import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const InitialLoader = ({ size }) => (
  <Spin
    indicator={<LoadingOutlined style={{ fontSize: size ? size : 40 }} spin />}
    className={'initial-loading'}
  />
);
