const actions = {
  GET_ACTIVITIES_LISTS: 'GET_ACTIVITIES_LISTS',
  GET_ACTIVITIES_LISTS_SUCCESS: 'GET_ACTIVITIES_LISTS_SUCCESS',
  GET_ACTIVITIES_LISTS_FAILURE: 'GET_ACTIVITIES_LISTS_FAILURE',
  CREATE_ACTIVITY: 'CREATE_ACTIVITY',
  CREATE_ACTIVITY_SUCCESS: 'CREATE_ACTIVITY_SUCCESS',
  CREATE_ACTIVITY_FAILURE: 'CREATE_ACTIVITY_FAILURE',
  VIEW_ACTIVITY: 'VIEW_ACTIVITY',
  VIEW_ACTIVITY_SUCCESS: 'VIEW_ACTIVITY_SUCCESS',
  VIEW_ACTIVITY_FAILURE: 'VIEW_ACTIVITY_FAILURE',
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
  UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
  UPDATE_ACTIVITY_FAILURE: 'UPDATE_ACTIVITY_FAILURE',
  UPDATE_ACTIVITY_AVATAR: 'UPDATE_ACTIVITY_AVATAR',
  UPDATE_ACTIVITY_AVATAR_SUCCESS: 'UPDATE_ACTIVITY_AVATAR_SUCCESS',
  UPDATE_ACTIVITY_AVATAR_FAILURE: 'UPDATE_ACTIVITY_AVATAR_FAILURE',
  GET_ACTIVE_ACTIVITIES: 'GET_ACTIVE_ACTIVITIES',
  GET_ACTIVE_ACTIVITIES_SUCCESS: 'GET_ACTIVE_ACTIVITIES_SUCCESS',
  GET_ACTIVE_ACTIVITIES_FAILURE: 'GET_ACTIVE_ACTIVITIES_FAILURE',
  GET_ARCHIVED_ACTIVITIES: 'GET_ARCHIVED_ACTIVITIES',
  GET_ARCHIVED_ACTIVITIES_SUCCESS: 'GET_ARCHIVED_ACTIVITIES_SUCCESS',
  GET_ARCHIVED_ACTIVITIES_FAILURE: 'GET_ARCHIVED_ACTIVITIES_FAILURE',
  ARCHIVE_ACTIVITIES: 'ARCHIVE_ACTIVITIES',
  ARCHIVE_ACTIVITIES_SUCCESS: 'ARCHIVE_ACTIVITIES_SUCCESS',
  ARCHIVE_ACTIVITIES_FAILURE: 'ARCHIVE_ACTIVITIES_FAILURE',
  UNARCHIVE_ACTIVITIES: 'UNARCHIVE_ACTIVITIES',
  UNARCHIVE_ACTIVITIES_SUCCESS: 'UNARCHIVE_ACTIVITIES_SUCCESS',
  UNARCHIVE_ACTIVITIES_FAILURE: 'UNARCHIVE_ACTIVITIES_FAILURE',
  REORDER_ACTIVE_ACTIVITIES: 'REORDER_ACTIVE_ACTIVITIES',
  REORDER_ACTIVE_ACTIVITIES_SUCCESS: 'REORDER_ACTIVE_ACTIVITIES_SUCCESS',
  REORDER_ACTIVE_ACTIVITIES_FAILURE: 'REORDER_ACTIVE_ACTIVITIES_FAILURE',
  REORDER_ARCHIVED_ACTIVITIES: 'REORDER_ARCHIVED_ACTIVITIES',
  REORDER_ARCHIVED_ACTIVITIES_SUCCESS: 'REORDER_ARCHIVED_ACTIVITIES_SUCCESS',
  REORDER_ARCHIVED_ACTIVITIES_FAILURE: 'REORDER_ARCHIVED_ACTIVITIES_FAILURE',
  UPDATE_NODE: 'UPDATE_NODE',
  UPDATE_NODE_SUCCESS: 'UPDATE_NODE_SUCCESS',
  UPDATE_NODE_FAILURE: 'UPDATE_NODE_FAILURE',
  UPDATE_NODE_AVATAR: 'UPDATE_NODE_AVATAR',
  UPDATE_NODE_AVATAR_SUCCESS: 'UPDATE_NODE_AVATAR_SUCCESS',
  UPDATE_NODE_AVATAR_FAILURE: 'UPDATE_NODE_AVATAR_FAILURE',
  REMOVE_NODE_AVATAR: 'REMOVE_NODE_AVATAR',
  REMOVE_NODE_AVATAR_SUCCESS: 'REMOVE_NODE_AVATAR_SUCCESS',
  REMOVE_NODE_AVATAR_FAILURE: 'REMOVE_NODE_AVATAR_FAILURE',
  REORDER_NODE: 'REORDER_NODE',
  REORDER_NODE_SUCCESS: 'REORDER_NODE_SUCCESS',
  REORDER_NODE_FAILURE: 'REORDER_NODE_FAILURE',
  CREATE_SPARK: 'CREATE_SPARK',
  CREATE_SPARK_SUCCESS: 'CREATE_SPARK_SUCCESS',
  CREATE_SPARK_FAILURE: 'CREATE_SPARK_FAILURE',
  SPARK_A_NODE: 'SPARK_A_NODE',
  SPARK_A_NODE_SUCCESS: 'SPARK_A_NODE_SUCCESS',
  SPARK_A_NODE_FAILURE: 'SPARK_A_NODE_FAILURE',
  UNSPARK_A_NODE: 'UNSPARK_A_NODE',
  UNSPARK_A_NODE_SUCCESS: 'UNSPARK_A_NODE_SUCCESS',
  UNSPARK_A_NODE_FAILURE: 'UNSPARK_A_NODE_FAILURE',
  SPARK_PRESENTATION: 'SPARK_PRESENTATION',
  SPARK_PRESENTATION_SUCCESS: 'SPARK_PRESENTATION_SUCCESS',
  SPARK_PRESENTATION_FAILURE: 'SPARK_PRESENTATION_FAILURE',
  DELETE_ACTIVITY_MODAL_VISIBILITY: 'DELETE_ACTIVITY_MODAL_VISIBILITY',
  DELETE_ACTIVITY: 'DELETE_ACTIVITY',
  DELETE_ACTIVITY_SUCCESS: 'DELETE_ACTIVITY_SUCCESS',
  DELETE_ACTIVITY_FAILURE: 'DELETE_ACTIVITY_FAILURE',
  ADD_NEW_ACTIVITY_MODAL: 'ADD_NEW_ACTIVITY_MODAL',
  CLOSE_ACTIVITY_MODAL: 'CLOSE_ACTIVITY_MODAL',
  UPDATE_ACTIVITY_AVATAR_LOADER: 'UPDATE_ACTIVITY_AVATAR_LOADER',
  UPDATE_ACTIVITY_AVATAR_DATA: 'UPDATE_ACTIVITY_AVATAR_DATA',
};

export default actions;
