import React, { useEffect } from 'react';
import 'components/shared/style.scss';
import { Card, Typography } from 'antd';
import pauseHandIcon from 'images/svgs/hand.svg';
import lockIcon from 'images/svgs/lock.svg';
import unlockIcon from 'images/svgs/unlock.svg';
import shareIcon from 'images/svgs/share.svg';
import fullScreenIcon from 'images/svgs/full-screen-icon.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import folderActions from 'redux/folders/actions';
import commonActions from 'redux/common/actions';
import { find } from 'lodash';
import { screenName } from 'components/constants';
import sharedSparkActions from 'redux/sharedSparks/actions';
import { Translate } from 'utility/helpers';

const { Text } = Typography;

function Folder({
  title,
  children,
  actions,
  isShared,
  isPaused,
  count,
  newFolder,
  isFolderItemDragging,
  nodeId,
  selectedId,
  onClick,
  date,
  isVerified,
  isArchive,
  isSpark,
  isLock,
  isFromSharedSparks,
  urlValue,
}) {
  const { isComponentDragging, foldersList } = useSelector(
      (state) => state.Folder,
    ),
    dispatch = useDispatch(),
    updateLockStatus = (status) => {
      if (!isArchive) {
        if (isLock) {
          window.currentNodeId = nodeId;
          dispatch({
            type: commonActions.SET_INFO_MODAL_VISIBLE,
            module: isFromSharedSparks ? 'sparks' : 'folders',
            payload: {
              folderVisibility: true,
              status: 'unlock',
              currentNodeId: nodeId,
              archiveStatus: isArchive,
            },
          });
        } else {
          dispatch({
            type: folderActions.SET_LOCK_STATUS,
            nodeId,
            payload: {
              lock: !status,
            },
          });
        }
      }
    },
    onShare = () => {
      if (!isArchive) {
        if (!isLock && !isFromSharedSparks) {
          dispatch({
            type: folderActions.SHARE_NODE,
            nodeId,
          });
        }
        dispatch({
          type: commonActions.SET_INFO_MODAL_VISIBLE,
          module: isFromSharedSparks ? 'sparks' : 'folders',
          payload: {
            folderVisibility: true,
            status: 'share',
            currentNodeId: nodeId,
            urlValue,
          },
        });
      }
    },
    showPropertyModel = () => {
      if (!isArchive) {
        window.analytics.page(
          isLock ? screenName.spark_properties : screenName.folder_properties,
        );
        dispatch({
          type: folderActions.SHOW_FOLDER_PROPERTIES_MODAL,
          payload: find(foldersList, ['node_id', nodeId]),
          visibility: true,
        });
      }
    },
    onResume = () => {
      if (!isArchive) {
        dispatch({
          type: sharedSparkActions.RESUME_SHARED_SPARKS,
          payload: nodeId,
        });
      }
    };
  useEffect(() => {
    setTimeout(() => {
      let spark = document.getElementById('appear-spark-on-top');
      spark && spark.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  return (
    <div
      id={nodeId === selectedId && isShared ? 'appear-spark-on-top' : ''}
      className={`folder-outer ${isShared || isPaused ? 'with-icon' : ''} ${
        newFolder ? 'new-folder' : ''
      } ${newFolder && !isVerified ? 'not-verified' : ''} ${
        isFolderItemDragging ? 'is-dragging' : ''
      } ${isArchive ? 'archived-folder' : ''} ${
        isSpark && isLock && isComponentDragging ? 'disabled-folder' : ''
      }`}
      onClick={onClick}
    >
      <div className="folder-header handle">
        <div className={'title'} onClick={showPropertyModel}>
          <Text ellipsis style={{ width: '100%' }}>
            {title}
          </Text>
          <div className={'date'}>
            <Translate>updated</Translate>{' '}
            {moment(date).format('MMM DD YYYY hh:mm a')}
          </div>
        </div>
        <div className={'icons'}>
          {isFromSharedSparks ? (
            isPaused ? (
              <div className={'lock-unlock-status'}>
                <img
                  src={pauseHandIcon}
                  alt={'pause-hand-icon'}
                  height={'18px'}
                  onClick={onResume}
                />
              </div>
            ) : (
              ''
            )
          ) : (
            <div className={'lock-unlock-status'}>
              <img
                src={isLock ? lockIcon : unlockIcon}
                alt={'lock-icon'}
                height={'18px'}
                onClick={() => updateLockStatus(isLock)}
              />
            </div>
          )}
          <div className={'share-icon'}>
            <div>
              <img
                src={shareIcon}
                alt={'share-icon'}
                height={'18px'}
                onClick={onShare}
              />
            </div>
            {isFromSharedSparks && count > 0 && (
              <div className={'count-badge'}>{count > 99 ? '99+' : count}</div>
            )}
          </div>
          <div className={'actions'}>
            {actions && actions.map((action) => action)}
          </div>
          <div className={'full-screen-icon'}>
            <img
              src={fullScreenIcon}
              alt={'full-screen-icon'}
              height={'18px'}
            />
          </div>
        </div>
      </div>
      <div className={`${nodeId ? `body${nodeId}` : ''} folder-body`}>
        <Card bordered={false}>{children}</Card>
      </div>
    </div>
  );
}

export default Folder;
