export const privacyPolicyLink = 'https://www.sparkbox.io/privacy-policy.html';
export const termsOfUseLink = 'https://www.sparkbox.io/terms-of-use.html';
export const language = [
  { key: 'en', value: 'English' },
  { key: 'es', value: 'Spanish' },
  { key: 'num', value: 'Number' },
];
export const timePeriod = ['today'];

export const plansDetailActions = {
  in_trial_no_card: ['add', 'cancel'],
  in_trial_valid_card: ['cancel'],
  active_with_pending_invoices: ['pay', 'cancel'],
  active: [],
  scheduled_changes: ['remove'],
};

export const plansUsageActions = {
  in_trial_no_card: 'add',
  active_with_pending_invoices: 'pay',
  upgrade: 'upgrade',
};

export const screenName = {
  launch_screen: 'Launch', //
  welcome_screen: 'Welcome ', //
  register_screen: 'Registration',
  onBoarding_screen: 'Onboarding', //
  login_screen: 'Login',
  verification_screen: 'Email Verification',
  activity_screen: 'Activities',
  create_new_activity_screen: 'Create New Activity',
  activity_edit_screen: 'Edit Activity',
  node_list_screen: 'Folder and Sparks list',
  folder_spark_view_screen: 'Spark View', //
  folder_properties: 'Folder Properties',
  move_spark_item: 'Move spark item',
  move_spark: 'Move spark',
  select_folder: 'Select folder',
  spark_properties: 'Spark properties',
  spark_preview: 'Spark Preview',
  edit_image: 'Edit Image',
  edit_video: 'Edit Video',
  edit_audio: 'Edit Audio',
  image_properties: 'Image properties',
  image_caption: 'Image caption',
  replace_url: 'Replace URL',
  edit_note: 'Edit Note',
  replace_location: 'Replace location',
  edit_contact: 'Edit Contact',
  open_map: 'Open Map',
  image_preview: 'Image preview',
  play_video: 'Play video', //
  play_audio: 'Play Audio', //
  capture_list: 'Capture list',
  shared_spark: 'Shared Spark',
  settings: 'Settings',
  account_details: 'Account details',
  change_password: 'Change Password',
  deactivate_account: 'Deactivate Account',
  language: 'Language',
  privacy_policy: 'Privacy policy',
  contributors_list: 'Contributors List',
  terms_and_condition: 'Terms and Condition',
  synchronization: 'Synchronization', //
  cloud_storage: 'Cloud Storage', //
  plan_and_subscription: 'Plan and Subscription ',
  search: 'Search', //
  add_panel: 'Add Panel',
  image_capture_screen: 'Image',
  video_capture_screen: 'Video',
  audio_capture_screen: 'Audio',
  add_notes_screen: 'Notes',
  add_location_screen: 'Location',
  add_url_screen: 'URL',
  add_contact_screen: 'Contact',
  add_number_screen: 'Number list',
  add_bullet_screen: 'Bullet list',
  video_properties: 'Video Properties',
  audio_properties: 'Audio properties',
  location_properties: 'Location Properties',
  link_properties: 'Link Properties',
  note_properties: 'Note Properties',
  contact_properties: 'Contact Properties', //
  plan_details_status_screen: 'Plan Details and status screen ',
  hosted_page: 'Hosted page',
  pay_now: 'Pay now',
  add_card: 'Add Card',
  change_plan: 'Change Plan',
  cancel_plan: 'Cancel Plan',
  reschedule_change: 'Reschedule Change',
  move_folder_item: 'Move folder item', //
  move_folder: 'Move folder ', //
  view_folder_item: 'View Folder item', //
  view_shared_spark_item: 'View a shared spark item', //
  video_caption: 'Video caption',
};

export const settingsSubMenu = [
  'data',
  'sparkbox',
];

export const dataSubMenuItems = [
    'show-location',
];

export const sparkBoxSubMenuItems = [
    'privacy',
    'terms',
    'contributors',
];
