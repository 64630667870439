import actions from './actions';

const initState = {
  loading: false,
};

export default function addItemReducer(state = initState, action) {
  switch (action.type) {
    case actions.UPLOAD_ITEM_TO_CAPTURE: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.UPLOAD_ITEM_TO_CAPTURE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.UPLOAD_ITEM_TO_CAPTURE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
