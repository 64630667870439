import 'components/onBoarding/style.scss';
import React, { Fragment, useEffect } from 'react';
import { Button, Modal, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/auth/actions';
import whiteTick from 'images/svgs/whiteTick.svg';
import iIcon from 'images/svgs/infoNewIcon.svg';
import { Translate } from 'utility/helpers';

function OnBoardingModal() {
  const { onBoardingModal } = useSelector((state) => state.Auth),
    { visible, completionModalVisible, current } = onBoardingModal,
    dispatch = useDispatch(),
    [doneIconShow, setDoneIconShow] = React.useState(false),
    [alertShow, setAlertShow] = React.useState(true),
    [alertDisplayOff, setAlertDisplayOff] = React.useState(false),
    onComplete = () => {
      dispatch({
        type: actions.SET_ON_BOARDING_MODAL_VISIBLE,
        visible: false,
        completionModalVisible: false,
      });
      dispatch({
        type: actions.ON_BOARD_COMPLETE_PROCESS,
      });
    },
    onCompletionModalEnable = () => {
      dispatch({
        type: actions.SET_ON_BOARDING_MODAL_VISIBLE,
        completionModalVisible: true,
      });
    },
    completionModal = (
      <Modal
        visible={completionModalVisible}
        footer={null}
        closable={false}
        className={'completion-modal'}
        centered
        destroyOnClose
        width={270}
        mask={true}
        zIndex={1500}
      >
        <div className={'icon'}>
          <img src={iIcon} alt={'i-icon'} />
        </div>
        <div className={'title'}>
          <Translate>congratulations</Translate>
        </div>
        <div className={'info'}>
          <Translate>congratulations_info_message</Translate>
        </div>
        <Button type="primary" className="btn-blue btn-md" onClick={onComplete}>
          <Translate>goto_activity</Translate>
        </Button>
      </Modal>
    ),
    checkedBox = (
      <div className={'custom-checkbox progress-check-box'}>
        <Checkbox checked={true}></Checkbox>
      </div>
    ),
    steps = [
      {
        step: 1,
        content: `${Translate({ children: 'screen_1' })}`,
      },
      {
        step: 2,
        content: `${Translate({ children: 'screen_2' })}`,
      },
      {
        step: 3,
        content: `${Translate({ children: 'screen_3' })}`,
      },
      {
        step: 4,
        content: `${Translate({ children: 'screen_4' })}`,
      },
      {
        content: `${Translate({ children: 'screen_5' })}`,
      },
    ],
    onNext = () => {
      dispatch({
        type: actions.SET_ON_BOARDING_MODAL_VISIBLE,
        current: current + 1,
      });
      setDoneIconShow(true);
      setTimeout(() => {
        setDoneIconShow(false);
      }, 1000);
    },
    onPrevious = () => {
      dispatch({
        type: actions.SET_ON_BOARDING_MODAL_VISIBLE,
        current: current - 1,
      });
    };

  useEffect(() => {
    setTimeout(() => {
      setAlertShow(false);
    }, 3000);
    setTimeout(() => {
      setAlertDisplayOff(true);
    }, 4000);
  }, []);

  return (
    <div>
      <Modal
        className={'on-boarding-modal'}
        visible={visible}
        width={700}
        footer={null}
        centered
        destroyOnClose
        mask={true}
        maskClosable={true}
        closable={false}
        zIndex={1400}
      >
        <div className={`header${!alertDisplayOff ? ' alert-height' : ''}`}>
          <Translate>onboarding</Translate>
        </div>
        <div
          className={`alert-success-message${!alertShow ? ' alert-off' : ''}${
            alertDisplayOff ? ' display-off' : ''
          }`}
        >
          <div className={'icon'}>
            <img src={whiteTick} alt={'tick'} />
          </div>
          <div className={'message'}>
            <Translate>account_created_successfully_message</Translate>
          </div>
        </div>
        {alertDisplayOff && (
          <div className={'progress-bar'}>
            {steps.map((data, index) => {
              let stepValue;
              if (index < steps.length - 1) {
                stepValue =
                  current !== 0 && current > index ? (
                    checkedBox
                  ) : (
                    <div
                      className={`icon${current === index ? ' active' : ''}`}
                    >
                      {data.step}
                    </div>
                  );
              }
              return stepValue;
            })}
          </div>
        )}
        <div className={'body'}>
          <div className={'content'}>{steps[current].content}</div>
        </div>
        <div className={'footer'}>
          {!doneIconShow && current < steps.length - 1 && (
            <Fragment>
              <Button
                type="primary"
                className={'btn-secondary btn-md'}
                onClick={onNext}
              >
                <span>
                  <Translate>next</Translate>
                </span>
              </Button>
              <Button
                type="primary"
                className="btn-light-primary btn-md"
                onClick={onComplete}
              >
                <span>
                  <Translate>skip</Translate>
                </span>
              </Button>
            </Fragment>
          )}
          {!doneIconShow && current < steps.length - 1 && (
            <Button
              type="primary"
              className={`btn-grey btn-md ${
                current === 0 ? ' visibility-hidden' : ''
              }`}
              onClick={onPrevious}
            >
              <span>
                <Translate>previous</Translate>
              </span>
            </Button>
          )}
          {!doneIconShow && current === steps.length - 1 && (
            <Button
              type="primary"
              className={'btn-secondary btn-md'}
              onClick={onCompletionModalEnable}
            >
              <span>
                <Translate>got_it</Translate>
              </span>
            </Button>
          )}
          {doneIconShow && (
            <div className={'custom-checkbox confirmation-check-box'}>
              <Checkbox checked={true}></Checkbox>
            </div>
          )}
        </div>
      </Modal>
      {completionModal}
    </div>
  );
}

export default OnBoardingModal;
