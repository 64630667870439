import { store } from 'redux/store';
import actions from 'redux/auth/actions';

export default () =>
  new Promise(() => {
    store.dispatch({
      type: actions.REFRESH_TOKEN,
    });
    store.dispatch({
      type: actions.GET_PASSWORDS_LIST,
    });
  });
