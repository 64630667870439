import actions from './actions';
const initState = {
  folders: {
    folderVisibility: false,
    status: '',
    currentNodeId: undefined,
    archiveStatus: undefined,
  },
  sparks: {
    folderVisibility: false,
    status: '',
    currentNodeId: undefined,
    archiveStatus: undefined,
  },
};

export default function commonReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_INFO_MODAL_VISIBLE: {
      const { module, payload } = action;
      return {
        ...state,
        [module]: payload,
      };
    }
    default:
      return state;
  }
}
