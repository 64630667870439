import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import subscriptionSagas from './subscription/saga';
import settingsSagas from './settings/saga';
import activitiesSagas from './activities/saga';
import captureSagas from './capture/saga';
import addItem from './addItem/saga';
import sharedSparks from './sharedSparks/saga';
import folderSaga from './folders/saga';
import addBar from './addBar/saga';
import commonSaga from './common/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    subscriptionSagas(),
    settingsSagas(),
    activitiesSagas(),
    captureSagas(),
    addItem(),
    sharedSparks(),
    folderSaga(),
    addBar(),
    commonSaga(),
  ]);
}
