import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from 'utility/asyncComponent';
import App from 'containers/app/app';
import { InitialLoader } from 'containers/pages/initialLoader';

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PreventedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      let { from } = props.location.state || {
        from: { pathname: '/app' },
      };
      return isLoggedIn ? <Redirect to={from} /> : <Component {...props} />;
    }}
  />
);

const PublicRoutes = ({ isLoggedIn, refreshingToken }) => {
  if (refreshingToken) {
    return <InitialLoader />;
  }
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={'/'}
          render={() => <Redirect to={{ pathname: '/login' }} />}
        />
        <PreventedRoute
          exact
          path="/login"
          component={asyncComponent(() =>
            import('containers/onBoarding/index'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PreventedRoute
          exact
          path="/register"
          component={asyncComponent(() =>
            import('containers/onBoarding/index'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PreventedRoute
          path="/reset-password"
          component={asyncComponent(() =>
            import('containers/onBoarding/resetPassword'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PreventedRoute
          exact
          path="/forgot-password"
          component={asyncComponent(() =>
            import('containers/onBoarding/forgotPassword'),
          )}
          isLoggedIn={isLoggedIn}
        />
        <PrivateRoute path="/app" component={App} isLoggedIn={isLoggedIn} />
        <Route
          path={'/pay-now'}
          component={asyncComponent(() => import('./containers/pages/payNow'))}
        />
        <Route
          path={'/collect-now'}
          component={asyncComponent(() =>
            import('./containers/pages/collectNow'),
          )}
        />
        <Route
          path={'/plans-detail'}
          component={asyncComponent(() =>
            import('./containers/pricingPlan/planDetail'),
          )}
        />
        <Route
          path={'*'}
          component={asyncComponent(() =>
            import('./containers/pages/notFound'),
          )}
        />
      </Switch>
    </Router>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  refreshingToken: state.Auth.refreshingToken,
}))(PublicRoutes);
