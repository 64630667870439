import React from 'react';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import actions from 'redux/auth/actions';
import { postRequest, getRequest, putRequest } from 'utility/axiosClient';
import { handleException, getUserId, showAlert } from 'utility/helpers';
import { message } from 'antd';
import { updateAwsSdk, updateHistory } from 'utility/helpers';
import { verifyNotification } from 'components/shared/constants';
import { Translate } from 'utility/helpers';
import { store } from 'redux/store';
import Adjust from '@adjustcom/adjust-web-sdk';
let loggedInCredentials = {};

export function* loginUser(params) {
  try {
    const response = yield call(() => postRequest('login', params.payload)),
      { data } = response;
    if (
      data.is_email_verified === false &&
      data.remaining_verification_minutes === null
    ) {
      loggedInCredentials = data;
      yield put({ type: actions.VERIFY_MODAL_VISIBLE, payload: true });
      yield put({ type: actions.LOGIN_USER_FAILURE });
    } else {
      updateAwsSdk(data.s3_upload_credentials);
      yield put({
        type: actions.LOGIN_USER_SUCCESS,
        payload: data,
      });
      yield put({ type: actions.GET_USER_DETAILS });
      if (data.deactivated) {
        updateHistory('/app/settings');
      }
    }
  } catch (error) {
    yield put({ type: actions.LOGIN_USER_FAILURE });
    handleException(error);
  }
}

export function* registerUser(params) {
  const { payload } = params;
  try {
    const response = yield call(() => postRequest('register', params.payload)),
      { data } = response;
    yield put({
      type: actions.REGISTER_USER_SUCCESS,
      payload: data,
    });
    Adjust.trackEvent({
      eventToken: '3iovam',
    });
    yield put({
      type: actions.LOGIN_USER,
      payload: {
        provider: 'email',
        email: payload.email,
        password: payload.password,
      },
      isFromRegister: true,
    });
  } catch (error) {
    yield put({ type: actions.REGISTER_USER_FAILURE });
    handleException(error);
  }
}

export function* forgotPassword(params) {
  try {
    yield call(() => postRequest('forgot/web', params.payload));
    message.success(<Translate>forgot_password_sent_content</Translate>);
    yield put({
      type: actions.FORGOT_PASSWORD_SUCCESS,
    });
    params.history.push('/login');
  } catch (error) {
    yield put({ type: actions.FORGOT_PASSWORD_FAILURE });
    handleException(error);
  }
}

export function* resetPassword(params) {
  try {
    yield call(() => postRequest('reset-password', params.payload));
    yield put({
      type: actions.RESET_PASSWORD_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.RESET_PASSWORD_FAILURE });
    handleException(error);
  }
}

export function* logoutUser(params) {
  try {
    yield call(() => postRequest('logout', params.payload));
    yield put({
      type: actions.LOGOUT_USER_SUCCESS,
    });
    window.analytics.reset();
    notification.destroy();
    loggedInCredentials = {};
    localStorage.removeItem(`node-${getUserId()}`);
    localStorage.removeItem('activeTab');
  } catch (error) {
    yield put({ type: actions.LOGOUT_USER_FAILURE });
    handleException(error);
  }
}

export function* refreshToken() {
  try {
    const response = yield call(() => postRequest('refresh-token'));
    yield put({
      type: actions.REFRESH_TOKEN_SUCCESS,
      payload: response.data,
    });
    updateAwsSdk(response.data.s3_upload_credentials);
    yield put({ type: actions.GET_USER_DETAILS });
    if (response.data.deactivated) {
      updateHistory('/app/settings');
    }
  } catch (error) {
    yield put({ type: actions.REFRESH_TOKEN_FAILURE });
    window.analytics.reset();
  }
}

export function* getUserDetails(params) {
  try {
    const response = yield call(() => getRequest('users/me', params.payload));
    yield put({
      type: actions.GET_USER_DETAILS_SUCCESS,
      payload: response.data,
    });
    if (response.data.deactivated) {
      updateHistory('/app/settings');
    }
    notification.destroy();
    !response.data.is_email_verified &&
      verifyNotification(response.data.remaining_verification_minutes);
  } catch (error) {
    yield put({ type: actions.GET_USER_DETAILS_FAILURE });
    handleException(error);
  }
}

export function* verifyOtp(params) {
  try {
    const { isLoggedIn } = store.getState().Auth;
    yield call(() => postRequest('users/me/validate-otp', params.payload));
    yield put({
      type: actions.VERIFY_OTP_SUCCESS,
    });
    yield put({ type: actions.MODAL_VISIBLE, payload: false });
    notification.destroy();
    showAlert(
      '.app-body',
      'account_created_successfully_message',
      'success',
      '',
      3000,
    );
    if (!isLoggedIn) {
      updateAwsSdk(loggedInCredentials.s3_upload_credentials);
      yield put({
        type: actions.LOGIN_USER_SUCCESS,
        payload: loggedInCredentials,
      });
      yield put({ type: actions.GET_USER_DETAILS });
    }
  } catch (error) {
    yield put({ type: actions.VERIFY_OTP_FAILURE });
    handleException(error);
  }
}

export function* resendOtp() {
  try {
    yield call(() => getRequest('users/me/verify'));
    yield put({
      type: actions.RESEND_OTP_SUCCESS,
    });
    message.success(<Translate>verification_email</Translate>);
  } catch (error) {
    yield put({ type: actions.RESEND_OTP_FAILURE });
    handleException(error);
  }
}

export function* fetchDeactivateReasonList() {
  try {
    const response = yield call(() => getRequest('users/deactivate/reasons'));
    yield put({
      type: actions.FETCH_DEACTIVATE_REASON_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    handleException(error);
  }
}
export function* deactivateAccount(params) {
  try {
    let data = {
      reasons: [],
    };
    params.payload.values.map((reason) =>
      reason === '1d6adb5a-010e-47f9-87af-66585fb55f85'
        ? data.reasons.push({ id: reason, reason: params.payload.comments })
        : data.reasons.push({ id: reason }),
    );
    yield call(() => postRequest('users/me/deactivate', data));
    yield put({ type: actions.DEACTIVATE_ACCOUNT_SUCCESS });
    yield put({
      type: actions.DEACTIVATE_CONFIRMATION_VISIBLE,
      payload: false,
    });
    yield put({ type: actions.REACTIVATE_CONFIRMATION_VISIBLE, payload: true });
  } catch (error) {
    handleException(error);
    yield put({ type: actions.DEACTIVATE_ACCOUNT_FAILURE });
  }
}

export function* updateUserDetails(params) {
  try {
    const response = yield call(() => putRequest('users/me', params.payload));
    yield put({
      type: actions.UPDATE_USER_DETAILS_SUCCESS,
      payload: response.data,
    });
    yield put({ type: actions.CHANGE_PASSWORD_VISIBLE, payload: false });
    if (params.isChangePassword) {
      showAlert(
        '.settings-container',
        'password_updated_successfully',
        'success',
        '',
        3000,
      );
    } else {
      showAlert(
        '.settings-container',
        'profile_update_success',
        'success',
        '',
        3000,
      );
    }
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_DETAILS_FAILURE });
    handleException(error);
  }
}

export function* activateAccount() {
  try {
    const response = yield call(() => postRequest('users/me/activate'));
    yield put({
      type: actions.ACTIVATE_ACCOUNT_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: actions.REACTIVATE_CONFIRMATION_VISIBLE,
      payload: false,
    });
    message.success(<Translate>weve_reactivated_your_account</Translate>);
  } catch (error) {
    yield put({ type: actions.ACTIVATE_ACCOUNT_FAILURE });
    handleException(error);
  }
}

export function* changeUserAvatar(params) {
  try {
    const response = yield call(() => postRequest('me/avatar', params.payload));
    yield put({
      type: actions.CHANGE_USER_AVATAR_SUCCESS,
      payload: response.data,
    });
    showAlert(
      '.settings-container',
      'user_avatar_success',
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({ type: actions.CHANGE_USER_AVATAR_FAILURE });
    handleException(error);
  }
}

export function* onBoardComplete(params) {
  try {
    yield call(() => postRequest('users/update_onboard_process'));
    yield put({
      type: actions.SET_ON_BOARDING_MODAL_VISIBLE,
      visible: false,
      isOnboardComplete: true,
    });
    updateHistory('/');
  } catch (error) {
    handleException(error);
  }
}

export function* locationStatusChange(params) {
  try {
    const response = yield call(() =>
      postRequest('users/update_location_access', params.status),
    );
    yield put({
      type: actions.SET_LOCATION_ACCESS_STATUS_SUCCESS,
      status: response.data.location_access,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOCATION_ACCESS_STATUS_FAILURE,
    });
    handleException(error);
  }
}

export function* getPasswordsList(params) {
  try {
    const response = yield call(() => getRequest('show_common_password_list'));
    yield put({
      type: actions.GET_PASSWORDS_LIST_SUCCESS,
      passwordsList: response.data,
    });
  } catch (error) {
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN_USER, loginUser),
    takeEvery(actions.REGISTER_USER, registerUser),
    takeEvery(actions.FORGOT_PASSWORD, forgotPassword),
    takeEvery(actions.RESET_PASSWORD, resetPassword),
    takeEvery(actions.LOGOUT_USER, logoutUser),
    takeEvery(actions.REFRESH_TOKEN, refreshToken),
    takeEvery(actions.GET_USER_DETAILS, getUserDetails),
    takeEvery(actions.VERIFY_OTP, verifyOtp),
    takeEvery(actions.RESEND_OTP, resendOtp),
    takeEvery(actions.FETCH_DEACTIVATE_REASON_LIST, fetchDeactivateReasonList),
    takeEvery(actions.DEACTIVATE_ACCOUNT, deactivateAccount),
    takeEvery(actions.UPDATE_USER_DETAILS, updateUserDetails),
    takeEvery(actions.ACTIVATE_ACCOUNT, activateAccount),
    takeEvery(actions.CHANGE_USER_AVATAR, changeUserAvatar),
    takeEvery(actions.ON_BOARD_COMPLETE_PROCESS, onBoardComplete),
    takeEvery(actions.SET_LOCATION_ACCESS_STATUS, locationStatusChange),
    takeEvery(actions.GET_PASSWORDS_LIST, getPasswordsList),
  ]);
}
