import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getRequest, postRequest } from 'utility/axiosClient';
import { handleException } from 'utility/helpers';

export function* getPlansList() {
  try {
    const response = yield call(() => getRequest('plans-list'));
    yield put({
      type: actions.GET_PLANS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_PLANS_LIST_FAILURE });
    handleException(error);
  }
}

export function* checkoutSelectedPlan(params) {
  try {
    const response = yield call(() =>
      postRequest(`chargebee/plan/${params.payload}/checkout`, {
        platform: 'web',
      }),
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error);
  }
}

export function* editPlan() {
  try {
    const response = yield call(() =>
      postRequest('chargebee/payment-portal', { platform: 'web' }),
    );
    window.location.href = response.data.url;
  } catch (error) {
    yield put({ type: actions.EDIT_PLAN_FAILURE });
    handleException(error);
  }
}

export function* cancelPlan() {
  try {
    const response = yield call(() =>
      postRequest('chargebee/cancel-subscription', {
        platform: 'web',
      }),
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error);
  }
}

export function* getPayNowUrl() {
  try {
    const response = yield call(() =>
      getRequest('chargebee/payment-sources?platform=web'),
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error);
  }
}

export function* getCollectNowUrl() {
  try {
    const response = yield call(() =>
      getRequest('chargebee/collect-now?platform=web'),
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error);
  }
}

export function* getPlanDetail() {
  try {
    const response = yield call(() => getRequest('users/usage'));
    yield put({
      type: actions.GET_PLAN_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_PLAN_DETAIL_FAILURE });
    handleException(error);
  }
}

export function* cancelScheduledChanged() {
  try {
    const response = yield call(
      () => postRequest('chargebee/cancel-scheduled-changes'),
      { platform: 'web' },
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PLANS_LIST, getPlansList),
    takeEvery(actions.SELECT_PLAN, checkoutSelectedPlan),
    takeEvery(actions.EDIT_PLAN, editPlan),
    takeEvery(actions.CANCEL_PLAN, cancelPlan),
    takeEvery(actions.GET_PAY_NOW_URL, getPayNowUrl),
    takeEvery(actions.GET_COLLECT_NOW_URL, getCollectNowUrl),
    takeEvery(actions.GET_PLAN_DETAIL, getPlanDetail),
    takeEvery(actions.CANCEL_SCHEDULED_CHANGES, cancelScheduledChanged),
  ]);
}
