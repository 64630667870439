const actions = {
  ADD_BAR_MODAL_VISIBLE: 'ADD_BAR_MODAL_VISIBLE',
  ADD_CONTACT: 'ADD_CONTACT',
  ADD_CONTACT_SUCCESS: 'ADD_CONTACT_SUCCESS',
  ADD_CONTACT_FAILURE: 'ADD_CONTACT_FAILURE',
  ADD_NOTE: 'ADD_NOTE',
  ADD_NOTE_SUCCESS: 'ADD_NOTE_SUCCESS',
  ADD_NOTE_FAILURE: 'ADD_NOTE_FAILURE',
  ADD_PHOTO: 'ADD_PHOTO',
  ADD_PHOTO_SUCCESS: 'ADD_PHOTO_SUCCESS',
  ADD_PHOTO_FAILURE: 'ADD_PHOTO_FAILURE',
  ADD_VIDEO: 'ADD_VIDEO',
  ADD_VIDEO_SUCCESS: 'ADD_VIDEO_SUCCESS',
  ADD_VIDEO_FAILURE: 'ADD_VIDEO_FAILURE',
  ADD_AUDIO: 'ADD_AUDIO',
  ADD_AUDIO_SUCCESS: 'ADD_AUDIO_SUCCESS',
  ADD_AUDIO_FAILURE: 'ADD_AUDIO_FAILURE',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
  ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',
  CURRENT_LOCATION: 'CURRENT_LOCATION',
  MARKER_LOCATION: 'MARKER_LOCATION',
  ADD_URL: 'ADD_URL',
  ADD_URL_SUCCESS: 'ADD_URL_SUCCESS',
  ADD_URL_FAILURE: 'ADD_URL_FAILURE',
  GET_URL: 'GET_URL',
  GET_URL_SUCCESS: 'GET_URL_SUCCESS',
  GET_URL_FAILURE: 'GET_URL_FAILURE',
  SET_MEDIA_TYPE: 'SET_MEDIA_TYPE',
  RESET_URL_PREVIEW: 'RESET_URL_PREVIEW',
  RESET_NODE_ID_STATUS: 'RESET_NODE_ID_STATUS',
  SET_CLIPBOARD_VALUE: 'SET_CLIPBOARD_VALUE',
  SET_SELECTED_ADD_BAR_STATUS: 'SET_SELECTED_ADD_BAR_STATUS',
};

export default actions;
