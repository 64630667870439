import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'antd';
import modalClose from 'images/svgs/close.svg';
import { Translate } from 'utility/helpers';
import InputPassword from 'components/shared/inputPassword';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'redux/auth/actions';

function VerificationModal({ visible }) {
  const dispatch = useDispatch(),
    loading = useSelector((state) => state.Auth.loading),
    verifyingOtp = useSelector((state) => state.Auth.verifyingOtp),
    verifyingOTP = (values) => {
      dispatch({ type: actions.VERIFY_OTP, payload: values });
    },
    resendOtp = () => {
      dispatch({ type: actions.RESEND_OTP });
    },
    onClose = () => {
      dispatch({ type: actions.VERIFY_MODAL_VISIBLE, payload: false });
    };

  return (
    <Modal
      className={'verification-modal'}
      visible={visible}
      onCancel={onClose}
      width={440}
      footer={null}
      closeIcon={<img src={modalClose} alt={'close-icon'} height={24} />}
      destroyOnClose={true}
      centered={true}
    >
      <Form colon={false} hideRequiredMark={true} onFinish={verifyingOTP}>
        <div className={'title'}>
          <Translate>title_verification</Translate>
        </div>
        <div className={'content'}>
          <Translate>email_verification_info</Translate>
        </div>
        <div className={'form-inputs'}>
          <InputPassword
            label={<Translate>enter_otp</Translate>}
            required={true}
            errorMessage={<Translate>enter_otp_error</Translate>}
            className={'verification-code'}
            name={'verification_code'}
          />
        </div>
        <Form.Item className={'align-footer-box'}>
          <Button
            type="primary"
            className="btn-grey btn-lg"
            onClick={() => resendOtp()}
            loading={useMemo(() => loading, [loading])}
          >
            <span>
              <Translate>resend_otp</Translate>
            </span>
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className={'btn-secondary btn-md'}
            loading={useMemo(() => verifyingOtp, [verifyingOtp])}
          >
            <span>
              <Translate>submit</Translate>
            </span>
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default VerificationModal;
