import axios from 'axios';
import settings from 'config/settings';

export const axiosClient = axios.create();

axiosClient.defaults.baseURL = settings.HOST_URL;

axiosClient.defaults.headers = settings.headers;

export function getRequest(URL) {
  return axiosClient
    .get(`/${URL}`, { withCredentials: true })
    .then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient
    .post(`/${URL}`, payload, { withCredentials: true })
    .then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient
    .patch(`/${URL}`, payload, { withCredentials: true })
    .then((response) => response);
}

export function deleteRequest(URL, payload) {
  return axiosClient
    .delete(`/${URL}`, { data: payload, withCredentials: true })
    .then((response) => response);
}
export function putRequest(URL, payload) {
  return axiosClient
    .put(`/${URL}`, payload, { withCredentials: true })
    .then((response) => response);
}
