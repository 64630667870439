import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { postRequest } from 'utility/axiosClient';
import { handleException } from 'utility/helpers';

export function* fileUpload(params) {
  try {
    const response = yield call(() => postRequest('upload', params.payload));
    yield put({
      type: actions.FILE_UPLOAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FILE_UPLOAD_FAILURE });
    handleException(error);
  }
}

export function* uploadItemToNode(params) {
  try {
    const response = yield call(() =>
      postRequest(
        `projects/${params.projectId}/nodes/capture/${params.type}`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPLOAD_ITEM_TO_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPLOAD_ITEM_TO_PROJECT_FAILURE });
    handleException(error);
  }
}

export function* uploadItemToCapture(params) {
  try {
    const response = yield call(() =>
      postRequest(`capture/${params.type}`, params.payload),
    );
    yield put({
      type: actions.UPLOAD_ITEM_TO_CAPTURE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPLOAD_ITEM_TO_CAPTURE_FAILURE });
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FILE_UPLOAD, fileUpload),
    takeEvery(actions.UPLOAD_ITEM_TO_PROJECT, uploadItemToNode),
    takeEvery(actions.UPLOAD_ITEM_TO_CAPTURE, uploadItemToCapture),
  ]);
}
