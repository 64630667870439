import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import activitiesAction from '../activities/actions';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'utility/axiosClient';
import {
  findActivityData,
  getCurrentProjectId,
  getUserId,
  handleException,
  makeFolderAppearOnTop,
  showAlert,
  setFreeSpace,
} from 'utility/helpers';
import { store } from 'redux/store';
import commonActions from '../common/actions';

export function* getFolderList(params) {
  try {
    const projectId = params.projectId
      ? params.projectId
      : getCurrentProjectId();
    let sortFoldersBy = store.getState().Folder.sortFoldersBy;
    if (params && params.sortFoldersBy) {
      sortFoldersBy = params.sortFoldersBy;
    }
    const responseData = yield call(() =>
      getRequest(
        `projects/${projectId}/timeline?order_by=${
          sortFoldersBy === 'sparks_only' ? 'my_order' : sortFoldersBy
        }`,
      ),
    );
    responseData.data.data.forEach((data) => {
      store.dispatch({
        type: actions.GET_FOLDER_ITEMS_LIST,
        id: data.node_id,
      });
    });
    yield put({
      type: actions.GET_NODES_LIST_SUCCESS,
      payload: responseData.data.data,
      sortFoldersBy,
    });
  } catch (error) {
    yield put({ type: actions.GET_NODES_LIST_FAILURE });
    handleException(error);
  }
}

export function* updateFolder(params) {
  try {
    yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${
          params.payload.id
        }/properties`,
        params.payload.properties,
      ),
    );
  } catch (error) {
    yield put({ type: actions.UPDATE_FOLDER_FAILURE });
    handleException(error);
  }
}

export function* getFolderItemsList(params) {
  try {
    const responseData = yield call(() =>
      getRequest(`projects/${getCurrentProjectId()}/nodes/${params.id}/items`),
    );
    yield put({
      type: actions.GET_FOLDER_ITEMS_LIST_SUCCESS,
      payload: {
        data: responseData.data,
        id: params.id,
      },
    });
  } catch (error) {
    yield put({ type: actions.GET_FOLDER_ITEMS_LIST_FAILURE });
    handleException(error);
  }
}

export function* reOrderNodeItem(params) {
  try {
    yield call(() =>
      postRequest(
        `projects/${getCurrentProjectId()}/nodes/${
          params.payload.folderId
        }/items/${params.payload.folderItemId}/reorder`,
        params.payload.details,
      ),
    );
    yield put({
      type: actions.REORDER_NODE_ITEM_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    yield put({ type: actions.REORDER_NODE_ITEM_FAILURE });
    handleException(error);
  }
}

export function* moveNodeItem(params) {
  try {
    yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${
          params.payload.nodeId
        }/items/move`,
        params.payload.details,
      ),
    );
    yield put({
      type: actions.MOVE_NODE_ITEM_SUCCESS,
      payload: params.payload,
    });
    showAlert(
      `.body${params.payload.details.node_id}`,
      'move_to_folder_name_successful',
      'success',
      params.payload.destinationName,
      3000,
    );
  } catch (error) {
    yield put({ type: actions.MOVE_NODE_ITEM_FAILURE });
    handleException(error);
  }
}
export function* updateFolderProperties(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_FOLDER_PROPERTIES_SUCCESS,
      payload: response.data,
      visibility: false,
    });
    if (params.isSpark) {
      window.analytics.track('Spark Updated');
    }
    if (params.isFolderUpdate) {
      yield put({
        type: actions.DELETE_NODE_SUCCESS,
        payload: params.nodeId,
      });
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_FOLDER_PROPERTIES_FAILURE,
      visibility: false,
    });
    handleException(error);
  }
}
export function* duplicateNode(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/duplicate`,
        { project_id: getCurrentProjectId(), name: params.name },
      ),
    );
    yield put({
      type: actions.GET_FOLDER_ITEMS_LIST,
      id: response.data.node_id,
    });
    yield put({
      type: actions.DUPLICATE_SUCCESS,
      payload: response.data,
      nodeId: params.nodeId,
    });
    makeFolderAppearOnTop(response.data.node_id);
    window.analytics.track('Folder Duplicated', {
      'Activity Id': response.data.project_id,
      'Folder Id': response.data.node_id,
      'Folder Type': 'Normal',
    });
    showAlert(
      `.body${params.nodeId}`,
      'duplicated_successfully',
      'success',
      '',
      3000,
    );
  } catch (error) {
    handleException(error);
  }
}
export function* deleteNode(params) {
  try {
    yield call(() =>
      deleteRequest(`projects/${getCurrentProjectId()}/nodes/${params.nodeId}`),
    );
    yield put({
      type: actions.DELETE_NODE_SUCCESS,
      payload: params.nodeId,
    });
    if (params.nodeType !== 'spark') {
      window.analytics.track('Folder Deleted', {
        'Activity Id': getCurrentProjectId(),
        'Folder Id': params.nodeId,
        'Folder Type': 'Normal',
      });
    }
    showAlert(
      '.folders',
      params.nodeType === 'spark'
        ? 'spark_deleted_successfully'
        : 'folder_deleted_successfully',
      'success',
      '',
      3000,
    );
  } catch (error) {
    handleException(error);
  }
}
export function* shareNode(params) {
  try {
    const response = yield call(() =>
      postRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/spark`,
      ),
    );
    yield put({
      type: actions.SHARE_NODE_SUCCESS,
      payload: response.data,
      nodeId: params.nodeId,
    });
    window.analytics.track('Folder to Spark', {
      'Activity Id': response.data.project_id,
      'Folder Id': response.data.node_id,
      'Folder Type': 'Spark',
    });
    showAlert(
      `.body${params.nodeId}`,
      'success_update_spark_message_web',
      'success',
      'Document',
      3000,
    );
  } catch (error) {
    handleException(error);
  }
}
export function* shareSpark(params) {
  try {
    const response = yield call(() =>
      postRequest('user/sparks/shared', {
        node_id: params.nodeId,
      }),
    );
    yield put({
      type: actions.SHARE_SPARK_SUCCESS,
      nodeId: params.nodeId,
      payload: response.data,
    });
    window.analytics.track('Spark Shared');
  } catch (error) {
    handleException(error);
  }
}
export function* duplicateSpark(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/newversion`,
        { name: params.name },
      ),
    );
    yield put({
      type: actions.GET_FOLDER_ITEMS_LIST,
      id: response.data.node_id,
    });
    yield put({
      type: actions.DUPLICATE_SUCCESS,
      payload: response.data,
      nodeId: params.nodeId,
    });
    makeFolderAppearOnTop(response.data.node_id);
    showAlert('.folders', 'duplicated_successfully', 'success', '', 3000);
  } catch (error) {
    handleException(error);
  }
}

export function* moveNodeToActivity(params) {
  try {
    yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${
          params.payload.movedId
        }/move`,
        params.payload.movedTo,
      ),
    );
    yield put({
      type: actions.MOVE_NODE_TO_ACTIVITY_SUCCESS,
      payload: params.payload,
    });
    window.analytics.track('Folder Moved', {
      'Activity Id': getCurrentProjectId(),
      'Folder Id': params.payload.movedId,
      'To Activity Id': params.payload.movedTo.project_id,
    });
    yield put({
      type: activitiesAction.CLOSE_ACTIVITY_MODAL,
    });
    yield put({
      type: actions.CHANGE_SELECTED_ACTIVITY,
      selectedActivity: findActivityData(params.payload.movedTo.project_id),
    });
    localStorage.setItem(
      `spark-projectId-${getUserId()}`,
      params.payload.movedTo.project_id,
    );
  } catch (error) {
    yield put({ type: actions.MOVE_NODE_TO_ACTIVITY_FAILURE });
    handleException(error);
  }
}

export function* addItemFromCapture(params) {
  try {
    const response = yield call(() =>
      postRequest(
        `projects/${getCurrentProjectId()}/nodes/${
          params.payload.nodeId
        }/items/capture`,
        params.payload.details,
      ),
    );
    yield put({
      type: actions.ADD_ITEM_FROM_CAPTURE_SUCCESS,
      payload: response.data,
      nodeId: params.payload.nodeId,
      captureId: params.payload.details.capture_ids,
    });
  } catch (error) {
    yield put({ type: actions.ADD_ITEM_FROM_CAPTURE_FAILURE });
    handleException(error);
  }
}
export function* convertingItemToNode(params) {
  try {
    let projectId = getCurrentProjectId();
    if (params.payload.details.project_id) {
      projectId = params.payload.details.project_id;
    }
    const response = yield call(() =>
      postRequest(
        `projects/${projectId}/nodes/${params.payload.nodeId}/items/convert-to-node`,
        params.payload.details,
      ),
    );
    yield put({
      type: actions.CONVERTING_ITEM_TO_NODE_SUCCESS,
      payload: response.data,
      params: params.payload,
    });
    makeFolderAppearOnTop(response.data.node_id);
    yield put({
      type: activitiesAction.CLOSE_ACTIVITY_MODAL,
    });
    yield put({
      type: actions.CHANGE_SELECTED_ACTIVITY,
      selectedActivity: findActivityData(params.payload.details.project_id),
    });
    localStorage.setItem(
      `spark-projectId-${getUserId()}`,
      params.payload.details.project_id,
    );
    window.analytics.track('Folder Added', {
      folder_id: response.data.node_id,
    });
    showAlert(
      '.folders',
      'move_to_folder_name_successful',
      'success',
      response.data.name,
      3000,
    );
  } catch (error) {
    yield put({ type: actions.CONVERTING_ITEM_TO_NODE_FAILURE });
    handleException(error);
  }
}

export function* createNodeFromCapture(params) {
  try {
    let projectId = getCurrentProjectId(),
      { activityLists } = store.getState().Activities;
    if (params.projectId) {
      projectId = params.projectId;
    }
    const response = yield call(() =>
      postRequest(
        `projects/${projectId}/nodes/capture`,
        params.payload.details,
      ),
    );
    yield put({
      type: actions.CREATE_NODE_FROM_CAPTURE_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: activitiesAction.CLOSE_ACTIVITY_MODAL,
    });
    window.analytics.track('Folder Added', {
      folder_id: response.data.folder_id,
    });
    yield put({
      type: actions.CHANGE_SELECTED_ACTIVITY,
      selectedActivity: findActivityData(projectId),
    });
    if (params.fromQuickSpark) {
      localStorage.setItem(
        `spark-projectId-${getUserId()}`,
        activityLists.active.data[0].project_id,
      );
      showAlert(
        '.folders',
        'quick_spark_message_with_content',
        'success',
        '',
        3000,
      );
    } else {
      localStorage.setItem(`spark-projectId-${getUserId()}`, projectId);
      showAlert(
        '.folders',
        'move_to_folder_name_successful',
        'success',
        response.data.name,
        3000,
      );
    }
    makeFolderAppearOnTop(response.data.node_id);
  } catch (error) {
    yield put({ type: actions.CREATE_NODE_FROM_CAPTURE_FAILURE });
    handleException(error);
  }
}
export function* duplicateNodeItems(params) {
  try {
    const response = yield call(() =>
      postRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }/duplicate`,
      ),
    );
    yield put({
      type: actions.UPDATE_NODE_ITEMS_DATA,
      payload: response.data,
    });
    showAlert(
      `.body${params.nodeId}`,
      'duplicated_successfully',
      'success',
      '',
      3000,
    );
  } catch (error) {
    handleException(error);
  }
}
export function* deleteNodeItems(params) {
  try {
    yield call(() =>
      deleteRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }`,
      ),
    );
    showAlert(
      `.body${params.nodeId}`,
      'folder_item_deleted_successfully',
      'success',
      '',
      3000,
    );
    yield put({
      type: actions.DELETE_NODE_ITEMS_SUCCESS,
      itemId: params.itemId,
      nodeId: params.nodeId,
    });
  } catch (error) {
    handleException(error);
  }
}

export function* updateItemProperties(params) {
  try {
    yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_ITEM_PROPERTIES_SUCCESS,
      payload: params,
      visibility: false,
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_ITEM_PROPERTIES_FAILURE,
    });

    handleException(error);
  }
}
export function* updateNoteItem(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }/note`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_ITEM_SUCCESS,
      payload: response.data,
      visibility: false,
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_MODAL_VISIBLE,
      payload: false,
      editItemData: '',
    });
    handleException(error);
  }
}
export function* updateLocationItem(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }/location`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_ITEM_SUCCESS,
      payload: response.data,
      visibility: false,
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_MODAL_VISIBLE,
      payload: false,
      editItemData: '',
    });
    handleException(error);
  }
}
export function* updateContactItem(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }/contact`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_ITEM_SUCCESS,
      payload: response.data,
      visibility: false,
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_MODAL_VISIBLE,
      payload: false,
      editItemData: '',
    });
    handleException(error);
  }
}
export function* updateLinkItem(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }/url`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_ITEM_SUCCESS,
      payload: response.data,
      visibility: false,
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_MODAL_VISIBLE,
      payload: false,
      editItemData: '',
    });
    handleException(error);
  }
}
export function* updateFolderPropertiesWithActivity(params) {
  try {
    yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/move`,
        params.payload,
      ),
    );
    params.callback(params.payload, params.nodeId, true);
  } catch (error) {
    yield put({
      type: actions.UPDATE_FOLDER_PROPERTIES_FAILURE,
      visibility: false,
    });
    handleException(error);
  }
}
export function* updatePhoto(params) {
  const { nodeId, itemId, payload, isFullscreen, isRotate } = params;
  try {
    const response = yield call(() =>
      patchRequest(
        `projects/${getCurrentProjectId()}/nodes/${nodeId}/items/${itemId}/image`,
        payload,
      ),
    );

    if (isFullscreen) {
      yield put({
        type: actions.ROTATE_IMAGE_SUCCESS,
        nodeId,
        itemId,
      });
    } else {
      yield put({
        type: actions.UPDATE_ITEM_SUCCESS,
        payload: response.data,
        visibility: false,
      });
    }
  } catch (error) {
    if (isRotate) {
      yield put({
        type: actions.ROTATE_IMAGE_FAILURE,
        nodeId,
        itemId,
      });
    }
    yield put({
      type: actions.EDIT_MODAL_VISIBLE,
      payload: false,
      editItemData: '',
    });
    handleException(error);
  }
}
export function* updateVideo(params) {
  try {
    const response = yield call(() =>
      patchRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }/video`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_ITEM_SUCCESS,
      payload: response.data,
      visibility: false,
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_MODAL_VISIBLE,
      payload: false,
      editItemData: '',
    });

    handleException(error);
  }
}
export function* updateAudio(params) {
  try {
    const response = yield call(() =>
      patchRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/items/${
          params.itemId
        }/audio`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_ITEM_SUCCESS,
      payload: response.data,
      visibility: false,
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_MODAL_VISIBLE,
      payload: false,
      editItemData: '',
    });
    handleException(error);
  }
}
export function* updateCaption(params) {
  try {
    const { nodeId, itemId, actionType, payload } = params;
    yield call(() =>
      patchRequest(
        `projects/${getCurrentProjectId()}/nodes/${nodeId}/items/${itemId}/${actionType}`,
        payload,
      ),
    );
    yield put({
      type: actions.UPDATE_CAPTION_SUCCESS,
      visibility: false,
      payload: {
        nodeId: nodeId,
        itemId: itemId,
        content: payload,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_CAPTION_FAILURE,
      visibility: false,
    });
    handleException(error);
  }
}

export function* createQuickSparkFolder(params) {
  try {
    const { activityLists } = store.getState().Activities,
      homeActivityId = activityLists.active.data[0].project_id;
    const response = yield call(() =>
      postRequest(`projects/${homeActivityId}/sparks`),
    );
    if (response.data.project_id !== getCurrentProjectId()) {
      yield put({
        type: actions.CHANGE_SELECTED_ACTIVITY,
        selectedActivity: response.data,
      });
    }
    localStorage.setItem(
      `spark-projectId-${getUserId()}`,
      response.data.project_id,
    );
    setFreeSpace();
    showAlert('.folders', 'quick_spark_message', 'success', '', 3000);
    yield put({
      type: actions.CREATE_QUICK_SPARK_FOLDER_SUCCESS,
      payload: response.data,
      nodeId: response.data.node_id,
    });
  } catch (error) {
    handleException(error);
  }
}

export function* setLockStatus(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.nodeId}/lock`,
        params.payload,
      ),
    );
    yield put({
      type: actions.SHARE_NODE_SUCCESS,
      payload: response.data,
      nodeId: params.nodeId,
    });
    window.analytics.track('Lock the Folder', {
      'Activity Id': response.data.project_id,
      'Folder Id': response.data.node_id,
      'Folder Type': 'Spark',
    });
    if (!params.payload.lock) {
      yield put({
        type: commonActions.SET_INFO_MODAL_VISIBLE,
        visible: false,
      });
    }
    showAlert(
      `.body${params.nodeId}`,
      'success_update_spark_message_web',
      'success',
      'Document',
      3000,
    );
  } catch (error) {
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NODES_LIST, getFolderList),
    takeEvery(actions.UPDATE_FOLDER, updateFolder),
    takeEvery(actions.GET_FOLDER_ITEMS_LIST, getFolderItemsList),
    takeEvery(actions.REORDER_NODE_ITEM, reOrderNodeItem),
    takeEvery(actions.MOVE_NODE_ITEM, moveNodeItem),
    takeEvery(actions.MOVE_NODE_TO_ACTIVITY, moveNodeToActivity),
    takeEvery(actions.UPDATE_FOLDER_PROPERTIES, updateFolderProperties),
    takeEvery(actions.DUPLICATE_NODE, duplicateNode),
    takeEvery(actions.DELETE_NODE, deleteNode),
    takeEvery(actions.SHARE_NODE, shareNode),
    takeEvery(actions.DUPLICATE_SPARK, duplicateSpark),
    takeEvery(actions.SHARE_SPARK, shareSpark),
    takeEvery(actions.ADD_ITEM_FROM_CAPTURE, addItemFromCapture),
    takeEvery(actions.CONVERTING_ITEM_TO_NODE, convertingItemToNode),
    takeEvery(actions.CREATE_NODE_FROM_CAPTURE, createNodeFromCapture),
    takeEvery(actions.DUPLICATE_NODE_ITEMS, duplicateNodeItems),
    takeEvery(actions.DELETE_NODE_ITEMS, deleteNodeItems),
    takeEvery(actions.UPDATE_ITEM_PROPERTIES, updateItemProperties),
    takeEvery(actions.UPDATE_NOTE_ITEM, updateNoteItem),
    takeEvery(actions.UPDATE_LOCATION_ITEM, updateLocationItem),
    takeEvery(actions.UPDATE_CONTACT_ITEM, updateContactItem),
    takeEvery(actions.UPDATE_LINK_ITEM, updateLinkItem),
    takeEvery(
      actions.UPDATE_FOLDER_PROPERTIES_WITH_ACTIVITY,
      updateFolderPropertiesWithActivity,
    ),
    takeEvery(actions.UPDATE_PHOTO, updatePhoto),
    takeEvery(actions.UPDATE_VIDEO, updateVideo),
    takeEvery(actions.UPDATE_AUDIO, updateAudio),
    takeEvery(actions.UPDATE_CAPTION, updateCaption),
    takeEvery(actions.CREATE_QUICK_SPARK_FOLDER, createQuickSparkFolder),
    takeEvery(actions.SET_LOCK_STATUS, setLockStatus),
  ]);
}
