import React from 'react';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import PublicRoutes from 'router';
import ErrorBoundary from './errorBoundary';
import Boot from 'boot';

function DashApp() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PublicRoutes />
      </Provider>
    </ErrorBoundary>
  );
}

Boot()
  .then(() => DashApp())
  .catch((error) => console.error(error));

export default DashApp;
