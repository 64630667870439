import actions from './actions';
import { addLivePlatformToHeader, parseJwt } from 'utility/helpers';

const initState = {
  loading: false,
  email: null,
  isLoggedIn: false,
  id: null,
  refreshingToken: false,
  subscribedPlan: null,
  firstName: null,
  lastName: null,
  isVerified: true,
  remainingVerificationMinutes: null,
  modalVisible: false,
  verifyingOtp: false,
  deactivateReason: [],
  phoneNumber: null,
  avatar: null,
  deactivated: null,
  deactivatedAt: null,
  workspaceId: null,
  changePasswordVisible: false,
  accountDeactivationVisible: false,
  deactivateConfirmationVisible: false,
  reactivateConfirmationVisible: false,
  deactivationLoader: false,
  activationLoader: false,
  verifyModalVisible: false,
  registerLoader: false,
  loginLoader: false,
  history: undefined,
  isOnboardComplete: undefined,
  locationAccess: {
    loading: false,
    status: false,
  },
  onBoardingModal: {
    visible: false,
    completionModalVisible: false,
    current: 0,
  },
  passwordsList: [],
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_USER: {
      return {
        ...state,
        loginLoader: true,
      };
    }
    case actions.LOGIN_USER_SUCCESS: {
      const { payload } = action,
        {
          id,
          token,
          is_email_verified,
          remaining_verification_minutes,
          deactivated,
        } = payload;
      return {
        ...state,
        loginLoader: false,
        isLoggedIn: true,
        id,
        subscribedPlan: parseJwt(token)['plan_id'],
        isVerified: is_email_verified,
        remainingVerificationMinutes: remaining_verification_minutes,
        deactivated,
      };
    }
    case actions.LOGIN_USER_FAILURE: {
      return {
        ...state,
        loginLoader: false,
      };
    }
    case actions.LOGOUT_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.LOGOUT_USER_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        email: null,
        id: null,
        refreshingToken: false,
        subscribedPlan: null,
        firstName: null,
        lastName: null,
        loading: false,
        remainingVerificationMinutes: null,
      };
    }
    case actions.LOGOUT_USER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.REFRESH_TOKEN: {
      return {
        ...state,
        refreshingToken: true,
      };
    }
    case actions.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        deactivated: action.payload.deactivated,
        isLoggedIn: true,
        refreshingToken: false,
      };
    }
    case actions.REFRESH_TOKEN_FAILURE: {
      return {
        ...state,
        refreshingToken: false,
        isLoggedIn: false,
        email: null,
        id: null,
        subscribedPlan: null,
        firstName: null,
        lastName: null,
        loading: false,
        remainingVerificationMinutes: null,
      };
    }
    case actions.REGISTER_USER: {
      addLivePlatformToHeader();
      return {
        ...state,
        registerLoader: true,
      };
    }
    case actions.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        registerLoader: false,
      };
    }
    case actions.REGISTER_USER_FAILURE: {
      return {
        ...state,
        registerLoader: false,
      };
    }
    case actions.GET_USER_DETAILS_SUCCESS: {
      const { payload } = action;
      window.analytics.identify(payload.user_id, {
        email: payload.email,
        phoneNumber: payload.phone_number,
        avatar: payload.avatar,
        'Account Deactivated': payload.deactivated,
        deactivatedAt: payload.deactivated_at,
        firstName: payload.first_name,
        lastName: payload.last_name,
        'Workspace Id': payload.workspace_id,
      });
      localStorage.setItem('userId', payload.user_id);
      return {
        ...state,
        email: payload.email,
        phoneNumber: payload.phone_number,
        avatar: payload.avatar,
        deactivated: payload.deactivated,
        deactivatedAt: payload.deactivated_at,
        firstName: payload.first_name,
        lastName: payload.last_name,
        workspaceId: payload.workspace_id,
        remainingVerificationMinutes: payload.remaining_verification_minutes,
        isVerified: payload.is_email_verified,
        subscribedPlan: payload.plan,
        isOnboardComplete: payload.is_onboard_complete,
        locationAccess: {
          loading: false,
          status: payload.location_access,
        },
      };
    }
    case actions.MODAL_VISIBLE: {
      return {
        ...state,
        modalVisible: action.payload,
      };
    }
    case actions.VERIFY_OTP: {
      return {
        ...state,
        verifyingOtp: true,
      };
    }
    case actions.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        verifyingOtp: false,
        remainingVerificationMinutes: null,
        verifyModalVisible: false,
        isLoggedIn: true,
      };
    }
    case actions.VERIFY_OTP_FAILURE: {
      return {
        ...state,
        verifyingOtp: false,
      };
    }
    case actions.RESEND_OTP: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.RESEND_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.RESEND_OTP_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.FETCH_DEACTIVATE_REASON_LIST_SUCCESS: {
      return {
        ...state,
        deactivateReason: action.payload,
      };
    }
    case actions.FORGOT_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        modalVisible: true,
      };
    }
    case actions.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.UPDATE_USER_DETAILS_SUCCESS: {
      const { payload } = action;
      localStorage.setItem('userId', payload.user_id);
      return {
        ...state,
        email: payload.email,
        phoneNumber: payload.phone_number,
        avatar: payload.avatar,
        deactivated: payload.deactivated,
        deactivatedAt: payload.deactivated_at,
        firstName: payload.first_name,
        lastName: payload.last_name,
        workspaceId: payload.workspace_id,
        remainingVerificationMinutes: payload.remaining_verification_minutes,
      };
    }
    case actions.CHANGE_PASSWORD_VISIBLE: {
      return {
        ...state,
        changePasswordVisible: action.payload,
      };
    }
    case actions.ACCOUNT_DEACTIVATION_VISIBLE: {
      return {
        ...state,
        accountDeactivationVisible: action.payload,
      };
    }
    case actions.DEACTIVATE_ACCOUNT: {
      return {
        ...state,
        deactivationLoader: true,
      };
    }
    case actions.DEACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        deactivationLoader: false,
        deactivated: true,
      };
    }
    case actions.DEACTIVATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        deactivationLoader: false,
      };
    }
    case actions.DEACTIVATE_CONFIRMATION_VISIBLE: {
      return {
        ...state,
        deactivateConfirmationVisible: action.payload,
      };
    }
    case actions.REACTIVATE_CONFIRMATION_VISIBLE: {
      return {
        ...state,
        reactivateConfirmationVisible: action.payload,
      };
    }
    case actions.VERIFY_MODAL_VISIBLE: {
      return {
        ...state,
        verifyModalVisible: action.payload,
      };
    }
    case actions.CHANGE_USER_AVATAR_SUCCESS: {
      return {
        ...state,
        avatar: action.payload.avatar,
      };
    }
    case actions.ACTIVATE_ACCOUNT: {
      return {
        ...state,
        activationLoader: true,
      };
    }
    case actions.ACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        activationLoader: false,
        deactivated: false,
      };
    }
    case actions.ACTIVATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        activationLoader: false,
      };
    }
    case actions.SET_HISTORY: {
      return {
        ...state,
        history: action.history,
      };
    }
    case actions.CHANGE_USER_AVATAR_LOADER: {
      return {
        ...state,
        userAvatarLoader: action.payload,
      };
    }
    case actions.SET_ON_BOARDING_MODAL_VISIBLE: {
      const {
        visible,
        completionModalVisible,
        current,
        isOnboardComplete,
      } = action;
      return {
        ...state,
        isOnboardComplete: [true, false].includes(isOnboardComplete)
          ? isOnboardComplete
          : state.isOnboardComplete,
        onBoardingModal: {
          ...state.onBoardingModal,
          visible: [true, false].includes(visible)
            ? visible
            : state.onBoardingModal.visible,
          completionModalVisible: [true, false].includes(completionModalVisible)
            ? completionModalVisible
            : state.onBoardingModal.completionModalVisible,
          current:
            current || current === 0 ? current : state.onBoardingModal.current,
        },
      };
    }
    case actions.SET_LOCATION_ACCESS_STATUS: {
      return {
        ...state,
        locationAccess: {
          loading: true,
          status: state.locationAccess.status,
        },
      };
    }
    case actions.SET_LOCATION_ACCESS_STATUS_SUCCESS: {
      return {
        ...state,
        locationAccess: {
          loading: false,
          status: action.status,
        },
      };
    }
    case actions.SET_LOCATION_ACCESS_STATUS_FAILURE: {
      return {
        ...state,
        locationAccess: {
          loading: false,
          status: state.locationAccess.status,
        },
      };
    }
    case actions.GET_PASSWORDS_LIST_SUCCESS: {
      return {
        ...state,
        passwordsList: action.passwordsList,
      };
    }
    default: {
      return state;
    }
  }
}
