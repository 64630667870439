import actions from './actions';

const initState = {
  userDetail: '',
  currentTab: ['activities'],
  contentWidth: {
    capture: '0',
    activities: '100%',
    sparks: '0',
    shared: '0',
    settings: '0',
    search: '0',
  },
  headerWidth: {
    capture: '0',
    activities: '100%',
    sparks: '0',
    shared: '0',
    settings: '0',
    search: '0',
  },
  i18nextLng: !!localStorage.getItem('i18nextLng')
    ? localStorage.getItem('i18nextLng')
    : 'en',
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_LANGUAGE: {
      return {
        ...state,
        i18nextLng: action.payload,
      };
    }
    case actions.SET_ACTIVE_TAB_WIDTH: {
      const { contentWidth, currentTab, headerWidth } = action.payload;
      return {
        ...state,
        currentTab,
        contentWidth,
        headerWidth,
      };
    }
    default: {
      return state;
    }
  }
}
