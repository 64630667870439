import Auth from './auth/reducer';
import Subscription from './subscription/reducer';
import Settings from './settings/reducer';
import Activities from './activities/reducer';
import Capture from './capture/reducer';
import addItem from './addItem/reducer';
import SharedSparks from './sharedSparks/reducer';
import Folder from './folders/reducer';
import addBar from './addBar/reducer';
import commonReducer from './common/reducer';

export default {
  Auth,
  Subscription,
  Settings,
  Activities,
  Capture,
  addItem,
  SharedSparks,
  Folder,
  addBar,
  commonReducer,
};
