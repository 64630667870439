import 'components/shared/style.scss';
import React, { useState } from 'react';
import { Form, Input } from 'antd';
import eyeIcon from 'images/svgs/eye.svg';
import eyeClosedIcon from 'images/svgs/eye-closed.svg';

function InputPassword(props) {
  const { label, name, required, errorMessage } = props,
    [isValue, setIsValue] = useState(undefined),
    [isFocus, setIsFocus] = useState(undefined),
    onChange = (e) => {
      setIsValue(e.target.value);
    },
    setFocus = (status) => {
      setIsFocus(status);
    };

  return (
    <Form.Item
      name={name}
      label={label}
      className={`input ${isValue ? 'has-value' : ''} ${
        isFocus ? 'focus' : ''
      } `}
      validateTrigger={'onBlur'}
      rules={[
        {
          required: required,
          whitespace: required,
          message: errorMessage,
        },
      ]}
    >
      <Input.Password
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoComplete={'off'}
        iconRender={(visible) =>
          visible ? (
            <img src={eyeIcon} alt={'eye'} />
          ) : (
            <img src={eyeClosedIcon} alt={'eye-closed'} />
          )
        }
        maxLength={60}
      />
    </Form.Item>
  );
}

export default InputPassword;
