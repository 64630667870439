import { all, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

export function* temporaryAction() {
  try {
  } catch (error) {
    yield put({ type: actions.TEMP0RARY_ACTION_SUCCESS });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.TEMP0RARY_ACTION, temporaryAction)]);
}
