import actions from './actions';
import { cloneDeep } from 'lodash';
const initState = {
  captureLists: [],
  captureListLoader: false,
  selectedCaptureLists: [],
  deleteModalVisibility: false,
  deleteLoading: false,
  deleteId: '',
  page: 1,
  captureListLoadMore: false,
};

export default function captureReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CAPTURE_LISTS: {
      if (action.loadMore) {
        return { ...state, captureListLoadMore: true };
      }
      return {
        ...state,
        captureListLoader: true,
      };
    }
    case actions.GET_CAPTURE_LISTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        captureLists: data,
        captureListLoader: false,
        page: state.page + 1,
      };
    }
    case actions.UPDATE_CAPTURE_LISTS: {
      const { payload } = action,
        lists = cloneDeep(state.captureLists);
      lists.unshift(payload);
      return {
        ...state,
        captureLists: lists,
        captureListLoader: false,
        page: state.page + 1,
      };
    }
    case actions.UPDATE_CAPTURE_LISTS_SUCCESS: {
      return {
        ...state,
        captureLists: [...state.captureLists, ...action.payload.data],
        captureListLoadMore: false,
        page: state.page + 1,
      };
    }
    case actions.GET_CAPTURE_LISTS_FAILURE: {
      return {
        ...state,
        captureListLoader: false,
      };
    }
    case actions.DELETE_CAPTURE: {
      return {
        ...state,
        deleteLoading: true,
        deleteId: action.deleteId,
      };
    }
    case actions.DELETE_CAPTURE_SUCCESS: {
      return {
        ...state,
        captureLists: state.captureLists.filter(
          (data) => !action.payload.includes(data.item_id),
        ),
        deleteModalVisibility: false,
        deleteLoading: false,
      };
    }
    case actions.DELETE_CAPTURE_FAILURE: {
      return {
        ...state,
        deleteModalVisibility: false,
        deleteLoading: false,
      };
    }
    case actions.UPDATE_SELECTED_CAPTURE: {
      if (action.isAll) {
        return {
          ...state,
          selectedCaptureLists: action.payload,
        };
      } else {
        return {
          ...state,
          selectedCaptureLists: action.payload,
        };
      }
    }
    case actions.MOVE_CAPTURE_TO_NODE: {
      return {
        ...state,
        captureLists: state.captureLists.filter(
          (data) => data.item_id !== action.id,
        ),
      };
    }
    case actions.UPDATE_DELETE_CAPTURE_MODAL: {
      return {
        ...state,
        deleteModalVisibility: action.payload,
        deleteId: action.deleteId,
      };
    }
    case actions.SET_CURRENT_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    default:
      return state;
  }
}
