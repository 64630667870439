import actions from './actions';
import { Translate } from '../../utility/helpers';
import React from 'react';

const commonValues = {
    loader: false,
    modalVisible: false,
  },
  defaultLocation = {
    lat: 0,
    lng: 0,
  };

const initState = {
  currentAddress: <Translate>unable_to_get_the_data</Translate>,
  currentLocation: defaultLocation,
  isCurrentLocation: false,
  addressComponents: [],
  markerAddress: '',
  contact: commonValues,
  location: commonValues,
  note: commonValues,
  bullets: commonValues,
  numbers: commonValues,
  photo: commonValues,
  video: commonValues,
  audio: commonValues,
  url: commonValues,
  addingCurrentLocationInFolder: commonValues,
  addingCurrentLocationInCapture: commonValues,
  addingClipboardInFolder: commonValues,
  addingClipboardInCapture: commonValues,
  mediaType: 'photo',
  clipBoardUrlPreview: {
    title: undefined,
    description: undefined,
    image: undefined,
    link: undefined,
    previewLoader: false,
    isPreviewData: true,
  },
  modalUrlPreview: {
    title: false,
    description: false,
    image: false,
    link: undefined,
    previewLoader: false,
    isPreviewData: true,
    urlValue: undefined,
  },
  nodeIdStatus: false,
  clipBoardValue: '',
  clipboardLink: false,
  isClipBoardValue: false,
  addBarStatus: {
    nodeIdAddbar: undefined,
    captureItemsStatus: undefined,
    titleValue: undefined,
  },
};

export default function addBarReducer(state = initState, action) {
  switch (action.type) {
    case actions.ADD_BAR_MODAL_VISIBLE: {
      const { payload, addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          modalVisible: payload,
          loader: state[addBarType].loader,
        },
        nodeIdStatus: payload ? false : state.nodeIdStatus,
      };
    }
    case actions.ADD_CONTACT: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: true,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_CONTACT_SUCCESS: {
      const { addBarType, nodeIdStatus } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: false,
        },
        nodeIdStatus: nodeIdStatus,
      };
    }
    case actions.ADD_CONTACT_FAILURE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_NOTE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: true,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_NOTE_SUCCESS: {
      const { addBarType, nodeIdStatus } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: false,
        },
        nodeIdStatus: nodeIdStatus,
      };
    }
    case actions.ADD_NOTE_FAILURE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_PHOTO: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: true,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_PHOTO_SUCCESS: {
      const { addBarType, nodeIdStatus } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: false,
        },
        nodeIdStatus: nodeIdStatus,
      };
    }
    case actions.ADD_PHOTO_FAILURE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_VIDEO: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: true,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_VIDEO_SUCCESS: {
      const { addBarType, nodeIdStatus } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: false,
        },
        nodeIdStatus: nodeIdStatus,
      };
    }
    case actions.ADD_VIDEO_FAILURE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_AUDIO: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: true,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_AUDIO_SUCCESS: {
      const { addBarType, nodeIdStatus } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: false,
        },
        nodeIdStatus: nodeIdStatus,
      };
    }
    case actions.ADD_AUDIO_FAILURE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_LOCATION: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: true,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_LOCATION_SUCCESS: {
      const { addBarType, nodeIdStatus } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: false,
        },
        nodeIdStatus: nodeIdStatus,
      };
    }
    case actions.ADD_LOCATION_FAILURE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_URL: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: true,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.ADD_URL_SUCCESS: {
      const { addBarType, nodeIdStatus } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: false,
        },
        nodeIdStatus: nodeIdStatus,
      };
    }
    case actions.ADD_URL_FAILURE: {
      const { addBarType } = action;
      return {
        ...state,
        [addBarType]: {
          loader: false,
          modalVisible: state[addBarType].modalVisible,
        },
        nodeIdStatus: false,
      };
    }
    case actions.GET_URL: {
      const { urlType } = action;
      return {
        ...state,
        [`${urlType}`]: {
          ...state[urlType],
          previewLoader: true,
          isPreviewData: true,
        },
      };
    }
    case actions.GET_URL_SUCCESS: {
      const { title, description, image, url } = action.payload;
      const { urlType, isPreviewData, urlValue } = action;
      return {
        ...state,
        [`${urlType}`]: {
          title,
          description,
          image,
          link: url,
          previewLoader: false,
          urlValue,
          isPreviewData: [true, false].includes(isPreviewData)
            ? isPreviewData
            : state[urlType].isPreviewData,
        },
      };
    }
    case actions.GET_URL_FAILURE: {
      const { urlType } = action;
      return {
        ...state,
        [`${urlType}`]: {
          [`${urlType}`]: initState[urlType],
        },
      };
    }
    case actions.CURRENT_LOCATION: {
      const { address, location, addressComponents } = action.payload;
      return {
        ...state,
        currentAddress: address,
        currentLocation: location,
        isCurrentLocation: true,
        addressComponents,
      };
    }
    case actions.MARKER_LOCATION: {
      return {
        ...state,
        markerAddress: action.payload,
      };
    }
    case actions.SET_MEDIA_TYPE: {
      return {
        ...state,
        mediaType: action.payload,
      };
    }
    case actions.RESET_URL_PREVIEW: {
      const { urlType } = action;
      return {
        ...state,
        [`${urlType}`]: initState[urlType],
      };
    }
    case actions.RESET_NODE_ID_STATUS: {
      return {
        ...state,
        nodeIdStatus: false,
      };
    }
    case actions.SET_CLIPBOARD_VALUE: {
      const {
        clipBoardValue,
        isClipBoardValue,
        clipboardLink,
      } = action.payload;
      return {
        ...state,
        clipBoardValue: clipBoardValue,
        isClipBoardValue: isClipBoardValue,
        clipboardLink: [true, false].includes(clipboardLink)
          ? clipboardLink
          : state.clipboardLink,
      };
    }
    case actions.SET_SELECTED_ADD_BAR_STATUS: {
      const { nodeIdAddbar, captureItemsStatus, titleValue } = action;
      return {
        ...state,
        addBarStatus: {
          nodeIdAddbar,
          captureItemsStatus,
          titleValue,
        },
      };
    }
    default: {
      return state;
    }
  }
}
