import React, { useEffect } from 'react';
import { Button, Select } from 'antd';
import {
  getCurrentProjectId,
  getProjectDetails,
  getUserId,
  Translate,
} from 'utility/helpers';
import { store } from 'redux/store';
import folderActions from 'redux/folders/actions';
import sharedSparkActions from 'redux/sharedSparks/actions';
import { disableCollapseOnFilter } from 'components/shared/constants';
import commonActions from 'redux/common/actions';

const { Option } = Select;
function SparksHeader({
  selectValue,
  isFromSharedSparks,
  expandedItems,
  listOrder,
}) {
  const onChangeSort = (sortBy) => {
    if (!isFromSharedSparks) {
      store.dispatch({
        type: folderActions.GET_NODES_LIST,
        sortFoldersBy: sortBy,
      });
      store.dispatch({
        type: folderActions.SET_SORT_BY_VALUE_FOLDERS,
        sortFoldersBy: sortBy,
      });
      let details = {
        ...getProjectDetails(),
        spark: {
          ...(getProjectDetails() && getProjectDetails().spark),
          [getCurrentProjectId()]: {
            ...(getProjectDetails() &&
              getProjectDetails().spark &&
              getProjectDetails().spark[getCurrentProjectId()]),
            sortBy,
          },
        },
      };
      localStorage.setItem(
        `spark-details-${getUserId()}`,
        JSON.stringify(details),
      );
    } else {
      store.dispatch({
        type: sharedSparkActions.SET_SORT_BY_VALUE_SHARED_SPARKS,
        payload: sortBy,
      });
      let details = {
        ...getProjectDetails(),
        sharedSpark: {
          ...(getProjectDetails() && getProjectDetails().sharedSpark),
          sortBy,
        },
      };
      localStorage.setItem(
        `spark-details-${getUserId()}`,
        JSON.stringify(details),
      );
    }
  };
  const onChangeCollapse = () => {
    const expandedElements =
      expandedItems.length === listOrder.length ? [] : listOrder;
    if (!isFromSharedSparks) {
      store.dispatch({
        type: folderActions.SET_EXPAND_FOLDER_DETAILS,
        payload: expandedElements,
      });
      let details = {
        ...getProjectDetails(),
        spark: {
          ...(getProjectDetails() && getProjectDetails().spark),
          [getCurrentProjectId()]: {
            ...(getProjectDetails() &&
              getProjectDetails().spark &&
              getProjectDetails().spark[getCurrentProjectId()]),
            expandedItems: expandedElements,
          },
        },
      };
      localStorage.setItem(
        `spark-details-${getUserId()}`,
        JSON.stringify(details),
      );
    } else {
      store.dispatch({
        type: sharedSparkActions.SET_EXPAND_SHARED_SPARK_DETAILS,
        payload: expandedElements,
      });
      let details = {
        ...getProjectDetails(),
        sharedSpark: {
          ...(getProjectDetails() && getProjectDetails().spark),
          expandedItems: expandedElements,
        },
      };
      localStorage.setItem(
        `spark-details-${getUserId()}`,
        JSON.stringify(details),
      );
    }
  };

  useEffect(
    () => () => {
      store.dispatch({
        type: commonActions.SET_INFO_MODAL_VISIBLE,
        module: isFromSharedSparks ? 'sparks' : 'folders',
        payload: {
          folderVisibility: false,
        },
      });
      store.dispatch({
        type: folderActions.UPDATE_SHARE_MODAL_VISIBILITY,
        payload: false,
      });
    },
    [isFromSharedSparks],
  );
  return (
    <div>
      {!disableCollapseOnFilter.includes(selectValue) && (
        <Button
          style={{ marginRight: '5px' }}
          onClick={onChangeCollapse}
          disabled={disableCollapseOnFilter.includes(selectValue)}
        >
          <span>
            <Translate>
              {`${
                expandedItems.length === listOrder.length
                  ? 'collapse_all'
                  : 'expand_all'
              }`}
            </Translate>
          </span>
        </Button>
      )}
      <Select
        style={{ width: '155px' }}
        onChange={onChangeSort}
        value={selectValue}
      >
        <Option value={'newest_first'}>
          <Translate>newest_first</Translate>
        </Option>
        <Option value={'oldest_first'}>
          <Translate>oldest_first</Translate>
        </Option>
        {!isFromSharedSparks && (
          <>
            <Option value={'free_space'}>
              <Translate>free_space</Translate>
            </Option>
            <Option value={'sparks_only'}>
              <Translate>sparks_only</Translate>
            </Option>
          </>
        )}
      </Select>
    </div>
  );
}

export default SparksHeader;
