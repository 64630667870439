import React from 'react';
import { message, Skeleton } from 'antd';
import { axiosClient } from './axiosClient';
import { store } from 'redux/store';
import actions from 'redux/auth/actions';
import AWS from 'aws-sdk';
import settings from '../config/settings';
import settingsAction from '../redux/settings/actions';
import activitiesAction from '../redux/activities/actions';
import foldersAction from '../redux/folders/actions';
import i18n from 'i18n';
import { cloneDeep, isEmpty, isEqual, times } from 'lodash';
import moment from 'moment';
import captureActions from 'redux/capture/actions';
import alertSuccess from 'images/svgs/whiteTick.svg';
import alertInfo from 'images/svgs/alertInfo.png';
import alertDanger from 'images/svgs/alertDanger.png';
import _ from 'lodash';
import folderActions from '../redux/folders/actions';
import exclamationMark from 'images/svgs/info-icon.svg';
import modalClose from 'images/svgs/close.svg';

let imageProperty = { degree: 0, step: 90, boundaryRad: 0 };

export function handleException(error) {
  const { response } = error,
    { data } = response;
  if (response && response.status === 401 && response.config.url !== '/login') {
    if (
      response.data.message === 'Token expired' ||
      response.data.message === 'Token not found'
    ) {
      store.dispatch({
        type: actions.REFRESH_TOKEN,
      });
    }
  }
  if (data.errors && data.errors.length > 0) {
    const errors = data.errors;
    if (errors.length > 1) {
      message.error({
        content: (
          <ul>
            {errors.map((error) => (
              <li>{error.message}</li>
            ))}
          </ul>
        ),
        className: 'custom-error-message',
      });
    } else {
      message.error(errors[0].message);
    }
  } else {
    if (data.message) {
      message.error(data.message);
    } else {
      if (data.error && data.error.message) {
        message.error(data.error.message);
      }
    }
  }
}

export function parseJwt(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function addLivePlatformToHeader() {
  axiosClient.interceptors.request.use(function (config) {
    config.headers['Live-Platform'] = 'web';
    return config;
  });
}

export function timeConvert(data) {
  const minutes = data % 60;
  const hours = (data - minutes) / 60;

  return `${hours ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${
    minutes
      ? `${Translate({ children: 'and' })} ${minutes} ${
        minutes > 1 ? 'minutes' : 'minute'
        }`
      : ''
  }`;
}

export function Translate({ children, data }) {
  return i18n.t(children, { data });
}
export function updateAwsSdk(data) {
  AWS.config.update({
    accessKeyId: data.access_key,
    secretAccessKey: data.secret_key,
    sessionToken: data.session_token,
  });
}

export function uploadMedia(data, successCallback, failureCallback) {
  const s3 = new AWS.S3();
  s3.upload(
    {
      Bucket: settings.BUCKET_NAME,
      Key: `${settings.BUCKET_DIRECTORY}/${data.name}`,
      ACL: 'public-read',
      Body: data.file,
      ContentType: data.file.type,
    },
    function (err, data) {
      if (err) {
        message.error(<Translate>media_upload_failed</Translate>);
        if (err.code === 'ExpiredToken') {
          store.dispatch({
            type: actions.REFRESH_TOKEN,
          });
        }
        failureCallback(err);
      } else {
        successCallback({ ...data, key: data.Key });
      }
    },
  );
}

export function updateWidth(tabs) {
  let singleTab = ['shared', 'settings', 'search'],
    defaultWidth = {
      capture: '0',
      activities: '0',
      sparks: '0',
      shared: '0',
      settings: '0',
      search: '0',
    },
    headerWidth = cloneDeep(defaultWidth);
  if (singleTab.includes(tabs[0]) || tabs.length === 1) {
    defaultWidth[tabs[0]] = '100%';
    headerWidth[tabs[0]] = '100%';
    return { defaultWidth, headerWidth };
  } else {
    if (
      tabs.includes('capture') &&
      tabs.includes('activities') &&
      tabs.includes('sparks')
    ) {
      defaultWidth.capture = '20%';
      defaultWidth.activities = '20%';
      defaultWidth.sparks = '60%';

      headerWidth.capture = '20%';
      headerWidth.activities = '20%';
      headerWidth.sparks = 'calc( 60% - 205px)';
    } else if (tabs.includes('capture') && tabs.includes('activities')) {
      defaultWidth.capture = '50%';
      defaultWidth.activities = '50%';

      headerWidth.capture = '50%';
      headerWidth.activities = 'calc(50% - 275px)';
    } else if (tabs.includes('capture') && tabs.includes('sparks')) {
      defaultWidth.capture = '20%';
      defaultWidth.sparks = '80%';

      headerWidth.capture = '20%';
      headerWidth.sparks = 'calc(80% - 275px)';
    } else if (tabs.includes('activities') && tabs.includes('sparks')) {
      defaultWidth.activities = '30%';
      defaultWidth.sparks = '70%';

      headerWidth.activities = '30%';
      headerWidth.sparks = 'calc(70% - 55px)';
    } else {
      defaultWidth.activities = '100%';
      headerWidth.activities = '100%';
    }
    return { defaultWidth, headerWidth };
  }
}
export function setActiveTab() {
  let defaultWidth, headerWidth, currentTab;
  const { pathname } = window.location;
  if (pathname.includes('settings')) {
    currentTab = ['settings'];
  } else if (pathname.includes('shared')) {
    currentTab = ['shared'];
  } else {
    currentTab = isEmpty(
      localStorage
        .getItem('activeTab')
        ?.split(',')
        .filter((data) => !['settings', 'shared'].includes(data)),
    )
      ? ['activities']
      : localStorage.getItem('activeTab')?.split(',');
  }
  defaultWidth = updateWidth(currentTab).defaultWidth;
  headerWidth = updateWidth(currentTab).headerWidth;
  localStorage.setItem('activeTab', currentTab);

  if (!isEqual(currentTab, store.getState().Settings.currentTab)) {
    updateBannerPosition(defaultWidth);
    store.dispatch({
      type: settingsAction.SET_ACTIVE_TAB_WIDTH,
      payload: { currentTab, contentWidth: defaultWidth, headerWidth },
    });
  }
}

export function reOrder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function isTargetZone(itemsList, folderId, folderItemId) {
  const item = itemsList[folderId].data.find(
    (data) => data.item_id === folderItemId,
  );
  return item && item.type === 'target_zone';
}

export function onDragEnd(
  result,
  changeFolderItemDragging,
  folderItemsList,
  foldersList,
) {
  const { source, destination, draggableId, combine } = result,
    { activityLists } = store.getState().Activities;
  changeFolderItemDragging(false);
  store.dispatch({
    type: foldersAction.SET_DESTINATION_ID,
    payload: {},
  });
  if (
    combine &&
    source.droppableId.includes('folders') &&
    combine.draggableId !== getCurrentProjectId()
  ) {
    let { activityLists } = store.getState().Activities;
    let activityName = activityLists.active.data.find(
      (data) => data.project_id === combine.draggableId,
    ).name;
    store.dispatch({
      type: foldersAction.MOVE_NODE_TO_ACTIVITY,
      payload: {
        movedTo: {
          project_id: combine.draggableId,
        },
        movedId: draggableId.replace('-folders', ''),
        destinationName: activityName,
      },
    });
  }
  if (
    source.droppableId !== 'activities' &&
    source.droppableId !== 'archived'
  ) {
    store.dispatch({
      type: foldersAction.SET_COMPONENT_IS_DRAGGING,
      payload: false,
    });
  }
  if (!destination) {
    return;
  }
  const sInd = source.index,
    dInd = destination.index,
    move = sInd - dInd;
  if (source.droppableId.includes('captures')) {
    if (destination.droppableId.includes('folder-items')) {
      store.dispatch({
        type: foldersAction.HIDE_TARGET_ZONE_ID,
        payload: '',
      });
      store.dispatch({
        type: foldersAction.ADD_ITEM_FROM_CAPTURE,
        payload: {
          details: {
            capture_ids: [draggableId],
            move_to: dInd + 1,
          },
          nodeId: destination.droppableId.replace('-folder-items', ''),
          dInd,
          captureLists: store.getState().Capture.captureLists,
        },
      });
      store.dispatch({
        type: captureActions.MOVE_CAPTURE_TO_NODE,
        id: draggableId,
      });
      makeFolderAppearOnTop(
        destination.droppableId.replace('-folder-items', ''),
      );
    } else if (destination.droppableId.includes('new-folder')) {
      store.dispatch({
        type: captureActions.MOVE_CAPTURE_TO_NODE,
        id: draggableId,
      });
      store.dispatch({
        type: foldersAction.CREATE_NODE_FROM_CAPTURE,
        payload: {
          details: {
            capture_ids: [draggableId],
          },
        },
      });
    } else if (destination.droppableId.includes('new-activity')) {
      store.dispatch({
        type: activitiesAction.ADD_NEW_ACTIVITY_MODAL,
        payload: result,
        visible: true,
        isFromDrag: true,
      });
    } else if (destination.droppableId.includes('quick-spark-drop-zone')) {
      let { currentTab } = store.getState().Settings,
        home = activityLists?.active?.data[0];
      if (!currentTab.includes('sparks')) {
        let changedTabs = [...currentTab, 'sparks'],
          defaultWidth = updateWidth(changedTabs).defaultWidth,
          headerWidth = updateWidth(changedTabs).headerWidth;
        localStorage.setItem('activeTab', changedTabs);
        store.dispatch({
          type: settingsAction.SET_ACTIVE_TAB_WIDTH,
          payload: {
            currentTab: changedTabs,
            contentWidth: defaultWidth,
            headerWidth,
          },
        });
        if (home?.project_id !== getCurrentProjectId()) {
          store.dispatch({
            type: folderActions.CHANGE_SELECTED_ACTIVITY,
            selectedActivity: home,
          });
          localStorage.setItem(
            `spark-projectId-${getUserId()}`,
            home.project_id,
          );
        }
      }
      setFreeSpace();
      store.dispatch({
        type: captureActions.MOVE_CAPTURE_TO_NODE,
        id: draggableId,
      });
      store.dispatch({
        type: foldersAction.CREATE_NODE_FROM_CAPTURE,
        payload: {
          details: {
            capture_ids: [draggableId],
          },
        },
        projectId: home.project_id,
        fromQuickSpark: true,
      });
    } else {
      return;
    }
  }
  if (source.droppableId.includes('folder-items')) {
    // for moving and reordering node-items
    let sourceId = source.droppableId.replace('-folder-items', ''),
      destinationId = destination.droppableId.replace('-folder-items', '');
    if (sourceId === destinationId && move !== 0) {
      let payload = {
        details: {
          move,
        },
        folderId: sourceId,
        folderItemId: draggableId,
        reOrderDetails: {
          sInd,
          dInd,
        },
      };
      store.dispatch({
        type: foldersAction.REORDER_NODE_ITEM,
        payload,
      });
    } else if (destination.droppableId === 'new-folder') {
      if (isTargetZone(folderItemsList, sourceId, draggableId)) {
        return;
      } else {
        store.dispatch({
          type: foldersAction.CONVERTING_ITEM_TO_NODE,
          payload: {
            nodeId: source.droppableId.replace('-folder-items', ''),
            details: {
              project_id: getCurrentProjectId(),
              items: [draggableId],
            },
          },
        });
      }
    } else if (destination.droppableId.includes('new-activity')) {
      if (isTargetZone(folderItemsList, sourceId, draggableId)) {
        return;
      } else {
        store.dispatch({
          type: activitiesAction.ADD_NEW_ACTIVITY_MODAL,
          payload: result,
          visible: true,
        });
      }
    } else if (
      sourceId !== destinationId &&
      destinationId !== 'quick-spark-drop-zone'
    ) {
      store.dispatch({
        type: foldersAction.HIDE_TARGET_ZONE_ID,
        payload: '',
      });
      if (isTargetZone(folderItemsList, sourceId, draggableId)) {
        return;
      } else {
        makeFolderAppearOnTop(
          destination.droppableId.replace('-folder-items', ''),
        );
        let destinationName = foldersList.find(
          (data) => data.node_id === destinationId,
        ).name;
        let payload = {
          details: {
            items: [draggableId],
            node_id: destinationId, // target node id
            move_to: dInd + 1,
          },
          nodeId: sourceId, // source node id
          sInd,
          dInd,
          destinationName,
        };
        store.dispatch({
          type: foldersAction.MOVE_NODE_ITEM,
          payload,
        });
      }
    }
  }
  if (move !== 0) {
    let payload = {
      details: {
        move,
      },
      projectId: draggableId,
      reOrderDetails: {
        sInd,
        dInd,
      },
    };
    if (source.droppableId === 'activities') {
      if (dInd) {
        store.dispatch({
          type: activitiesAction.REORDER_ACTIVE_ACTIVITIES,
          payload,
        });
      }
    } else if (source.droppableId === 'archived') {
      store.dispatch({
        type: activitiesAction.REORDER_ARCHIVED_ACTIVITIES,
        payload,
      });
    }
  }
}

let draggingOverEle;
export function onDragUpdate(result, foldersList) {
  const { source, destination, combine } = result,
    { folderItemsList } = store.getState().Folder;
  if (
    source.droppableId.includes('folder-items') ||
    source.droppableId.includes('captures')
  ) {
    zIndexUpdate(result, foldersList);
    if (
      (source.droppableId.includes('folder-items') ||
        source.droppableId.includes('captures')) &&
      destination &&
      destination.droppableId.includes('folder-items')
    ) {
      if (source.droppableId !== destination.droppableId) {
        let nodeId = destination.droppableId.replace('-folder-items', ''),
          targetZoneId = findTargetZone(folderItemsList[nodeId].data).item_id;
        store.dispatch({
          type: foldersAction.HIDE_TARGET_ZONE_ID,
          payload: { nodeId, targetZoneId },
        });
      } else {
        store.dispatch({
          type: foldersAction.HIDE_TARGET_ZONE_ID,
          payload: '',
        });
      }
    }
  }
  if (source.droppableId.includes('folders')) {
    let destinationId = destination
      ? destination.droppableId
      : combine?.droppableId;
    store.dispatch({
      type: foldersAction.SET_DESTINATION_ID,
      payload: { destinationId, sourceId: source.droppableId },
    });
    if (combine && combine.draggableId !== getCurrentProjectId()) {
      draggingOverEle = document.querySelectorAll(
        `#${CSS.escape(combine.draggableId)}-activities .ant-card`,
      )[0];
      draggingOverEle.classList.add('highlight-activity');
    } else if (draggingOverEle) {
      draggingOverEle.classList.remove('highlight-activity');
    }
  }
}

export function onRndDragStop(e, d, index, data, changeComponentSize) {
  const { foldersList } = store.getState().Folder;
  let droppedOnEle = allTheElements(e.clientX, e.clientY).find((data) =>
      data.id.includes('activities'),
    ),
    droppedOnEleId = droppedOnEle && droppedOnEle.id,
    draggingEle = d.node.classList[0],
    droppedOnNewActivity = allTheElements(e.clientX, e.clientY).find((data) =>
      data.id.includes('activity-drop-zone'),
    );

  if (Math.sign(d.x) !== -1 && d.y > 13) {
    store.dispatch({
      type: foldersAction.UPDATE_FOLDER,
      payload: {
        index,
        id: data.node_id,
        properties: {
          height:
            foldersList[index].properties &&
            foldersList[index].properties.height
              ? foldersList[index].properties.height
              : 0,
          width:
            foldersList[index].properties && foldersList[index].properties.width
              ? foldersList[index].properties.width
              : 0,
          x_coordinate: d.x,
          y_coordinate: d.y,
        },
      },
    });
  } else if (droppedOnNewActivity) {
    let res = {
      destination: { droppableId: droppedOnNewActivity.id },
      draggableId: draggingEle,
    };
    changeComponentSize(false);
    highlight(droppedOnNewActivity.id, draggingEle, true);
    store.dispatch({
      type: activitiesAction.ADD_NEW_ACTIVITY_MODAL,
      payload: res,
      visible: true,
    });
  } else if (
    droppedOnEleId &&
    droppedOnEleId.replace('-activities', '') !== getCurrentProjectId()
  ) {
    highlight(droppedOnEleId, draggingEle, true);
    changeComponentSize(false);
    let { activityLists } = store.getState().Activities;
    let activityName = activityLists.active.data.find(
      (data) => data.project_id === droppedOnEleId.replace('-activities', ''),
    ).name;
    store.dispatch({
      type: foldersAction.MOVE_NODE_TO_ACTIVITY,
      payload: {
        movedTo: {
          project_id: droppedOnEleId.replace('-activities', ''),
        },
        movedId: draggingEle,
        destinationName: activityName,
      },
    });
  } else {
    changeComponentSize(false);
  }
}

export function pixelsToNumbers(dimension) {
  return dimension && dimension !== 'auto'
    ? parseInt(dimension.replace('px', ''))
    : 0;
}

export function onResizeStop(ref, index, data, position) {
  store.dispatch({
    type: foldersAction.UPDATE_FOLDER,
    payload: {
      index,
      id: data.node_id,
      properties: {
        height: pixelsToNumbers(ref.style.height),
        width: pixelsToNumbers(ref.style.width),
        x_coordinate: position.x,
        y_coordinate: position.y,
      },
    },
  });
}

export function getUserId() {
  return localStorage.getItem('userId');
}

export function getCurrentProjectId() {
  let selectedActivity = null;
  if (localStorage.getItem(`spark-projectId-${getUserId()}`)) {
    selectedActivity = localStorage.getItem(`spark-projectId-${getUserId()}`);
  } else {
    selectedActivity = getFirstProjectId();
  }
  return selectedActivity;
}

export function getCurrentProjectName(activityLists) {
  let currentProjectId = getCurrentProjectId(),
    inActive =
      activityLists.active &&
      activityLists.active.data.find(
        (data) => data.project_id === currentProjectId,
      ),
    inArchived =
      activityLists.archived &&
      activityLists.archived.data.find(
        (data) => data.project_id === currentProjectId,
      );
  if (inActive) {
    return { name: inActive.name, avatar: inActive.avatar };
  } else if (inArchived) {
    return { name: inArchived.name, avatar: inArchived.avatar };
  }
}

export function getFirstProjectId() {
  let { activityLists } = store.getState().Activities;
  if (activityLists.active && activityLists.active.data.length) {
    return activityLists.active.data[0].project_id;
  } else if (activityLists.archived && activityLists.archived.data.length) {
    return activityLists.archived.data[0].project_id;
  }
}

export function updateHistory(data) {
  const history = store.getState().Auth.history;
  history.push(data);
}

export function onRndDivClick(e, index) {
  resetIds(index);
}

export function zIndexUpdate(result, foldersList) {
  const { destination } = result;
  let r1 = getElement();
  if (!destination) {
    return;
  }
  const dInd = foldersList.findIndex((data) =>
    destination.droppableId.includes(data.node_id),
  );
  if (r1) {
    resetIds(dInd);
    r1.setAttribute('id', 'appear-on-top');
  }
}

export function resetIds(dInd) {
  let el = document.getElementsByClassName('react-draggable');
  for (let i = 0; i < el.length; i++) {
    if (el[i].hasAttribute('id') && i !== dInd) el[i].setAttribute('id', '');
    else if (i === dInd) {
      el[i].setAttribute('id', 'appear-on-top');
    }
  }
}

export function getElement() {
  let el = document.getElementById('dragging-over');
  if (el) {
    return el.closest('.react-draggable');
  }
  return null;
}

export function allTheElements(x, y) {
  let stack = [];

  let allElements = document.getElementsByTagName('*');
  let len = allElements.length;

  for (let i = 0; i < len; i++) {
    let elm = allElements[i];
    let rect = elm.getBoundingClientRect();

    if (
      y >= rect.top &&
      y <= rect.bottom &&
      x >= rect.left &&
      x <= rect.right
    ) {
      stack.push(elm);
    }
  }
  return stack;
}

export function onRndDrag(e, d, params) {
  let droppedOnEle = allTheElements(e.clientX, e.clientY).find((data) =>
      data.id.includes('activities'),
    ),
    droppedOnNewActivity = allTheElements(e.clientX, e.clientY).find((data) =>
      data.id.includes('activity-drop-zone'),
    ),
    droppedOnEleId = droppedOnEle && droppedOnEle.id,
    draggingEle = d.node.classList[0],
    {
      rndDroppedOnEle,
      changeRndDroppedOn,
      shouldAppearSmall,
      changeComponentSize,
    } = params;
  if (droppedOnNewActivity) {
    if (droppedOnNewActivity.id !== rndDroppedOnEle) {
      changeRndDroppedOn(droppedOnNewActivity.id);
    }
    highlight(droppedOnNewActivity.id, draggingEle);
  } else if (
    droppedOnEleId &&
    droppedOnEleId.replace('-activities', '') !== getCurrentProjectId()
  ) {
    if (droppedOnEleId !== rndDroppedOnEle) {
      changeRndDroppedOn(droppedOnEleId);
    }
    highlight(droppedOnEleId, draggingEle);
  } else {
    highlight(rndDroppedOnEle, draggingEle, true);
  }
  if (Math.sign(d.x) === -1) {
    if (!shouldAppearSmall) {
      changeComponentSize(true);
    }
  } else if (shouldAppearSmall) {
    changeComponentSize(false);
  }
}

let prevDropEle = '';

export function highlight(dropping, dragging, reset) {
  let droppingEle = document.getElementById(dropping),
    draggingEle = document.getElementsByClassName(dragging)[0];
  if (dropping) {
    if (reset || (prevDropEle && dropping !== prevDropEle)) {
      if (prevDropEle) {
        droppingEle = document.getElementById(prevDropEle);
      }
      if (dropping === 'activity-drop-zone') {
        droppingEle.classList.remove('dragging-over');
      } else {
        droppingEle.childNodes[0].classList.remove('highlight-activity');
      }
      draggingEle.style.opacity = '100%';
      prevDropEle = dropping;
    } else {
      prevDropEle = dropping;
      if (dropping === 'activity-drop-zone') {
        droppingEle.classList.add('dragging-over');
      } else {
        droppingEle.childNodes[0].classList.add('highlight-activity');
      }
      draggingEle.style.opacity = '95%';
    }
  }
}

export function copyToClipbord(data) {
  let textArea = document.createElement('textarea');
  textArea.value = data;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  textArea.remove();
  message.success('Copied to clipboard');
}

export function loadingSkeleton(count, className) {
  return times(count, () => (
    <Skeleton active className={className ? className : null} title={false} />
  ));
}

export function dateConverter(myDate) {
  const today = moment(new Date());

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar({
    // when the date is closer, specify custom values
    sameDay: '[Today]',
    lastWeek: function (now) {
      const diffInHours = today.diff(myDate, 'hours');
      const diffInWeeks = today.diff(myDate, 'weeks');
      const startOfWeek = moment(now).startOf('week').format('YYYY-MM-DD');
      if (moment(startOfWeek).isAfter(myDate)) {
        return '[Last week]';
      } else if (diffInHours > 48 && diffInWeeks < 1) {
        return `[${moment(myDate).format('MMMM Do YYYY')}]`;
      }
    },
    lastDay: '[Yesterday]',
    // when the date is further away, use from-now functionality
    sameElse: noOfDays(myDate),
  });
}

function startEndWeeks(isStart, numberOfWeeks) {
  return isStart
    ? moment()
      .subtract(numberOfWeeks, 'weeks')
      .startOf('week')
      .format('YYYY-MM-DD')
    : moment()
      .subtract(numberOfWeeks, 'weeks')
      .endOf('week')
      .format('YYYY-MM-DD');
}

function isBetweenTwoDates(startDate, endDate, myDate) {
  return (
    (moment(myDate).isBefore(endDate) && moment(myDate).isAfter(startDate)) ||
    moment(endDate).isSame(myDate) ||
    moment(startDate).isSame(myDate)
  );
}

function noOfDays(myDate) {
  const startOfLastWeek = startEndWeeks(true, 1),
    endOfLastWeek = startEndWeeks(false, 1),
    startOfTwoWeeks = startEndWeeks(true, 2),
    endOfTwoWeeks = startEndWeeks(false, 2),
    startOfThreeWeeks = startEndWeeks(true, 3),
    endOfThreeWeek = startEndWeeks(false, 3),
    startOfFourWeeks = startEndWeeks(true, 4),
    endOfFourWeek = startEndWeeks(false, 4),
    startOfMonth = moment().startOf('months').format('YYYY-MM-DD');
  if (moment(myDate).isBefore(startOfMonth)) {
    let a = moment(),
      b = moment(myDate),
      years = a.diff(b, 'year');
    b.add(years, 'years');
    let months = a.diff(b, 'months');
    b.add(months, 'months');
    let noOfMonths = moment().month() - moment(myDate).month();
    if (noOfMonths === 1) {
      return '[Last month]';
    } else if (years === 1) {
      return '[A year ago]';
    } else if (years > 1) {
      return `[${years} years ago]`;
    } else {
      return `[${noOfMonths} months ago]`;
    }
  } else if (isBetweenTwoDates(startOfLastWeek, endOfLastWeek, myDate)) {
    return '[Last week]';
  } else if (isBetweenTwoDates(startOfTwoWeeks, endOfTwoWeeks, myDate)) {
    return '[2 weeks ago]';
  } else if (isBetweenTwoDates(startOfThreeWeeks, endOfThreeWeek, myDate)) {
    return '[3 weeks ago]';
  } else if (isBetweenTwoDates(startOfFourWeeks, endOfFourWeek, myDate)) {
    return '[4 weeks ago]';
  }
}

export function captureListUpdate(myDate) {
  const today = moment(new Date());
  return moment(myDate).calendar({
    sameDay: function () {
      const diffInMin = today.diff(myDate, 'minutes');
      const diffInHours = today.diff(myDate, 'hours');
      if (diffInMin < 1) {
        return '[Captured just now]';
      } else if (diffInMin >= 1 && diffInMin < 60) {
        return `[Captured ${diffInMin} minute${
          diffInMin !== 1 ? 's' : ''
        } ago]`;
      } else if (
        diffInMin > 60 &&
        diffInHours <= 24 &&
        moment(new Date(myDate)).format('HHmm') < 1200
      ) {
        return '[This morning]';
      } else {
        return '[Today]';
      }
    },
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: noOfDays(myDate),
  });
}

export function captureGrouping(sparksList) {
  const group = {};
  sparksList.forEach((spark) => {
    const period = captureListUpdate(spark.created_at);
    if (group[period]) {
      group[period].push(spark);
    } else {
      group[period] = [spark];
    }
  });
  return group;
}

export function arrangeHash(object, isNewestFirst) {
  let order = [
      'Today',
      'Yesterday',
      'Last week',
      'weeks ago',
      'Last month',
      'months ago',
      'A year ago',
      'years ago',
    ],
    arrangeArr = [],
    checkMonthsAgo = [],
    checkWeeksAgo = [],
    checkYearsAgo = [],
    keys = Object.keys(object);
  if (!isNewestFirst) {
    order = _.reverse(order);
  }
  order.forEach((orderData) => {
    keys.forEach((keyData) => {
      if (keyData.includes(orderData)) {
        arrangeArr.push(keyData);
      } else if (
        !arrangeArr.includes(keyData) &&
        moment(keyData, 'MMMM DD YYYY').isValid()
      ) {
        order.splice(2, 0, keyData);
      }
    });
  });

  checkMonthsAgo = arrangeArr.filter((data) => data.includes('months ago'));
  checkWeeksAgo = arrangeArr.filter((data) => data.includes('weeks ago'));
  checkYearsAgo = arrangeArr.filter((data) => data.includes('years ago'));
  arrangeDaysAgo(checkMonthsAgo, arrangeArr, isNewestFirst);
  arrangeDaysAgo(checkWeeksAgo, arrangeArr, isNewestFirst);
  arrangeDaysAgo(checkYearsAgo, arrangeArr, isNewestFirst);

  return _.uniqWith(arrangeArr, _.isEqual);
}

export function arrangeDaysAgo(checkAgo, arr, isNewestFirst) {
  if (checkAgo.length > 1) {
    let index = arr.findIndex((data) => data === checkAgo[0]);
    arr.splice(index, checkAgo.length, ...sortFunc(checkAgo, isNewestFirst));
  }
}

export function sortFunc(sortArr, isNewestFirst) {
  return isNewestFirst ? sortArr.sort() : sortArr.sort((a, b) => b - a);
}

export function grouping(sparksList, groupArchived, isFromSharedSparks) {
  const group = {};
  sparksList.forEach((spark) => {
    if ((groupArchived && !spark.archived) || !groupArchived) {
      const period = dateConverter(
        isFromSharedSparks ? spark.shared_at : spark.created_at,
      );
      if (group[period]) {
        group[period].push(spark);
      } else {
        group[period] = [spark];
      }
    } else if (groupArchived && spark.archived) {
      if (group['Archived']) {
        group['Archived'].push(spark);
      } else {
        group['Archived'] = [spark];
      }
    }
  });
  return group;
}

export function copyToClipboard(str, container) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  showAlert(container, 'copied', 'success', '', 3000);
}

export function onDragStart(result, changeFolderItemDragging, folderItemsList) {
  const { source } = result;
  if (
    source.droppableId !== 'activities' &&
    source.droppableId !== 'archived' &&
    !source.droppableId.includes('folders')
  ) {
    store.dispatch({
      type: foldersAction.SET_COMPONENT_IS_DRAGGING,
      payload: true,
    });
  }
  if (
    (source.droppableId.includes('folder-items') &&
      !isTargetZone(
        folderItemsList,
        source.droppableId.replace('-folder-items', ''),
        result.draggableId,
      )) ||
    source.droppableId.includes('captures')
  ) {
    changeFolderItemDragging(true);
  }
  store.dispatch({
    type: foldersAction.DISABLE_COMBINE_ENABLED,
    payload: !source.droppableId.includes('activities'),
  })
}

export function onBeforeDragStart() {
  store.dispatch({
    type: foldersAction.UPDATE_RENDER_VARIABLE,
    payload: false,
  });
}

const shareModalFeatures =
  'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600';

export function shareToFacebook(data) {
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${escape(data)}`,
    '',
    shareModalFeatures,
  );
}
export function shareToTwitter(data) {
  window.open(
    `https://twitter.com/intent/tweet?text=${escape(data)}`,
    '',
    shareModalFeatures,
  );
}
export function shareToLinkedIn(data) {
  window.open(
    `https://linkedin.com/shareArticle?mini=true&url=${escape(data)}`,
    '',
    shareModalFeatures,
  );
}

export function pickIconColor(isActive) {
  return isActive ? '#FFA303' : '#000';
}

export function getProjectDetails() {
  return JSON.parse(localStorage.getItem(`spark-details-${getUserId()}`));
}

export function rotateImage(src, angle, callback) {
  if (src) {
    const image = new Image();
    image.src = src;
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      imageProperty.boundaryRad = Math.atan(image.width / image.height);
      imageProperty.degree = (360 + angle) % 360;
      getRotatedImage(
        imageProperty.image,
        {
          degree: imageProperty.degree,
        },
        callback,
      );
    };
    imageProperty.image = image;
  } else {
    console.log('Not a valid image');
  }
}

export function calcProjectedRectSizeOfRotatedRect(size, rad) {
  const { width, height } = size;

  const rectProjectedWidth =
    Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
  const rectProjectedHeight =
    Math.abs(width * Math.sin(rad)) + Math.abs(height * Math.cos(rad));

  return { width: rectProjectedWidth, height: rectProjectedHeight };
}
function randomSection() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}
export function randomIdGenerate(name) {
  return `${name}-${randomSection()}-${randomSection()}-${new Date().getTime()}`;
}

export function getRotatedImage(image, angle, callback) {
  const canvas = document.createElement('canvas');
  const { degree, rad: _rad } = angle;

  const rad = _rad || (degree * Math.PI) / 180 || 0;

  const { width, height } = calcProjectedRectSizeOfRotatedRect(
    { width: image.width, height: image.height },
    rad,
  );
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx.save();

  const sin_Height = image.height * Math.abs(Math.sin(rad));
  const cos_Height = image.height * Math.abs(Math.cos(rad));
  const cos_Width = image.width * Math.abs(Math.cos(rad));
  const sin_Width = image.width * Math.abs(Math.sin(rad));

  let xOrigin, yOrigin;

  if (rad < imageProperty.boundaryRad) {
    xOrigin = Math.min(sin_Height, cos_Width);
    yOrigin = 0;
  } else if (rad < Math.PI / 2) {
    xOrigin = Math.max(sin_Height, cos_Width);
    yOrigin = 0;
  } else if (rad < Math.PI / 2 + imageProperty.boundaryRad) {
    xOrigin = width;
    yOrigin = Math.min(cos_Height, sin_Width);
  } else if (rad < Math.PI) {
    xOrigin = width;
    yOrigin = Math.max(cos_Height, sin_Width);
  } else if (rad < Math.PI + imageProperty.boundaryRad) {
    xOrigin = Math.max(sin_Height, cos_Width);
    yOrigin = height;
  } else if (rad < (Math.PI / 2) * 3) {
    xOrigin = Math.min(sin_Height, cos_Width);
    yOrigin = height;
  } else if (rad < (Math.PI / 2) * 3 + imageProperty.boundaryRad) {
    xOrigin = 0;
    yOrigin = Math.max(cos_Height, sin_Width);
  } else if (rad < Math.PI * 2) {
    xOrigin = 0;
    yOrigin = Math.min(cos_Height, sin_Width);
  }
  ctx.translate(xOrigin, yOrigin);
  ctx.rotate(rad);
  ctx.drawImage(image, 0, 0);

  ctx.restore();
  try {
    if (canvas.toBlob) {
      canvas.toBlob(function (blob) {
        const file = new File(
          [blob],
          `${randomIdGenerate('rotatedImage')}.png`,
          blob,
        );
        callback(file, canvas.toDataURL('image/png'));
      }, 'image/png');
    } else {
      const dataURL = canvas.toDataURL('image/png');
      fetch(dataURL)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            `${randomIdGenerate('rotatedImage')}.png`,
            blob,
          );
          callback(file, canvas.toDataURL('image/png'));
        });
    }
  } catch (err) {
    console.log(err);
  }
}

const alertIcons = {
  success: alertSuccess,
  info: alertInfo,
  error: alertDanger,
};

export function showAlert(parent, bannerText, type, data, delay) {
  const text = i18n.t(bannerText, { data }),
    bannerElement = document.getElementsByClassName('banner-message');
  document
    .getElementsByClassName(parent.replace('.', ''))[0]
    .classList.add('parent-banner');
  if (type === 'success') {
    if (bannerElement) {
      setTimeout(() => {
        bannerElement[0].classList.add('alert-off');
      }, delay);
      setTimeout(() => {
        document.getElementById('infoSuccess').remove();
      }, delay + 1000);
    }
    document.querySelector(parent).insertAdjacentHTML(
      'afterbegin',
      `<div class='banner-message ${type}' id="infoSuccess">
      <div class='banner-icon'>
        <img src='${alertIcons[type]}' alt='alert-icon' />
      </div>
      <div class='banner-text'>${text}</div>
    </div>`,
    );
  }
  if (type === 'info') {
    document.querySelector(parent).insertAdjacentHTML(
      'afterbegin',
      `<div class='banner-message info' id="infoBanner">
      <div class='banner-info-icon'>
        <img src='${exclamationMark}' alt='alert-icon' />
      </div>
      <div class='banner-info-text'>${text}</div>
      <div class="info-close-icon" id="infoClosed"><img src='${modalClose}' alt='alert-icon' /></div>
    </div>`,
    );
    let closeElement = document.getElementById('infoClosed');
    closeElement.onclick = function () {
      setTimeout(() => {
        bannerElement[0].classList.add('alert-off');
      }, delay);
      setTimeout(() => {
        document.getElementById('infoBanner').remove();
      }, delay + 1000);
    };
    document.getElementById('infoBanner').scrollIntoView();
  }
  if (!parent.includes('body')) {
    document
      .getElementById(type === 'success' ? 'infoSuccess' : 'infoBanner')
      .scrollIntoView();
  }
}

export function updateBannerPosition(contentWidth) {
  let bannerElement = document.querySelectorAll('.verify-notification')[0],
    bannerElementParent =
      bannerElement &&
      bannerElement.parentElement &&
      bannerElement.parentElement.parentElement;
  if (bannerElementParent) {
    if (contentWidth.capture === '100%') {
      bannerElementParent.style.bottom = '40px';
    } else {
      bannerElementParent.style.bottom = '0px';
    }
  }
}
export function getFullScreenElement() {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  );
}
export function toggleFullScreen(element) {
  if (!getFullScreenElement()) {
    try {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

export function makeFolderAppearOnTop(nodeId) {
  const { sortFoldersBy } = store.getState().Folder;
  if (sortFoldersBy === 'free_space') {
    let el = document.getElementsByClassName(nodeId)[0];
    if (el) {
      el.setAttribute('id', 'appear-on-top');
    }
  }
  store.dispatch({
    type: foldersAction.SET_SELECTED_NODE_ID,
    payload: nodeId,
  });
}

export function findTargetZone(itemsList) {
  return itemsList.find((data) => data.type === 'target_zone');
}
export function getDuplicateNodeName(data) {
  if (data.version) {
    return `${data.name}${Translate({ children: 'node_fist_version' })}(${
      data.version + 1
    })`;
  }
  return `${data.name}${Translate({ children: 'node_fist_version' })}`;
}

export function findActivityData(activityId) {
  let { activityLists } = store.getState().Activities;
  return activityLists.active.data.find(
    (data) => data.project_id === activityId,
  );
}

export function formatBytes(a, b = 2) {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  }`;
}

export function setShouldActivityReorder(shouldReorder) {
  store.dispatch({
    type: foldersAction.SET_SHOULD_REORDER,
    payload: shouldReorder,
  });
}

export function setFreeSpace() {
  store.dispatch({
    type: folderActions.SET_SORT_BY_VALUE_FOLDERS,
    sortFoldersBy: 'free_space',
  });
  let details = {
    ...getProjectDetails(),
    spark: {
      ...(getProjectDetails() && getProjectDetails().spark),
      [getCurrentProjectId()]: {
        ...(getProjectDetails() &&
          getProjectDetails().spark &&
          getProjectDetails().spark[getCurrentProjectId()]),
        sortBy: 'free_space',
      },
    },
  };
  localStorage.setItem(`spark-details-${getUserId()}`, JSON.stringify(details));
}
