const actions = {
  GET_SHARED_SPARKS: 'GET_SHARED_SPARKS',
  GET_SHARED_SPARKS_SUCCESS: 'GET_SHARED_SPARKS_SUCCESS',
  GET_SHARED_SPARKS_FAILURE: 'GET_SHARED_SPARKS_FAILURE',
  PAUSE_SHARED_SPARKS: 'PAUSE_SHARED_SPARKS',
  RESUME_SHARED_SPARKS: 'RESUME_SHARED_SPARKS',
  ARCHIVE_SHARED_SPARKS: 'ARCHIVE_SHARED_SPARKS',
  UNARCHIVE_SHARED_SPARKS: 'UNARCHIVE_SHARED_SPARKS',
  DELETE_SHARED_SPARK: 'DELETE_SHARED_SPARK',
  DELETE_SHARED_SPARK_SUCCESS: 'DELETE_SHARED_SPARK_SUCCESS',
  DELETE_SHARED_SPARK_FAILURE: 'DELETE_SHARED_SPARK_FAILURE',
  SHARE_MODAL_VISIBLE: 'SHARE_MODAL_VISIBLE',
  RE_SHARE_SPARK: 'RE_SHARE_SPARK',
  GET_ITEM_LIST: 'GET_ITEM_LIST',
  GET_ITEM_LIST_SUCCESS: 'GET_ITEM_LIST_SUCCESS',
  GET_ITEM_LIST_FAILURE: 'GET_ITEM_LIST_FAILURE',
  SET_EXPAND_SHARED_SPARK_DETAILS: 'SET_EXPAND_SHARED_SPARK_DETAILS',
  SET_SORT_BY_VALUE_SHARED_SPARKS: 'SET_SORT_BY_VALUE_SHARED_SPARKS',
  GET_URL_PREVIEW: 'GET_URL_PREVIEW',
  GET_URL_PREVIEW_SUCCESS: 'GET_URL_PREVIEW_SUCCESS',
  GET_URL_PREVIEW_FAILURE: 'GET_URL_PREVIEW_FAILURE',
  SET_SELECTED_ID: 'SET_SELECTED_ID',
  DELETE_SHARED_SPARK_MODAL_VISIBLE: 'DELETE_SHARED_SPARK_MODAL_VISIBLE',
};

export default actions;
