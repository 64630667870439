import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from 'redux/addBar/actions';
import folderActions from 'redux/folders/actions';
import captureActions from 'redux/capture/actions';
import {
  handleException,
  getCurrentProjectId,
  Translate,
  showAlert,
} from 'utility/helpers';
import { getRequest, postRequest } from 'utility/axiosClient';
import { message } from 'antd';
import React from 'react';

export function* addContact(params) {
  const { payload, addBarType, captureItems } = params,
    differenceUrl = captureItems
      ? `projects/${getCurrentProjectId()}/nodes/`
      : '';
  try {
    const response = yield call(() =>
      postRequest(`${differenceUrl}capture/contact`, payload),
    );
    yield put({
      type: actions.ADD_CONTACT_SUCCESS,
      payload: response,
      addBarType,
      nodeIdStatus: payload.node_id,
    });
    if (captureItems) {
      yield put({
        type: folderActions.UPDATE_NODE_ITEMS_DATA,
        payload: response.data,
      });
    } else {
      yield put({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: response.data,
      });
    }
    if (!captureItems) {
      window.analytics.track('Capture Item Added', { type: 'contact' });
    }
    showAlert(
      `${captureItems ? `.body${payload.node_id}` : '.capture-list-container'}`,
      `${
        captureItems
          ? 'contact_successfully_added'
          : 'contact_added_to_captures_list_successfully'
      }`,
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({
      type: actions.ADD_CONTACT_FAILURE,
      addBarType,
    });
    handleException(error);
  }
}

export function* addNote(params) {
  const { payload, addBarType, captureItems } = params,
    differenceUrl = captureItems
      ? `projects/${getCurrentProjectId()}/nodes/`
      : '';
  try {
    const response = yield call(() =>
      postRequest(`${differenceUrl}capture/note`, payload),
    );
    yield put({
      type: actions.ADD_NOTE_SUCCESS,
      payload: response,
      addBarType,
      nodeIdStatus: payload.node_id,
    });
    if (captureItems) {
      yield put({
        type: folderActions.UPDATE_NODE_ITEMS_DATA,
        payload: response.data,
      });
    } else {
      yield put({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: response.data,
      });
    }
    if (!captureItems) {
      window.analytics.track('Capture Item Added', {
        'Item Id': response.data.item_id,
        'Item Type': response.data.type,
        'Item UploadedAt': response.data.created_at,
      });
    }
    showAlert(
      `${captureItems ? `.body${payload.node_id}` : '.capture-list-container'}`,
      `${
        captureItems
          ? 'notes_successfully_added'
          : 'notes_added_to_captures_list_successfully'
      }`,
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({
      type: actions.ADD_NOTE_FAILURE,
      addBarType: addBarType,
    });
    handleException(error);
  }
}

export function* addPhoto(params) {
  const { payload, addBarType, captureItems } = params,
    differenceUrl = captureItems
      ? `projects/${getCurrentProjectId()}/nodes/`
      : '';
  try {
    const response = yield call(() =>
      postRequest(`${differenceUrl}capture/image`, payload),
    );
    yield put({
      type: actions.ADD_PHOTO_SUCCESS,
      payload: response,
      addBarType,
      nodeIdStatus: payload.node_id,
    });
    if (captureItems) {
      yield put({
        type: folderActions.UPDATE_NODE_ITEMS_DATA,
        payload: response.data,
      });
    } else {
      yield put({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: response.data,
      });
    }
    if (!captureItems) {
      window.analytics.track('Capture Item Added', {
        'Item Id': response.data.item_id,
        'Item Type': response.data.type,
        'Item UploadedAt': response.data.created_at,
      });
    }
    showAlert(
      `${captureItems ? `.body${payload.node_id}` : '.capture-list-container'}`,
      `${
        captureItems
          ? 'photo_successfully_added'
          : 'photo_added_to_captures_list_successfully'
      }`,
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({
      type: actions.ADD_PHOTO_FAILURE,
      addBarType,
    });
    handleException(error);
  }
}

export function* addVideo(params) {
  const { payload, addBarType, captureItems, title } = params,
    differenceUrl = captureItems
      ? `projects/${getCurrentProjectId()}/nodes/`
      : '',
    messageValue = Translate({ children: 'recording_has_been_saved' });
  try {
    const response = yield call(() =>
      postRequest(`${differenceUrl}capture/video`, payload),
    );
    yield put({
      type: actions.ADD_VIDEO_SUCCESS,
      payload: response,
      addBarType,
      nodeIdStatus: payload.node_id,
    });
    if (captureItems) {
      yield put({
        type: folderActions.UPDATE_NODE_ITEMS_DATA,
        payload: response.data,
      });
    } else {
      yield put({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: response.data,
      });
    }
    if (!captureItems) {
      window.analytics.track('Capture Item Added', {
        'Item Id': response.data.item_id,
        'Item Type': response.data.type,
        'Item UploadedAt': response.data.created_at,
      });
    }
    showAlert(
      `${captureItems ? `.body${payload.node_id}` : '.capture-list-container'}`,
      `${messageValue} ${title ? title : 'Captures'}`,
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({
      type: actions.ADD_VIDEO_FAILURE,
      addBarType,
    });
    handleException(error);
  }
}

export function* addAudio(params) {
  const { payload, addBarType, captureItems, title } = params,
    differenceUrl = captureItems
      ? `projects/${getCurrentProjectId()}/nodes/`
      : '',
    messageValue = Translate({ children: 'recording_has_been_saved' });
  try {
    const response = yield call(() =>
      postRequest(`${differenceUrl}capture/audio`, payload),
    );
    yield put({
      type: actions.ADD_AUDIO_SUCCESS,
      payload: response,
      addBarType,
      nodeIdStatus: payload.node_id,
    });
    if (captureItems) {
      yield put({
        type: folderActions.UPDATE_NODE_ITEMS_DATA,
        payload: response.data,
      });
    } else {
      yield put({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: response.data,
      });
    }
    if (!captureItems) {
      window.analytics.track('Capture Item Added', {
        'Item Id': response.data.item_id,
        'Item Type': response.data.type,
        'Item UploadedAt': response.data.created_at,
      });
    }
    showAlert(
      `${captureItems ? `.body${payload.node_id}` : '.capture-list-container'}`,
      `${messageValue} ${title ? title : 'Captures'}`,
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({
      type: actions.ADD_AUDIO_FAILURE,
      addBarType,
    });
    handleException(error);
  }
}

export function* addUrl(params) {
  const { payload, addBarType, captureItems } = params,
    differenceUrl = captureItems
      ? `projects/${getCurrentProjectId()}/nodes/`
      : '';
  try {
    const response = yield call(() =>
      postRequest(`${differenceUrl}capture/url`, payload),
    );
    yield put({
      type: actions.ADD_URL_SUCCESS,
      payload: response,
      addBarType,
      nodeIdStatus: payload.node_id,
    });
    if (captureItems) {
      yield put({
        type: folderActions.UPDATE_NODE_ITEMS_DATA,
        payload: response.data,
      });
    } else {
      yield put({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: response.data,
      });
    }
    if (!captureItems) {
      window.analytics.track('Capture Item Added', {
        'Item Id': response.data.item_id,
        'Item Type': response.data.type,
        'Item UploadedAt': response.data.created_at,
      });
    }
    showAlert(
      `${captureItems ? `.body${payload.node_id}` : '.capture-list-container'}`,
      `${
        captureItems
          ? 'link_successfully_added'
          : 'link_added_to_captures_list_successfully'
      }`,
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({ type: actions.ADD_URL_FAILURE, addBarType });
    handleException(error);
  }
}

export function* getUrl(params) {
  const { urlType, payload, isFromUrl } = params,
    www = 'www.',
    https = 'https://',
    http = 'http://';
  try {
    let value = payload,
      protocal = window.protocal ? http : https;
    if (isFromUrl) {
      if (
        !value.startsWith(www) &&
        !value.startsWith(https) &&
        !value.startsWith(http)
      ) {
        value = `${www}${value}`;
      }
      if (!value.startsWith(https) && !value.startsWith(http)) {
        value = `${protocal}${value}`;
      }
    }
    const response = yield call(() => getRequest(`url-preview/?url=${value}`));
    if (response.data === '' && !window.protocal && isFromUrl) {
      window.protocal = true;
      yield put({
        type: actions.GET_URL,
        payload: params.payload,
        urlType: 'modalUrlPreview',
        isFromUrl: true,
      });
    } else {
      if (response.data === '' && urlType === 'modalUrlPreview' && isFromUrl) {
        message.error(<Translate data={value}>invalid_url_msg</Translate>);
      }
      yield put({
        type: actions.GET_URL_SUCCESS,
        payload: response.data,
        urlType,
        urlValue: value,
        isPreviewData: response.data ? false : true,
      });
      window.protocal = undefined;
    }
  } catch (error) {
    yield put({ type: actions.GET_URL_FAILURE, urlType });
    handleException(error);
  }
}

export function* addLocation(params) {
  const { payload, addBarType, captureItems } = params,
    differenceUrl = captureItems
      ? `projects/${getCurrentProjectId()}/nodes/`
      : '';
  try {
    const response = yield call(() =>
      postRequest(`${differenceUrl}capture/location`, payload),
    );
    yield put({
      type: actions.ADD_LOCATION_SUCCESS,
      payload: response,
      addBarType,
      nodeIdStatus: payload.node_id,
    });
    if (captureItems) {
      yield put({
        type: folderActions.UPDATE_NODE_ITEMS_DATA,
        payload: response.data,
      });
    } else {
      yield put({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: response.data,
      });
    }
    if (!captureItems) {
      window.analytics.track('Capture Item Added', {
        'Item Id': response.data.item_id,
        'Item Type': response.data.type,
        'Item UploadedAt': response.data.created_at,
      });
    }
    showAlert(
      `${captureItems ? `.body${payload.node_id}` : '.capture-list-container'}`,
      `${
        captureItems
          ? 'location_successfully_added'
          : 'location_successfully_added'
      }`,
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({
      type: actions.ADD_LOCATION_FAILURE,
      addBarType,
    });
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_CONTACT, addContact),
    takeEvery(actions.ADD_NOTE, addNote),
    takeEvery(actions.ADD_PHOTO, addPhoto),
    takeEvery(actions.ADD_VIDEO, addVideo),
    takeEvery(actions.ADD_AUDIO, addAudio),
    takeEvery(actions.ADD_URL, addUrl),
    takeEvery(actions.GET_URL, getUrl),
    takeEvery(actions.ADD_LOCATION, addLocation),
  ]);
}
