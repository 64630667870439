import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import './index.scss';
import 'styles/global.scss';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import Adjust from '@adjustcom/adjust-web-sdk';

Adjust.initSdk({
  appToken: process.env.REACT_APP_ADJUST_KEY,
  environment:
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? 'sandbox'
      : 'production',
});
ReactDOM.render(
  <React.StrictMode>
    <DashApp />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
