const actions = {
  GET_CAPTURE_LISTS: 'GET_CAPTURE_LISTS',
  GET_CAPTURE_LISTS_SUCCESS: 'GET_CAPTURE_LISTS_SUCCESS',
  GET_CAPTURE_LISTS_FAILURE: 'GET_CAPTURE_LISTS_FAILURE',
  UPDATE_CAPTURE_LISTS: 'UPDATE_CAPTURE_LISTS',
  DELETE_CAPTURE: 'DELETE_CAPTURE',
  DELETE_CAPTURE_SUCCESS: 'DELETE_CAPTURE_SUCCESS',
  DELETE_CAPTURE_FAILURE: 'DELETE_CAPTURE_FAILURE',
  UPDATE_SELECTED_CAPTURE: 'UPDATE_SELECTED_CAPTURE',
  MOVE_CAPTURE_TO_NODE: 'MOVE_CAPTURE_TO_NODE',
  MOVE_CAPTURE_TO_NODE_SUCCESS: 'MOVE_CAPTURE_TO_NODE_SUCCESS',
  MOVE_CAPTURE_TO_NODE_FAILURE: 'MOVE_CAPTURE_TO_NODE_FAILURE',
  UPDATE_DELETE_CAPTURE_MODAL: 'UPDATE_DELETE_CAPTURE_MODAL',
  UPDATE_CAPTURE_LISTS_SUCCESS: 'UPDATE_CAPTURE_LISTS_SUCCESS',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
};

export default actions;
