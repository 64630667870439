export default {
  HOST_URL: process.env.REACT_APP_API_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'content-language': !!localStorage.getItem('i18nextLng')
      ? localStorage.getItem('i18nextLng')
      : 'en',
  },
  BUCKET_NAME: process.env.REACT_APP_BUCKET_NAME,
  BUCKET_DIRECTORY: process.env.REACT_APP_BUCKET_DIRECTORY,
};
