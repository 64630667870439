import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { postRequest, getRequest, deleteRequest } from 'utility/axiosClient';
import {
  handleException,
  showAlert,
  getCurrentProjectId,
} from 'utility/helpers';
import { store } from 'redux/store';

export function* getSharedSparks() {
  try {
    const response = yield call(() => getRequest('user/sparks/shared'));
    yield put({
      type: actions.GET_SHARED_SPARKS_SUCCESS,
      payload: response.data.data,
    });
    const list = response.data.data;
    list.length > 0 &&
      list.forEach((item) => {
        store.dispatch({
          type: actions.GET_ITEM_LIST,
          payload: { projectId: item.project_id, nodeId: item.node_id },
        });
      });
  } catch (error) {
    yield put({ type: actions.GET_SHARED_SPARKS_FAILURE });
    handleException(error);
  }
}
export function* getItemlist(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `projects/${params.payload.projectId}/nodes/${params.payload.nodeId}/items/`,
      ),
    );
    yield put({
      type: actions.GET_ITEM_LIST_SUCCESS,
      payload: { data: response.data, id: params.payload.nodeId },
    });
  } catch (error) {
    yield put({ type: actions.GET_ITEM_LIST_FAILURE });
    handleException(error);
  }
}
export function* pauseSharedSparks(params) {
  try {
    yield call(() => postRequest(`user/sparks/shared/${params.payload}/pause`));
    window.analytics.track('Spark Paused Sharing', {
      'Shared url Status': 'Paused',
      'Spark Id': params.payload,
    });
    showAlert('.spark-container', 'menu_pause_message', 'success', '', 3000);
    yield put({ type: actions.GET_SHARED_SPARKS });
  } catch (error) {
    handleException(error);
  }
}
export function* resumeSharedSparks(params) {
  try {
    yield call(() =>
      postRequest(`user/sparks/shared/${params.payload}/resume`),
    );
    window.analytics.track('Spark Resumed Sharing', {
      'Shared url Status': 'Active',
      'Spark Id': params.payload,
    });
    showAlert('.spark-container', 'menu_resume_message', 'success', '', 3000);
    yield put({ type: actions.GET_SHARED_SPARKS });
  } catch (error) {
    handleException(error);
  }
}
export function* archiveSharedSparks(params) {
  try {
    yield call(() =>
      postRequest(`user/sparks/shared/${params.payload}/archive`),
    );
    yield put({ type: actions.GET_SHARED_SPARKS });
    window.analytics.track('Shared Spark Archived', {
      'Shared url Status': 'Paused',
      'Spark Id': params.payload,
    });
    showAlert('.spark-container', 'spark_archive_message', 'success', '', 3000);
  } catch (error) {
    handleException(error);
  }
}
export function* unarchiveSharedSparks(params) {
  try {
    yield call(() =>
      postRequest(`user/sparks/shared/${params.payload}/unarchive`),
    );
    window.analytics.track('Shared Spark UnArchived', {
      'Shared url Status': 'Active',
      'Spark Id': params.payload,
    });
    showAlert(
      '.spark-container',
      'spark_unarchive_message',
      'success',
      '',
      3000,
    );
    yield put({ type: actions.GET_SHARED_SPARKS });
  } catch (error) {
    handleException(error);
  }
}
export function* deleteSharedSpark(params) {
  try {
    yield call(() =>
      deleteRequest(
        `projects/${getCurrentProjectId()}/nodes/${params.payload}/deletespark`,
      ),
    );
    window.analytics.track('Shared Spark Deleted');
    yield put({ type: actions.GET_SHARED_SPARKS });
    yield put({ type: actions.DELETE_SHARED_SPARK_SUCCESS });
    yield put({
      type: actions.DELETE_SHARED_SPARK_MODAL_VISIBLE,
      payload: false,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_SHARED_SPARK_FAILURE });
    handleException(error);
  }
}
export function* reShareSpark(params) {
  try {
    yield call(() =>
      postRequest('user/sparks/shared', { node_id: params.payload }),
    );
    window.analytics.track('Spark re-share');
  } catch (error) {
    handleException(error);
  }
}

export function* getUrlPreview(params) {
  try {
    const response = yield call(() =>
      getRequest(`url-preview/?url=${params.payload}`),
    );
    yield put({
      type: actions.GET_URL_PREVIEW_SUCCESS,
      payload: { data: response.data, url: params.payload },
    });
  } catch (error) {
    handleException(error);
    yield put({ type: actions.GET_URL_PREVIEW_FAILURE });
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SHARED_SPARKS, getSharedSparks),
    takeEvery(actions.PAUSE_SHARED_SPARKS, pauseSharedSparks),
    takeEvery(actions.RESUME_SHARED_SPARKS, resumeSharedSparks),
    takeEvery(actions.ARCHIVE_SHARED_SPARKS, archiveSharedSparks),
    takeEvery(actions.UNARCHIVE_SHARED_SPARKS, unarchiveSharedSparks),
    takeEvery(actions.DELETE_SHARED_SPARK, deleteSharedSpark),
    takeEvery(actions.RE_SHARE_SPARK, reShareSpark),
    takeEvery(actions.GET_ITEM_LIST, getItemlist),
    takeEvery(actions.GET_URL_PREVIEW, getUrlPreview),
  ]);
}
