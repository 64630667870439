import React from 'react';
import { Avatar, Button, Layout } from 'antd';
import logo from 'images/svgs/logoSparkbox.png';
import { LogoutOutlined } from '@ant-design/icons';
import { store } from 'redux/store';
import authActions from 'redux/auth/actions';
import settingsAction from 'redux/settings/actions';
import VerificationModal from './verificationModal';
import {
  getCurrentProjectName,
  grouping,
  updateBannerPosition,
  updateWidth,
  Translate,
  getCurrentProjectId,
} from 'utility/helpers';
import { cloneDeep, isEqual } from 'lodash';
import { NavLink } from 'react-router-dom';
import SparksHeader from 'components/shared/sparksHeader';
import userImage from 'images/svgs/userImage.png';
import actions from 'redux/capture/actions';
import settingsIcon from 'images/svgs/settings.svg';
import capturesIcon from 'images/svgs/captures.svg';
import activitiesIcon from 'images/svgs/activities.svg';
import dragIcon from 'images/svgs/drag-black.svg';
import sparksIcon from 'images/svgs/spark-new.svg';
import searchIcon from 'images/svgs/search.svg';
import homeIcon from 'images/svgs/homeIconWithBackground.svg';

const { Header } = Layout;

class Headers extends React.Component {
  clicked = (e, value) => {
    if (!this.props.deactivated || value === 'settings') {
      e.persist();
      let currentTab = cloneDeep(this.props.currentTab),
        defaultWidth,
        headerWidth,
        singleTab = ['shared', 'settings', 'search'];
      if (currentTab.includes(value)) {
        if (currentTab.length > 1) {
          currentTab = currentTab.filter((data) => data !== value);
        }
      } else {
        if (singleTab.includes(value)) {
          currentTab = [value];
        } else if (singleTab.includes(currentTab[0])) {
          currentTab = [value];
        } else {
          currentTab.push(value);
        }
      }
      localStorage.setItem('activeTab', currentTab);
      defaultWidth = updateWidth(currentTab).defaultWidth;
      headerWidth = updateWidth(currentTab).headerWidth;
      if (!isEqual(currentTab, this.props.currentTab)) {
        updateBannerPosition(defaultWidth);
        store.dispatch({
          type: settingsAction.SET_ACTIVE_TAB_WIDTH,
          payload: { currentTab, contentWidth: defaultWidth, headerWidth },
        });
      }
    } else {
      e.preventDefault();
    }
  };

  tabWidth = (data) => {
    const { headerWidth } = this.props;
    return headerWidth[data];
  };
  logoutUser = () => {
    store.dispatch({ type: authActions.LOGOUT_USER });
  };
  isActiveTab = (data) => this.tabWidth(data) !== '0';

  componentDidMount() {
    store.dispatch({
      type: actions.GET_CAPTURE_LISTS,
    });
  }
  render() {
    const {
        loading,
        verifyingOtp,
        visible,
        deactivated,
        sortFoldersBy,
        expandedFolders,
        foldersList,
        sortSharedSparksBy,
        expandedSharedSparks,
        sharedSparksList,
        activityLists,
        captureLists,
      } = this.props,
      getDetails = getCurrentProjectName(activityLists),
      name = getDetails && getDetails.name,
      avatar = getDetails && getDetails.avatar;

    return (
      <>
        <Header>
          <img src={logo} alt={'logo'} height={'50px'} />
          <div>
            <Button
              type={'danger'}
              onClick={this.logoutUser}
              icon={<LogoutOutlined />}
              key={'logout'}
              loading={loading}
            >
              <span>
                <Translate>sign_out</Translate>
              </span>
            </Button>
          </div>
        </Header>
        <div className="header-tab">
          <NavLink
            style={{ width: this.tabWidth('capture') }}
            className={`${
              this.isActiveTab('capture') ? 'active-capture' : ''
            } header-tab-btn capture-ui`}
            to="/app"
          >
            <div
              key="1"
              className={`${this.isActiveTab('capture') ? 'active-btn' : ''} ${
                deactivated ? 'disabled' : ''
              }`}
              onClick={(e) => this.clicked(e, 'capture')}
            >
              <img src={capturesIcon} alt={'captures'} />
              {captureLists.length > 0 && (
                <div className={'count-badge'}>
                  {captureLists.length > 99 ? '99+' : captureLists.length}
                </div>
              )}
              {this.isActiveTab('capture') && (
                <span
                  style={{
                    marginLeft:
                      captureLists.length === 0
                        ? 6
                        : captureLists.length < 10
                          ? 12
                          : captureLists.length < 100
                            ? 20
                            : 28,
                  }}
                >
                  <Translate>captures</Translate>
                </span>
              )}
            </div>
          </NavLink>
          <NavLink
            style={{ width: this.tabWidth('activities') }}
            className="header-tab-btn"
            to="/app"
          >
            <div
              key="2"
              className={`${
                this.isActiveTab('activities') ? 'active-btn' : ''
              } ${deactivated ? 'disabled' : ''}`}
              onClick={(e) => this.clicked(e, 'activities')}
            >
              <img src={activitiesIcon} alt={'activities'} />
              {this.isActiveTab('activities') && (
                <span>
                  <Translate>activities</Translate>
                </span>
              )}
            </div>
          </NavLink>
          <NavLink
            style={{ width: this.tabWidth('sparks') }}
            className="header-tab-btn"
            to="/app"
          >
            <div
              key="3"
              className={`${this.isActiveTab('sparks') ? 'active-btn' : ''} ${
                deactivated ? 'disabled' : ''
              } with-select`}
            >
              <div onClick={(e) => this.clicked(e, 'sparks')}>
                <img src={dragIcon} alt={'drag'} />
                {this.isActiveTab('sparks') && (
                  <span>
                    {' '}
                    <Avatar
                      shape="square"
                      size={'small'}
                      src={
                        avatar
                          ? avatar
                          : activityLists?.active?.data[0].project_id ===
                            getCurrentProjectId()
                            ? homeIcon
                            : userImage
                      }
                      className={`${
                        activityLists?.active?.data[0].project_id ===
                        getCurrentProjectId()
                          ? 'home-icon'
                          : ''
                      }`}
                    />{' '}
                    {name}
                  </span>
                )}
              </div>
              {this.isActiveTab('sparks') && (
                <SparksHeader
                  selectValue={sortFoldersBy}
                  isFromSharedSparks={false}
                  expandedItems={expandedFolders}
                  listOrder={Object.keys(grouping(foldersList, false, false))}
                />
              )}
            </div>
          </NavLink>
          <NavLink
            className="header-tab-btn"
            style={{ width: this.tabWidth('shared') }}
            to="/app/shared"
          >
            <div
              key="4"
              className={`${this.isActiveTab('shared') ? 'active-btn' : ''} ${
                deactivated ? 'disabled' : ''
              } with-select`}
            >
              <div onClick={(e) => this.clicked(e, 'shared')}>
                <img src={sparksIcon} alt={'sparks'} />
                {this.isActiveTab('shared') && (
                  <span>
                    <Translate>shared </Translate>
                  </span>
                )}
              </div>
              {this.isActiveTab('shared') && (
                <SparksHeader
                  selectValue={sortSharedSparksBy}
                  expandedItems={expandedSharedSparks}
                  listOrder={Object.keys(sharedSparksList)}
                  isFromSharedSparks={true}
                />
              )}
            </div>
          </NavLink>
          <NavLink
            className="header-tab-btn"
            style={{ width: this.tabWidth('search') }}
            to="/app/search"
          >
            <div
              key="5"
              className={`${this.isActiveTab('search') ? 'active-btn' : ''} ${
                deactivated ? 'disabled' : ''
              }`}
              onClick={(e) => this.clicked(e, 'search')}
            >
              <img src={searchIcon} alt={'search'} />
              {this.isActiveTab('search') && (
                <span>
                  <Translate>search</Translate>
                </span>
              )}
            </div>
          </NavLink>
          <NavLink
            className="header-tab-btn"
            style={{ width: this.tabWidth('settings') }}
            to="/app/settings/account-settings"
          >
            <div
              key="6"
              className={this.isActiveTab('settings') ? 'active-btn' : ''}
              onClick={(e) => this.clicked(e, 'settings')}
            >
              <img src={settingsIcon} alt={'settings'} />
              {this.isActiveTab('settings') && (
                <span>
                  <Translate>settings</Translate>
                </span>
              )}
            </div>
          </NavLink>
        </div>
        <VerificationModal
          visible={visible}
          handleVisible={this.handleVisible}
          verifyingOtp={verifyingOtp}
        />
      </>
    );
  }
}

export default Headers;
