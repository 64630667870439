import React, { useEffect, useState } from 'react';
import AppRouter from 'routes/appRouter';
import { Layout } from 'antd';
import Headers from 'components/shared/headers';
import { useSelector } from 'react-redux';
import actions from 'redux/auth/actions';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  onBeforeDragStart,
  onDragEnd,
  onDragStart,
  onDragUpdate,
} from 'utility/helpers';
import { store } from 'redux/store';
import { useHistory } from 'react-router-dom';
import Folder from 'components/shared/folder';
import OnBoardingModal from 'components/onBoarding/onBoarding';
import { isEqual } from 'lodash';

const { Content } = Layout;

function App(props) {
  const {
      loading,
      isVerified,
      verifyingOtp,
      verifyModalVisible,
      remainingVerificationMinutes,
      deactivated,
      isOnboardComplete,
    } = useSelector((state) => state.Auth),
    { currentTab, headerWidth } = useSelector((state) => state.Settings),
    { activityLists } = useSelector((state) => state.Activities),
    {
      sortFoldersBy,
      expandedFolders,
      foldersList,
      folderItemsList,
    } = useSelector((state) => state.Folder),
    {
      sortSharedSparksBy,
      expandedSharedSparks,
      sharedSparksList,
    } = useSelector((state) => state.SharedSparks),
    { captureLists } = useSelector((state) => state.Capture),
    history = useHistory(),
    {
      match: { url },
    } = props,
    [isFolderItemDragging, changeFolderItemDragging] = useState(false);
  useEffect(() => {
    store.dispatch({
      type: actions.SET_HISTORY,
      history,
    });
    if (isOnboardComplete === false) {
      store.dispatch({
        type: actions.SET_ON_BOARDING_MODAL_VISIBLE,
        visible: true,
      });
    }
  }, [isOnboardComplete, history]);
  return (
    <div className={'container'}>
      <Layout>
        <Headers
          loading={loading}
          isVerified={isVerified}
          verifyingOtp={verifyingOtp}
          visible={verifyModalVisible}
          remainingTime={remainingVerificationMinutes}
          currentTab={currentTab}
          headerWidth={headerWidth}
          deactivated={deactivated}
          sortFoldersBy={sortFoldersBy}
          expandedFolders={expandedFolders}
          foldersList={foldersList}
          sortSharedSparksBy={sortSharedSparksBy}
          expandedSharedSparks={expandedSharedSparks}
          sharedSparksList={sharedSparksList}
          activityLists={activityLists}
          captureLists={captureLists}
        />
        <Content className={'app-content'}>
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(
                result,
                changeFolderItemDragging,
                folderItemsList,
                foldersList,
              )
            }
            onDragUpdate={(result) => onDragUpdate(result, foldersList)}
            onDragStart={(result) =>
              onDragStart(result, changeFolderItemDragging, folderItemsList)
            }
            onBeforeDragStart={onBeforeDragStart}
          >
            <AppRouter url={url} />
            <Folder
              title={'New folder'}
              newFolder={true}
              isFolderItemDragging={
                isFolderItemDragging && !isEqual(['capture'], currentTab)
              }
              isVerified={isVerified}
            >
              <Droppable droppableId="new-folder" type="child">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={`drop-zone ${
                      snapshot.isDraggingOver ? 'dragging-over' : ''
                    }`}
                    {...provided.droppableProps}
                  >
                    {!snapshot.isDraggingOver &&
                      'Drag here to move the item into new Folder'}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Folder>
          </DragDropContext>
        </Content>
      </Layout>
      <OnBoardingModal />
    </div>
  );
}

export default App;
