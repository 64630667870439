const actions = {
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',
  REGISTER_USER: 'REGISTER_USER',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  LOGOUT_USER: 'LOGOUT_USER',
  LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAILURE: 'LOGOUT_USER_FAILURE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
  UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
  UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
  UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',
  MODAL_VISIBLE: 'MODAL_VISIBLE',
  VERIFY_OTP: 'VERIFY_OTP',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',
  RESEND_OTP: 'RESEND_OTP',
  RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',
  FETCH_DEACTIVATE_REASON_LIST: 'FETCH_DEACTIVATE_REASON_LIST',
  DEACTIVATE_ACCOUNT: 'DEACTIVATE_ACCOUNT',
  DEACTIVATE_ACCOUNT_SUCCESS: 'DEACTIVATE_ACCOUNT_SUCCESS',
  DEACTIVATE_ACCOUNT_FAILURE: 'DEACTIVATE_ACCOUNT_FAILURE',
  FETCH_DEACTIVATE_REASON_LIST_SUCCESS: 'FETCH_DEACTIVATE_REASON_LIST_SUCCESS',
  CHANGE_PASSWORD_VISIBLE: 'CHANGE_PASSWORD_VISIBLE',
  ACCOUNT_DEACTIVATION_VISIBLE: 'ACCOUNT_DEACTIVATION_VISIBLE',
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_FAILURE: 'ACTIVATE_ACCOUNT_FAILURE',
  DEACTIVATE_CONFIRMATION_VISIBLE: 'DEACTIVATE_CONFIRMATION_VISIBLE',
  REACTIVATE_CONFIRMATION_VISIBLE: 'REACTIVATE_CONFIRMATION_VISIBLE',
  VERIFY_MODAL_VISIBLE: 'VERIFY_MODAL_VISIBLE',
  CHANGE_USER_AVATAR: 'CHANGE_USER_AVATAR',
  CHANGE_USER_AVATAR_SUCCESS: 'CHANGE_USER_AVATAR_SUCCESS',
  CHANGE_USER_AVATAR_FAILURE: 'CHANGE_USER_AVATAR_FAILURE',
  SET_HISTORY: 'SET_HISTORY',
  CHANGE_USER_AVATAR_LOADER: 'CHANGE_USER_AVATAR_LOADER',
  SET_ON_BOARDING_MODAL_VISIBLE: 'SET_ON_BOARDING_MODAL_VISIBLE',
  ON_BOARD_COMPLETE_PROCESS: 'ON_BOARD_COMPLETE_PROCESS',
  SET_LOCATION_ACCESS_STATUS: 'SET_LOCATION_ACCESS_STATUS',
  SET_LOCATION_ACCESS_STATUS_SUCCESS: 'SET_LOCATION_ACCESS_STATUS_SUCCESS',
  SET_LOCATION_ACCESS_STATUS_FAILURE: 'SET_LOCATION_ACCESS_STATUS_FAILURE',
  GET_PASSWORDS_LIST: 'GET_PASSWORDS_LIST',
  GET_PASSWORDS_LIST_SUCCESS: 'GET_PASSWORDS_LIST_SUCCESS',
};

export default actions;
