import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getRequest, deleteRequest } from 'utility/axiosClient';
import { handleException } from 'utility/helpers';
import { store } from 'redux/store';

export function* getCaptureLists(params) {
  try {
    if (params.loadMore) {
      const { page } = store.getState().Capture;
      const response = yield call(() => getRequest(`capture?page=${page}`));
      yield put({
        type: actions.UPDATE_CAPTURE_LISTS_SUCCESS,
        payload: response.data,
      });
    } else {
      const response = yield call(() => getRequest('capture'));
      yield put({
        type: actions.GET_CAPTURE_LISTS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({ type: actions.GET_CAPTURE_LISTS_FAILURE });
    handleException(error);
  }
}

export function* deleteCapture(params) {
  try {
    yield call(() => deleteRequest('capture/delete', params.payload));
    yield put({
      type: actions.DELETE_CAPTURE_SUCCESS,
      payload: params.payload.capture_ids,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CAPTURE_FAILURE });
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CAPTURE_LISTS, getCaptureLists),
    takeEvery(actions.DELETE_CAPTURE, deleteCapture),
  ]);
}
