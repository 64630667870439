import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from 'utility/axiosClient';
import {
  getFirstProjectId,
  getProjectDetails,
  getUserId,
  handleException,
  showAlert,
} from 'utility/helpers';
import { store } from 'redux/store';
import folderActions from 'redux/folders/actions';
import captureActions from 'redux/capture/actions';
import activitiesAction from 'redux/activities/actions';

export function* getActivitiesLists() {
  try {
    const response = yield call(() => getRequest('projects'));
    yield put({
      type: actions.GET_ACTIVITIES_LISTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ACTIVITIES_LISTS_FAILURE });
    handleException(error);
  }
}

export function* createActivity(params) {
  try {
    const { details, payload, isActivityAvatarUpdated, callback } = params,
      response = yield call(() => postRequest('projects', payload));
    yield put({
      type: actions.CREATE_ACTIVITY_SUCCESS,
      payload: response.data,
    });
    window.analytics.track('Activity Added', {
      'Activity Id': response.data.project_id,
      'Avatar Added': !!response.data.avatar ? 'Yes' : 'No',
      'Description Added': !!response.data.description ? 'Yes' : 'No',
    });
    if (isActivityAvatarUpdated) {
      callback(response.data.project_id);
    }
    if (details.source && details.source.droppableId.includes('captures')) {
      yield put({
        type: captureActions.MOVE_CAPTURE_TO_NODE,
        id: details.draggableId,
      });
      yield put({
        type: folderActions.CREATE_NODE_FROM_CAPTURE,
        payload: {
          details: {
            capture_ids: [details.draggableId],
          },
        },
        projectId: response.data.project_id,
      });
    } else if (
      details.source &&
      details.source.droppableId.includes('folder-items')
    ) {
      yield put({
        type: folderActions.CONVERTING_ITEM_TO_NODE,
        payload: {
          nodeId: details.source.droppableId.replace('-folder-items', ''),
          details: {
            project_id: response.data.project_id,
            items: [details.draggableId],
          },
        },
      });
    } else if (details.destination && details.destination.droppableId === 'activity-drop-zone') {
      let payload = {
        movedTo: {
          project_id: response.data.project_id,
        },
        movedId: details.draggableId,
        destinationName: response.data.name,
      };
      yield put({
        type: folderActions.MOVE_NODE_TO_ACTIVITY,
        payload,
      });
    } else {
      yield put({
        type: folderActions.CHANGE_SELECTED_ACTIVITY,
        selectedActivity: response.data,
      });
      localStorage.setItem(`spark-projectId-${getUserId()}`, response.data.project_id);
      if (!isActivityAvatarUpdated) {
        yield put({
          type: activitiesAction.CLOSE_ACTIVITY_MODAL,
        });
      }
    }
  } catch (error) {
    yield put({ type: actions.CREATE_ACTIVITY_FAILURE });
    handleException(error);
  }
}

export function* viewActivity(params) {
  try {
    const response = yield call(() =>
      getRequest(`projects/${params.projectId}`),
    );
    yield put({
      type: actions.VIEW_ACTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.VIEW_ACTIVITY_FAILURE });
    handleException(error);
  }
}

export function* updateActivity(params) {
  try {
    const response = yield call(() =>
      putRequest(`projects/${params.projectId}`, params.payload),
    );
    yield put({
      type: actions.UPDATE_ACTIVITY_SUCCESS,
      payload: response.data,
    });
    window.analytics.track('Activity Edited', {
      'Activity Id': response.data.project_id,
      'Avatar Added': !!response.data.avatar ? 'Yes' : 'No',
      'Description Added': !!response.data.description ? 'Yes' : 'No',
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_ACTIVITY_FAILURE });
    handleException(error);
  }
}

export function* updateActivityAvatar(params) {
  try {
    const { projectId, payload, callback, data } = params;
    const response = yield call(() =>
      postRequest(`projects/${projectId}/avatar`, payload),
    );
    callback && callback(data);
    yield put({
      type: actions.UPDATE_ACTIVITY_AVATAR_SUCCESS,
      avatarURL: response.data.avatar,
      projectId: params.projectId,
    });
    if (!callback) {
      yield put({
        type: activitiesAction.CLOSE_ACTIVITY_MODAL,
      });
    }
  } catch (error) {
    yield put({ type: actions.UPDATE_ACTIVITY_AVATAR_FAILURE });
    handleException(error);
  }
}

export function* getActiveActivities() {
  try {
    const response = yield call(() => getRequest('projects/active'));
    yield put({
      type: actions.GET_ACTIVE_ACTIVITIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ACTIVE_ACTIVITIES_FAILURE });
    handleException(error);
  }
}

export function* getArchivedActivities() {
  try {
    const response = yield call(() => getRequest('projects/archived'));
    yield put({
      type: actions.GET_ARCHIVED_ACTIVITIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ARCHIVED_ACTIVITIES_FAILURE });
    handleException(error);
  }
}

export function* archiveActivities(params) {
  try {
    yield call(() => postRequest(`projects/${params.projectId}/archive`));
    window.analytics.track('Activity Archived', {
      'Activity Id': params.projectId,
    });
    showAlert(
      '.activities',
      'activity_archived_successfully',
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({ type: actions.ARCHIVE_ACTIVITIES_FAILURE });
    handleException(error);
  }
}

export function* unarchiveActivities(params) {
  try {
    yield call(() => postRequest(`projects/${params.projectId}/unarchive`));
    window.analytics.track('Activity Un-Archived', {
      'Activity Id': params.projectId,
    });
    showAlert(
      '.activities',
      'activity_unarchived_successfully',
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({ type: actions.UNARCHIVE_ACTIVITIES_FAILURE });
    handleException(error);
  }
}

export function* reorderActiveActivities(params) {
  try {
    yield call(() =>
      postRequest(
        `projects/${params.payload.projectId}/reorder/active`,
        params.payload.details,
      ),
    );
    yield put({
      type: actions.REORDER_ACTIVE_ACTIVITIES_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    yield put({ type: actions.REORDER_ACTIVE_ACTIVITIES_FAILURE });
    handleException(error);
  }
}

export function* reorderArchivedActivities(params) {
  try {
    yield call(() =>
      postRequest(
        `projects/${params.payload.projectId}/reorder/archived`,
        params.payload.details,
      ),
    );
    yield put({
      type: actions.REORDER_ARCHIVED_ACTIVITIES_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    yield put({ type: actions.REORDER_ARCHIVED_ACTIVITIES_FAILURE });
    handleException(error);
  }
}

export function* updateNode(params) {
  try {
    const response = yield call(() =>
      putRequest(`projects/${params.projectId}/nodes/capture`, params.payload),
    );
    yield put({
      type: actions.CREATE_NODE_FROM_CAPTURE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_NODE_FROM_CAPTURE_FAILURE });
    handleException(error);
  }
}

export function* updateNodeAvatar(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${params.projectId}/nodes/${params.nodeId}/avatar`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UPDATE_NODE_AVATAR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_NODE_AVATAR_FAILURE });
    handleException(error);
  }
}

export function* removeNodeAvatar(params) {
  try {
    const response = yield call(() =>
      deleteRequest(
        `projects/${params.projectId}/nodes/${params.nodeId}/avatar`,
      ),
    );
    yield put({
      type: actions.REMOVE_NODE_AVATAR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.REMOVE_NODE_AVATAR_FAILURE });
    handleException(error);
  }
}

export function* reorderNode(params) {
  try {
    const response = yield call(() =>
      putRequest(`projects/${params.projectId}/nodes/${params.nodeId}/reorder`),
    );
    yield put({
      type: actions.REORDER_NODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.REORDER_NODE_FAILURE });
    handleException(error);
  }
}

export function* createSpark(params) {
  try {
    const response = yield call(() =>
      postRequest(`projects/${params.projectId}/sparks`, params.payload),
    );
    yield put({
      type: actions.CREATE_SPARK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SPARK_FAILURE });
    handleException(error);
  }
}

export function* sparkANode(params) {
  try {
    const response = yield call(() =>
      postRequest(
        `projects/${params.projectId}/nodes/${params.nodeId}/unspark`,
        params.payload,
      ),
    );
    yield put({
      type: actions.UNSPARK_A_NODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UNSPARK_A_NODE_FAILURE });
    handleException(error);
  }
}

export function* unsparkANode(params) {
  try {
    const response = yield call(() =>
      postRequest(
        `projects/${params.projectId}/nodes/${params.nodeId}/unspark`,
        params.payload,
      ),
    );
    yield put({
      type: actions.SPARK_A_NODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.SPARK_A_NODE_FAILURE });
    handleException(error);
  }
}

export function* sparkPresentation(params) {
  try {
    const response = yield call(() =>
      postRequest(`sparks/${params.nodeId}/view`),
    );
    yield put({
      type: actions.SPARK_A_NODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.SPARK_A_NODE_FAILURE });
    handleException(error);
  }
}

export function* duplicateSpark(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `projects/${params.projectId}/nodes/${params.nodeId}/duplicatespark`,
      ),
    );
    yield put({
      type: actions.DUPLICATE_SPARK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DUPLICATE_SPARK_FAILURE });
    handleException(error);
  }
}

export function* deleteActivity(params) {
  try {
    yield call(() => deleteRequest(`projects/${params.projectId}`));
    yield put({
      type: actions.DELETE_ACTIVITY_SUCCESS,
    });
    if (
      localStorage.getItem(`spark-projectId-${getUserId()}`) ===
      params.projectId
    ) {
      let changedProjectId = getFirstProjectId(),
        projectDetails =
          getProjectDetails() &&
          getProjectDetails().spark &&
          getProjectDetails().spark[changedProjectId];
      store.dispatch({
        type: folderActions.GET_NODES_LIST,
        projectId: changedProjectId,
        sortFoldersBy: projectDetails ? projectDetails.sortBy : 'free_space',
      });
      if (projectDetails && projectDetails.expandedItems) {
        store.dispatch({
          type: folderActions.SET_EXPAND_FOLDER_DETAILS,
          payload: projectDetails.expandedItems,
        });
      }
      store.dispatch({
        type: folderActions.CHANGE_SELECTED_ACTIVITY,
        selectedActivity: changedProjectId,
      });
      localStorage.setItem(`spark-projectId-${getUserId()}`, changedProjectId);
    }
    window.analytics.track('Activity Deleted', {
      'Activity Id': params.projectId,
    });
    showAlert(
      '.activities',
      'activity_deleted_successfully',
      'success',
      '',
      3000,
    );
  } catch (error) {
    yield put({ type: actions.DELETE_ACTIVITY_FAILURE });
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ACTIVITIES_LISTS, getActivitiesLists),
    takeEvery(actions.CREATE_ACTIVITY, createActivity),
    takeEvery(actions.VIEW_ACTIVITY, viewActivity),
    takeEvery(actions.UPDATE_ACTIVITY, updateActivity),
    takeEvery(actions.UPDATE_ACTIVITY_AVATAR, updateActivityAvatar),
    takeEvery(actions.GET_ACTIVE_ACTIVITIES, getActiveActivities),
    takeEvery(actions.GET_ARCHIVED_ACTIVITIES, getArchivedActivities),
    takeEvery(actions.ARCHIVE_ACTIVITIES, archiveActivities),
    takeEvery(actions.UNARCHIVE_ACTIVITIES, unarchiveActivities),
    takeEvery(actions.REORDER_ACTIVE_ACTIVITIES, reorderActiveActivities),
    takeEvery(actions.REORDER_ARCHIVED_ACTIVITIES, reorderArchivedActivities),
    takeEvery(actions.UPDATE_NODE, updateNode),
    takeEvery(actions.UPDATE_NODE_AVATAR, updateNodeAvatar),
    takeEvery(actions.REMOVE_NODE_AVATAR, removeNodeAvatar),
    takeEvery(actions.REORDER_NODE, reorderNode),
    takeEvery(actions.CREATE_SPARK, createSpark),
    takeEvery(actions.SPARK_A_NODE, sparkANode),
    takeEvery(actions.UNSPARK_A_NODE, unsparkANode),
    takeEvery(actions.SPARK_PRESENTATION, sparkPresentation),
    takeEvery(actions.DELETE_ACTIVITY, deleteActivity),
  ]);
}
