import actions from './actions';
import { reOrder } from 'utility/helpers';
import { cloneDeep } from 'lodash';

const initState = {
  activityLists: {},
  oldActivityLists: {},
  loaders: {
    activitiesListLoader: false,
    deleteActivityLoader: false,
  },
  deleteActivityModalVisible: false,
  activityDetails: {},
  newActivityModal: false,
  newActivityModalLoader: false,
  updateActivityAvatarLoader: false,
  activityAvatarData: '',
  isActivityAvatarUpdated: false,
  isFromDrag: false,
};

export default function activitiesReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ACTIVITIES_LISTS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          activitiesListLoader: true,
        },
      };
    }
    case actions.GET_ACTIVITIES_LISTS_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          activitiesListLoader: false,
        },
        activityLists: {
          active: action.payload.data.active,
          archived: action.payload.data.archived,
        },
      };
    }
    case actions.GET_ACTIVITIES_LISTS_FAILURE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          activitiesListLoader: false,
        },
      };
    }
    case actions.REORDER_ACTIVE_ACTIVITIES: {
      let { sInd, dInd } = action.payload.reOrderDetails;
      return {
        ...state,
        oldActivityLists: state.activityLists,
        activityLists: {
          ...state.activityLists,
          active: {
            ...state.activityLists.active,
            data: reOrder([...state.activityLists.active.data], sInd, dInd),
          },
        },
      };
    }
    case actions.REORDER_ACTIVE_ACTIVITIES_FAILURE: {
      return {
        ...state,
        activityLists: state.oldActivityLists,
      };
    }
    case actions.REORDER_ARCHIVED_ACTIVITIES: {
      let { sInd, dInd } = action.payload.reOrderDetails;
      return {
        ...state,
        oldActivityLists: state.activityLists,
        activityLists: {
          ...state.activityLists,
          archived: {
            ...state.activityLists.archived,
            data: reOrder([...state.activityLists.archived.data], sInd, dInd),
          },
        },
      };
    }
    case actions.REORDER_ARCHIVED_ACTIVITIES_FAILURE: {
      return {
        ...state,
        activityLists: state.oldActivityLists,
      };
    }
    case actions.ARCHIVE_ACTIVITIES: {
      let archiveActivities = { ...state.activityLists },
        archivedActivity = {};
      archivedActivity = archiveActivities.active.data.find(
        (activeData) => activeData.project_id === action.projectId,
      );
      archivedActivity.archived = true;
      archiveActivities.archived.data.push(archivedActivity);
      archiveActivities.active.data = archiveActivities.active.data.filter(
        (activeData) => activeData.project_id !== action.projectId,
      );
      return {
        ...state,
        oldActivityLists: state.activityLists,
        activityLists: archiveActivities,
      };
    }
    case actions.ARCHIVE_ACTIVITIES_FAILURE: {
      return {
        ...state,
        activityLists: state.oldActivityLists,
      };
    }
    case actions.UNARCHIVE_ACTIVITIES: {
      let unArchiveActivities = { ...state.activityLists },
        unarchivedActivity = {};
      unarchivedActivity = unArchiveActivities.archived.data.find(
        (archivedData) => archivedData.project_id === action.projectId,
      );
      unarchivedActivity.archived = false;
      unArchiveActivities.active.data.push(unarchivedActivity);
      unArchiveActivities.archived.data = unArchiveActivities.archived.data.filter(
        (activeData) => activeData.project_id !== action.projectId,
      );
      return {
        ...state,
        oldActivityLists: state.activityLists,
        activityLists: unArchiveActivities,
      };
    }
    // remove and merge the failure actions if loader is not needed
    case actions.UNARCHIVE_ACTIVITIES_FAILURE: {
      return {
        ...state,
        activityLists: state.oldActivityLists,
      };
    }
    case actions.DELETE_ACTIVITY: {
      let deletedActivity = cloneDeep(state.activityLists);
      if (action.isActive) {
        deletedActivity.active.data = deletedActivity.active.data.filter(
          (activeData) => activeData.project_id !== action.projectId,
        );
      } else {
        deletedActivity.archived.data = deletedActivity.archived.data.filter(
          (archivedData) => archivedData.project_id !== action.projectId,
        );
      }
      return {
        ...state,
        oldActivityLists: state.activityLists,
        activityLists: deletedActivity,
        loaders: {
          ...state.loaders,
          deleteActivityLoader: true,
        },
      };
    }
    case actions.DELETE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          deleteActivityLoader: false,
        },
        deleteActivityModalVisible: false,
      };
    }
    case actions.DELETE_ACTIVITY_FAILURE: {
      return {
        ...state,
        activityLists: state.oldActivityLists,
        loaders: {
          ...state.loaders,
          deleteActivityLoader: false,
        },
      };
    }
    case actions.DELETE_ACTIVITY_MODAL_VISIBILITY: {
      return {
        ...state,
        deleteActivityModalVisible: action.payload,
      };
    }
    case actions.ADD_NEW_ACTIVITY_MODAL: {
      const { payload, visible, isFromDrag } = action;
      return {
        ...state,
        activityDetails: payload,
        newActivityModal: visible,
        isFromDrag: isFromDrag,
      };
    }
    case actions.CREATE_ACTIVITY: {
      return {
        ...state,
        newActivityModalLoader: true,
      };
    }
    case actions.CREATE_ACTIVITY_SUCCESS: {
      let newAddedActivity = cloneDeep(state.activityLists);
      newAddedActivity.active.data.push({ ...action.payload });
      return {
        ...state,
        activityLists: newAddedActivity,
      };
    }
    case actions.CLOSE_ACTIVITY_MODAL: {
      return {
        ...state,
        newActivityModalLoader: false,
        newActivityModal: false,
      };
    }
    case actions.UPDATE_ACTIVITY: {
      return {
        ...state,
        newActivityModalLoader: true,
      };
    }
    case actions.UPDATE_ACTIVITY_SUCCESS: {
      let updatedActivityList = state.activityLists;
      updatedActivityList.active.data.find(
        (data) => data.project_id === action.payload.project_id,
      ).name = action.payload.name;
      updatedActivityList.active.data.find(
        (data) => data.project_id === action.payload.project_id,
      ).description = action.payload.description;
      return {
        ...state,
        activityLists: updatedActivityList,
        newActivityModalLoader: false,
        newActivityModal: false,
      };
    }
    case actions.UPDATE_ACTIVITY_AVATAR_FAILURE: {
      return {
        ...state,
        newActivityModalLoader: false,
      };
    }
    case actions.UPDATE_ACTIVITY_AVATAR_SUCCESS: {
      let updatedAvatarList = state.activityLists;
      updatedAvatarList.active.data.find(
        (data) => data.project_id === action.projectId,
      ).avatar = action.avatarURL;
      return {
        ...state,
        activityLists: updatedAvatarList,
      };
    }
    case actions.UPDATE_ACTIVITY_AVATAR_LOADER: {
      return {
        ...state,
        updateActivityAvatarLoader: action.payload,
      };
    }
    case actions.UPDATE_ACTIVITY_AVATAR_DATA: {
      return {
        ...state,
        activityAvatarData: action.payload,
        isActivityAvatarUpdated: true,
        activityDetails: { ...state.activityDetails, avatar: action.avatar },
      };
    }
    case actions.UPDATE_ACTIVITY_AVATAR: {
      return {
        ...state,
        newActivityModalLoader: true,
      };
    }
    default:
      return state;
  }
}
