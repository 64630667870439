import actions from './actions';
import { cloneDeep } from 'lodash';
import { reOrder } from 'utility/helpers';

const initState = {
  foldersList: [],
  oldFoldersList: [],
  selectedActivity: {},
  folderItemsList: {},
  oldFolderItemsList: {},
  propertyModalData: {},
  propertyModalVisibility: false,
  propertyModalLoader: false,
  previewModalVisibility: false,
  previewNodeId: '',
  sortFoldersBy: 'free_space',
  expandedFolders: [],
  itemPropertiesData: {},
  itemPropertiesLoader: false,
  itemPropertiesVisibility: false,
  editModalVisibility: false,
  editItemData: '',
  updateItemLoader: false,
  deleteNodeModalVisibility: false,
  deleteNodeId: '',
  deleteNodeType: '',
  deleteNodeLoader: false,
  deleteItemModalVisibility: false,
  deleteItemData: '',
  deleteItemLoader: false,
  shareModalVisibility: false,
  shareSparkUrl: '',
  shareSparkLoader: false,
  loader: {
    foldersLoader: false,
  },
  gotoSparkInfoVisible: false,
  captionModalData: '',
  captionModalVisibility: false,
  captionModalLoader: false,
  captionModalRemoveLoader: false,
  renderVideoComponent: false,
  updateNodeAvatarLoader: false,
  isAvatarChanged: false,
  avatarData: '',
  selectedNodeId: '',
  hideTargetZoneInNode: '',
  isComponentDragging: false,
  originalUrl: '',
  folderOnTop: '',
  disableDragImage: '',
  shouldActivityReorder: true,
  folderDragDetails: {},
  isCombineEnabled: false,
};

export default function foldersReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_NODES_LIST: {
      return {
        ...state,
        loader: {
          ...state.loader,
          folderLoader: true,
        },
      };
    }
    case actions.GET_NODES_LIST_SUCCESS: {
      return {
        ...state,
        foldersList: action.payload,
        sortFoldersBy: action.sortFoldersBy,
        loader: {
          ...state.loader,
          folderLoader: false,
        },
      };
    }
    case actions.GET_NODES_LIST_FAILURE: {
      return {
        ...state,
        loader: {
          ...state.loader,
          folderLoader: false,
        },
      };
    }
    case actions.CHANGE_SELECTED_ACTIVITY: {
      return {
        ...state,
        selectedActivity: action.selectedActivity,
      };
    }
    case actions.UPDATE_FOLDER: {
      const updatedFolderProps = cloneDeep(state.foldersList),
        { index } = action.payload;
      updatedFolderProps[index].properties = {
        ...updatedFolderProps[index].properties,
        ...action.payload.properties,
      };
      return {
        ...state,
        oldFoldersList: state.foldersList,
        foldersList: updatedFolderProps,
      };
    }
    case actions.UPDATE_FOLDER_FAILURE: {
      return {
        ...state,
        foldersList: state.oldFoldersList,
      };
    }
    case actions.GET_FOLDER_ITEMS_LIST_SUCCESS: {
      return {
        ...state,
        folderItemsList: {
          ...state.folderItemsList,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case actions.REORDER_NODE_ITEM: {
      let { sInd, dInd } = action.payload.reOrderDetails;
      return {
        ...state,
        oldFolderItemsList: state.folderItemsList,
        folderItemsList: {
          ...state.folderItemsList,
          [action.payload.folderId]: {
            ...state.folderItemsList[action.payload.folderId],
            data: reOrder(
              [...state.folderItemsList[action.payload.folderId].data],
              sInd,
              dInd,
            ),
          },
        },
      };
    }
    case actions.REORDER_NODE_ITEM_FAILURE: {
      return {
        ...state,
        folderItemsList: state.oldFolderItemsList,
      };
    }
    case actions.MOVE_NODE_ITEM: {
      const { details, nodeId, sInd, dInd } = action.payload,
        { node_id } = details,
        movedFolderItemsList = cloneDeep(state.folderItemsList),
        movedNodeItem = movedFolderItemsList[nodeId].data[sInd];
      movedNodeItem.node_id = node_id;
      movedFolderItemsList[node_id].data.splice(dInd, 0, movedNodeItem); // moving the node item to target node
      movedFolderItemsList[nodeId].data.splice(sInd, 1); // deleting the node item from source node
      return {
        ...state,
        oldFolderItemsList: state.folderItemsList,
        folderItemsList: movedFolderItemsList,
      };
    }
    case actions.MOVE_NODE_ITEM_FAILURE: {
      return {
        ...state,
        folderItemsList: state.oldFolderItemsList,
      };
    }
    case actions.MOVE_NODE_TO_ACTIVITY: {
      return {
        ...state,
        foldersList: state.foldersList.filter(
          (folder) => folder.node_id !== action.payload.movedId,
        ),
      };
    }
    case actions.SHOW_FOLDER_PROPERTIES_MODAL: {
      return {
        ...state,
        propertyModalData: action.payload ? action.payload : {},
        propertyModalVisibility: action.visibility,
      };
    }
    case actions.UPDATE_FOLDER_PROPERTIES: {
      return {
        ...state,
        propertyModalLoader: true,
      };
    }
    case actions.UPDATE_FOLDER_PROPERTIES_SUCCESS: {
      let foldersList = state.foldersList.map((data) =>
        data.node_id === action.payload.node_id ? action.payload : data,
      );
      return {
        ...state,
        foldersList,
        propertyModalLoader: false,
        propertyModalVisibility: action.visibility,
      };
    }
    case actions.UPDATE_FOLDER_PROPERTIES_FAILURE: {
      return {
        ...state,
        propertyModalLoader: false,
        propertyModalVisibility: action.visibility,
      };
    }
    case actions.DUPLICATE_SUCCESS: {
      let foldersList = [...state.foldersList, action.payload].map((data) =>
        data.node_id === action.nodeId
          ? { ...data, version: data.version + 1 }
          : data,
      );
      return {
        ...state,
        foldersList,
      };
    }
    case actions.DELETE_NODE_SUCCESS: {
      return {
        ...state,
        foldersList: state.foldersList.filter(
          (data) => data.node_id !== action.payload,
        ),
        deleteNodeLoader: false,
        deleteNodeModalVisibility: false,
        deleteNodeId: '',
        deleteNodeType: '',
      };
    }
    case actions.DELETE_NODE_FAILURE: {
      return {
        ...state,
        deleteNodeLoader: false,
        deleteNodeModalVisibility: false,
        deleteNodeId: '',
        deleteNodeType: '',
      };
    }
    case actions.SHARE_NODE_SUCCESS: {
      let foldersList = state.foldersList.map((data) => {
        if (data.node_id === action.nodeId) {
          return { ...data, ...action.payload };
        }
        return data;
      });
      return {
        ...state,
        foldersList,
      };
    }
    case actions.UPDATE_PREVIEW_MODAL_VISIBILITY: {
      return {
        ...state,
        previewModalVisibility: action.visibility,
        previewNodeId: action.payload,
      };
    }
    case actions.ADD_ITEM_FROM_CAPTURE: {
      let captureToList = cloneDeep(state.folderItemsList),
        { details, nodeId, captureLists, dInd } = action.payload,
        movedCapture = captureLists.find((data) =>
          details.capture_ids.includes(data.item_id),
        );
      movedCapture = [movedCapture].map((data) => ({ ...data, loading: true }));
      captureToList[nodeId].data.splice(dInd, 0, ...movedCapture);
      return {
        ...state,
        oldFolderItemsList: state.folderItemsList,
        folderItemsList: captureToList,
      };
    }
    case actions.ADD_ITEM_FROM_CAPTURE_FAILURE: {
      return {
        ...state,
        folderItemsList: state.oldFolderItemsList,
      };
    }
    case actions.CONVERTING_ITEM_TO_NODE_SUCCESS: {
      let convertedNode = [...state.foldersList],
        convertedFolderItemsList = cloneDeep(state.folderItemsList),
        { payload, params } = action,
        { nodeId, details } = params;
      convertedNode.push(payload);
      convertedFolderItemsList[payload.node_id] = {
        data: payload.items,
      };
      convertedFolderItemsList[nodeId].data = convertedFolderItemsList[
        nodeId
      ].data.filter((data) => !details.items.includes(data.item_id));
      return {
        ...state,
        foldersList: convertedNode,
        folderItemsList: convertedFolderItemsList,
        oldFolderItemsList: state.folderItemsList,
        oldFoldersList: state.foldersList,
      };
    }
    case actions.CONVERTING_ITEM_TO_NODE_FAILURE: {
      return {
        ...state,
        folderItemsList: state.oldFolderItemsList,
        foldersList: state.oldFoldersList,
      };
    }
    case actions.CREATE_NODE_FROM_CAPTURE_SUCCESS: {
      let movedCaptureFolderList = [...state.foldersList],
        movedCaptureFolderItemsList = cloneDeep(state.folderItemsList),
        { payload } = action;
      movedCaptureFolderList.push(payload);
      movedCaptureFolderItemsList[payload.node_id] = {
        data: payload.items.map((data) => ({
          ...data,
          node_id: payload.node_id,
        })),
      };
      return {
        ...state,
        foldersList: movedCaptureFolderList,
        folderItemsList: movedCaptureFolderItemsList,
        oldFolderItemsList: state.folderItemsList,
        oldFoldersList: state.foldersList,
      };
    }
    case actions.CREATE_NODE_FROM_CAPTURE_FAILURE: {
      return {
        ...state,
        folderItemsList: state.oldFolderItemsList,
        foldersList: state.oldFoldersList,
      };
    }
    case actions.SET_SORT_BY_VALUE_FOLDERS: {
      return {
        ...state,
        sortFoldersBy: action.sortFoldersBy,
      };
    }
    case actions.SET_EXPAND_FOLDER_DETAILS: {
      return {
        ...state,
        expandedFolders: action.payload,
      };
    }
    case actions.UPDATE_NODE_ITEMS_DATA: {
      let folderItemsList = cloneDeep(state.folderItemsList),
        { node_id, items } = action.payload;
      let updatedItem = items.map((data) => ({ ...data, node_id }));
      folderItemsList[node_id].data.splice(
        folderItemsList[node_id].data.findIndex(
          (data) => data.type === 'target_zone',
        ),
        0,
        ...updatedItem,
      );
      return {
        ...state,
        folderItemsList,
      };
    }
    case actions.DELETE_NODE_ITEMS_SUCCESS: {
      let folderItemsList = cloneDeep(state.folderItemsList);
      folderItemsList[action.nodeId].data = folderItemsList[
        action.nodeId
      ].data.filter((data) => data.item_id !== action.itemId);
      return {
        ...state,
        folderItemsList,
        deleteItemLoader: false,
        deleteItemModalVisibility: false,
        deleteItemData: '',
      };
    }
    case actions.DELETE_NODE_ITEMS_FAILURE: {
      return {
        ...state,
        deleteItemLoader: false,
        deleteItemModalVisibility: false,
        deleteItemData: '',
      };
    }
    case actions.SHOW_ITEM_PROPERTIES_MODAL: {
      return {
        ...state,
        itemPropertiesData: action.payload,
        itemPropertiesVisibility: action.visibility,
      };
    }
    case actions.EDIT_MODAL_VISIBLE: {
      return {
        ...state,
        editModalVisibility: action.payload,
        updateItemLoader: action.payload ? state.updateItemLoader : false,
        editItemData: action.editItemData,
      };
    }
    case actions.UPDATE_ITEM_PROPERTIES: {
      return {
        ...state,
        itemPropertiesLoader: true,
      };
    }
    case actions.UPDATE_ITEM_PROPERTIES_SUCCESS: {
      let folderItemsList = cloneDeep(state.folderItemsList),
        { nodeId, itemId, payload } = action.payload;
      folderItemsList[nodeId].data = folderItemsList[nodeId].data.map((data) =>
        data.item_id === itemId
          ? { ...data, ...{ name: payload.name, description: payload.note } }
          : data,
      );
      return {
        ...state,
        itemPropertiesLoader: false,
        itemPropertiesData: '',
        itemPropertiesVisibility: action.visibility,
        folderItemsList: folderItemsList,
        descriptionModalVisibility: false,
      };
    }
    case actions.UPDATE_ITEM_PROPERTIES_FAILURE: {
      return {
        ...state,
        itemPropertiesLoader: false,
        itemPropertiesData: '',
        itemPropertiesVisibility: false,
        descriptionModalVisibility: false,
      };
    }
    case actions.UPDATE_ITEM_SUCCESS: {
      let folderItemsList = cloneDeep(state.folderItemsList),
        { node_id, items } = action.payload;
      folderItemsList[node_id].data = folderItemsList[
        node_id
      ].data.map((data) =>
        data.item_id === items[0].item_id
          ? { ...data, ...items[0], loading: false }
          : data,
      );
      return {
        ...state,
        editModalVisibility: action.visibility,
        updateItemLoader: false,
        folderItemsList,
      };
    }
    case actions.ROTATE_IMAGE_FAILURE: {
      let folderItemsList = cloneDeep(state.folderItemsList),
        { itemId, nodeId } = action;
      folderItemsList[nodeId].data = folderItemsList[nodeId].data.map(
        (data) => {
          if (data.item_id === itemId) {
            return {
              ...data,
              content: { ...data.content, url: state.originalUrl },
              loading: false,
            };
          }
          return data;
        },
      );
      return {
        ...state,
        folderItemsList,
      };
    }
    case actions.ROTATE_IMAGE_SUCCESS: {
      let folderItemsList = cloneDeep(state.folderItemsList),
        { itemId, nodeId } = action;
      folderItemsList[nodeId].data = folderItemsList[nodeId].data.map((data) =>
        data.item_id === itemId ? { ...data, loading: false } : data,
      );
      return {
        ...state,
        folderItemsList,
      };
    }
    case actions.UPDATE_NOTE_ITEM: {
      return {
        ...state,
        updateItemLoader: true,
      };
    }
    case actions.UPDATE_CONTACT_ITEM: {
      return {
        ...state,
        updateItemLoader: true,
      };
    }
    case actions.UPDATE_LOCATION_ITEM: {
      return {
        ...state,
        updateItemLoader: true,
      };
    }
    case actions.UPDATE_LINK_ITEM: {
      return {
        ...state,
        updateItemLoader: true,
      };
    }
    case actions.UPDATE_DELETE_NODE_MODAL_VISIBILITY: {
      return {
        ...state,
        deleteNodeModalVisibility: action.payload,
        deleteNodeId: action.nodeId,
        deleteNodeType: action.deleteNodeType,
      };
    }
    case actions.UPDATE_DELETE_NODE_ITEMS_MODAL_VISIBILITY: {
      return {
        ...state,
        deleteItemModalVisibility: action.visibility,
        deleteItemData: action.payload,
      };
    }
    case actions.DELETE_NODE: {
      return {
        ...state,
        deleteNodeLoader: true,
      };
    }
    case actions.DELETE_NODE_ITEMS: {
      return {
        ...state,
        deleteItemLoader: true,
      };
    }
    case actions.UPDATE_SHARE_MODAL_VISIBILITY: {
      return {
        ...state,
        shareModalVisibility: action.payload,
      };
    }
    case actions.SHARE_SPARK: {
      return {
        ...state,
        shareModalVisibility: true,
        shareSparkLoader: !action.payload,
        shareSparkUrl: action.payload,
      };
    }
    case actions.UPDATE_NODE_AVATAR_DATA: {
      let propertyModalData = cloneDeep(state.propertyModalData);
      propertyModalData.avatar = action.payload.Location;
      return {
        ...state,
        propertyModalData: propertyModalData,
        isAvatarChanged: true,
        avatarData: action.avatarData,
      };
    }
    case actions.CHANGE_NODE_AVATAR_FAILURE: {
      return {
        ...state,
        propertyModalLoader: false,
        propertyModalVisibility: false,
      };
    }
    case actions.CHANGE_NODE_AVATAR_SUCCESS: {
      let foldersList = state.foldersList.map((data) => {
        if (data.node_id === action.nodeId) {
          data.avatar = action.payload.avatar;
        }
        return data;
      });
      return {
        ...state,
        foldersList,
      };
    }
    case actions.SHARE_SPARK_SUCCESS: {
      let foldersList = state.foldersList.map((data) => {
        if (data.node_id === action.nodeId) {
          data.no_of_time_shared = data.no_of_time_shared
            ? parseInt(data.no_of_time_shared) + 1
            : 1;
          data.shared_at = 'now';
        }
        return data;
      });
      return {
        ...state,
        foldersList,
        shareSparkUrl: action.payload.url,
        shareSparkLoader: false,
      };
    }
    case actions.ADD_ITEM_FROM_CAPTURE_SUCCESS: {
      let { nodeId, captureId, payload } = action,
        capturedFolderItemsList = cloneDeep(state.folderItemsList);
      capturedFolderItemsList[nodeId].data = capturedFolderItemsList[
        nodeId
      ].data.map((data) => {
        if (captureId.includes(data.item_id)) {
          return { ...payload.items[0], node_id: nodeId };
        }
        return data;
      });
      return {
        ...state,
        folderItemsList: capturedFolderItemsList,
      };
    }
    case actions.GOTO_SPARK_INFO_MODAL_VISIBLE: {
      return {
        ...state,
        gotoSparkInfoVisible: action.payload,
      };
    }
    case actions.UPDATE_FOLDER_PROPERTIES_WITH_ACTIVITY: {
      return {
        ...state,
        propertyModalLoader: true,
      };
    }
    case actions.UPDATE_PHOTO: {
      return {
        ...state,
        updateItemLoader: true,
      };
    }
    case actions.ROTATE_IMAGE: {
      let captureToList = cloneDeep(state.folderItemsList),
        originalUrl = '',
        { nodeId, itemId, url } = action;
      captureToList[nodeId].data = captureToList[nodeId].data.map((data) => {
        if (data.item_id === itemId) {
          originalUrl = data.content.url;
          return { ...data, loading: true, content: { ...data.content, url } };
        }
        return data;
      });
      return {
        ...state,
        folderItemsList: captureToList,
        originalUrl,
      };
    }
    case actions.UPDATE_VIDEO: {
      return {
        ...state,
        updateItemLoader: true,
      };
    }
    case actions.UPDATE_AUDIO: {
      return {
        ...state,
        updateItemLoader: true,
      };
    }
    case actions.SHOW_CAPTION_MODAL: {
      return {
        ...state,
        captionModalData: action.payload,
        captionModalVisibility: action.visibility,
      };
    }
    case actions.UPDATE_CAPTION: {
      return {
        ...state,
        captionModalLoader: !action.isRemove,
        captionModalRemoveLoader: action.isRemove,
      };
    }
    case actions.UPDATE_CAPTION_FAILURE: {
      return {
        ...state,
        captionModalData: '',
        captionModalLoader: false,
        captionModalVisibility: false,
        captionModalRemoveLoader: false,
      };
    }
    case actions.UPDATE_CAPTION_SUCCESS: {
      let folderItemsList = cloneDeep(state.folderItemsList),
        { nodeId, itemId, content } = action.payload;
      folderItemsList[nodeId].data = folderItemsList[nodeId].data.map((data) =>
        data.item_id === itemId
          ? { ...data, content: { ...data.content, ...content } }
          : data,
      );
      return {
        ...state,
        itemPropertiesLoader: false,
        captionModalData: '',
        captionModalLoader: false,
        captionModalRemoveLoader: false,
        captionModalVisibility: action.visibility,
        folderItemsList: folderItemsList,
      };
    }
    case actions.UPDATE_RENDER_VARIABLE: {
      return {
        ...state,
        renderVideoComponent: action.payload,
      };
    }
    case actions.UPDATE_NODE_AVATAR_LOADER: {
      return {
        ...state,
        updateNodeAvatarLoader: action.payload,
      };
    }
    case actions.SHOW_DESCRIPTION_MODAL: {
      return {
        ...state,
        descriptionModalVisibility: action.visibility,
      };
    }
    case actions.SET_SELECTED_NODE_ID: {
      return {
        ...state,
        selectedNodeId: action.payload,
      };
    }
    case actions.HIDE_TARGET_ZONE_ID: {
      return {
        ...state,
        hideTargetZoneInNode: action.payload,
      };
    }
    case actions.SET_COMPONENT_IS_DRAGGING: {
      return {
        ...state,
        isComponentDragging: action.payload,
      };
    }
    case actions.CREATE_QUICK_SPARK_FOLDER_SUCCESS: {
      return {
        ...state,
        folderItemsList: {
          ...state.folderItemsList,
          [action.payload.node_id]: {
            data: action.payload.items,
          },
        },
        foldersList: [...state.foldersList, action.payload],
        folderOnTop: action.nodeId,
      };
    }
    case actions.REMOVE_FOLDER_ON_TOP: {
      return {
        ...state,
        folderOnTop: action.payload,
      };
    }
    case actions.DISABLE_DRAG_IMAGE: {
      return {
        ...state,
        disableDragImage: action.payload,
      };
    }
    case actions.SET_SHOULD_REORDER: {
      return {
        ...state,
        shouldActivityReorder: action.payload,
      }
    }
    case actions.SET_DESTINATION_ID: {
      return {
        ...state,
        folderDragDetails: {...action.payload},
      }
    }
    case actions.DISABLE_COMBINE_ENABLED: {
      return {
        ...state,
        isCombineEnabled: action.payload,
      }
    }
    default: {
      return state;
    }
  }
}
