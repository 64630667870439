const actions = {
  GET_NODES_LIST: 'GET_NODES_LIST',
  GET_NODES_LIST_SUCCESS: 'GET_NODES_LIST_SUCCESS',
  GET_NODES_LIST_FAILURE: 'GET_NODES_LIST_FAILURE',
  UPDATE_FOLDER: 'UPDATE_FOLDER',
  UPDATE_FOLDER_SUCCESS: 'UPDATE_FOLDER_SUCCESS',
  UPDATE_FOLDER_FAILURE: 'UPDATE_FOLDER_FAILURE',
  CHANGE_SELECTED_ACTIVITY: 'CHANGE_SELECTED_ACTIVITY',
  GET_FOLDER_ITEMS_LIST: 'GET_FOLDER_ITEMS_LIST',
  GET_FOLDER_ITEMS_LIST_SUCCESS: 'GET_FOLDER_ITEMS_LIST_SUCCESS',
  GET_FOLDER_ITEMS_LIST_FAILURE: 'GET_FOLDER_ITEMS_LIST_FAILURE',
  REORDER_NODE_ITEM: 'REORDER_NODE_ITEM',
  REORDER_NODE_ITEM_SUCCESS: 'REORDER_NODE_ITEM_SUCCESS',
  REORDER_NODE_ITEM_FAILURE: 'REORDER_NODE_ITEM_FAILURE',
  MOVE_NODE_ITEM: 'MOVE_NODE_ITEM',
  MOVE_NODE_ITEM_SUCCESS: 'MOVE_NODE_ITEM_SUCCESS',
  MOVE_NODE_ITEM_FAILURE: 'MOVE_NODE_ITEM_FAILURE',
  MOVE_NODE_TO_ACTIVITY: 'MOVE_NODE_TO_ACTIVITY',
  MOVE_NODE_TO_ACTIVITY_SUCCESS: 'MOVE_NODE_TO_ACTIVITY_SUCCESS',
  MOVE_NODE_TO_ACTIVITY_FAILURE: 'MOVE_NODE_TO_ACTIVITY_FAILURE',
  SHOW_FOLDER_PROPERTIES_MODAL: 'SHOW_FOLDER_PROPERTIES_MODAL',
  UPDATE_FOLDER_PROPERTIES: 'UPDATE_FOLDER_PROPERTIES',
  UPDATE_FOLDER_PROPERTIES_SUCCESS: 'UPDATE_FOLDER_PROPERTIES_SUCCESS',
  UPDATE_FOLDER_PROPERTIES_FAILURE: 'UPDATE_FOLDER_PROPERTIES_FAILURE',
  DUPLICATE_NODE: 'DUPLICATE_NODE',
  DUPLICATE_SPARK_SUCCESS: 'DUPLICATE_SPARK_SUCCESS',
  DUPLICATE_SUCCESS: 'DUPLICATE_SUCCESS',
  DUPLICATE_SPARK: 'DUPLICATE_SPARK',
  DELETE_NODE: 'DELETE_NODE',
  DELETE_NODE_SUCCESS: 'DELETE_NODE_SUCCESS',
  DELETE_NODE_FAILURE: 'DELETE_NODE_FAILURE',
  SHARE_NODE: 'SHARE_NODE',
  SHARE_NODE_SUCCESS: 'SHARE_NODE_SUCCESS',
  SHARE_SPARK: 'SHARE_SPARK',
  SHARE_SPARK_SUCCESS: 'SHARE_SPARK_SUCCESS',
  UPDATE_PREVIEW_MODAL_VISIBILITY: 'UPDATE_PREVIEW_MODAL_VISIBILITY',
  ADD_ITEM_FROM_CAPTURE: 'ADD_ITEM_FROM_CAPTURE',
  ADD_ITEM_FROM_CAPTURE_SUCCESS: 'ADD_ITEM_FROM_CAPTURE_SUCCESS',
  ADD_ITEM_FROM_CAPTURE_FAILURE: 'ADD_ITEM_FROM_CAPTURE_FAILURE',
  CONVERTING_ITEM_TO_NODE: 'CONVERTING_ITEM_TO_NODE',
  CONVERTING_ITEM_TO_NODE_SUCCESS: 'CONVERTING_ITEM_TO_NODE_SUCCESS',
  CONVERTING_ITEM_TO_NODE_FAILURE: 'CONVERTING_ITEM_TO_NODE_FAILURE',
  CREATE_NODE_FROM_CAPTURE: 'CREATE_NODE_FROM_CAPTURE',
  CREATE_NODE_FROM_CAPTURE_SUCCESS: 'CREATE_NODE_FROM_CAPTURE_SUCCESS',
  CREATE_NODE_FROM_CAPTURE_FAILURE: 'CREATE_NODE_FROM_CAPTURE_FAILURE',
  SET_SORT_BY_VALUE_FOLDERS: 'SET_SORT_BY_VALUE_FOLDERS',
  SET_EXPAND_FOLDER_DETAILS: 'SET_EXPAND_FOLDER_DETAILS',
  DUPLICATE_NODE_ITEMS: 'DUPLICATE_NODE_ITEMS',
  UPDATE_NODE_ITEMS_DATA: 'UPDATE_NODE_ITEMS_DATA',
  DELETE_NODE_ITEMS: 'DELETE_NODE_ITEMS',
  DELETE_NODE_ITEMS_SUCCESS: 'DELETE_NODE_ITEMS_SUCCESS',
  DELETE_NODE_ITEMS_FAILURE: 'DELETE_NODE_ITEMS_FAILURE',
  SHOW_ITEM_PROPERTIES_MODAL: 'SHOW_ITEM_PROPERTIES_MODAL',
  EDIT_MODAL_VISIBLE: 'EDIT_MODAL_VISIBLE',
  CHANGE_NODE_AVATAR_SUCCESS: 'CHANGE_NODE_AVATAR_SUCCESS',
  UPDATE_ITEM_PROPERTIES: 'UPDATE_ITEM_PROPERTIES',
  UPDATE_ITEM_PROPERTIES_SUCCESS: 'UPDATE_ITEM_PROPERTIES_SUCCESS',
  UPDATE_NOTE_ITEM: 'UPDATE_NOTE_ITEM',
  UPDATE_LOCATION_ITEM: 'UPDATE_LOCATION_ITEM',
  UPDATE_CONTACT_ITEM: 'UPDATE_CONTACT_ITEM',
  UPDATE_LINK_ITEM: 'UPDATE_LINK_ITEM',
  UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
  UPDATE_DELETE_NODE_MODAL_VISIBILITY: 'UPDATE_DELETE_NODE_MODAL_VISIBILITY',
  UPDATE_DELETE_NODE_ITEMS_MODAL_VISIBILITY:
    'UPDATE_DELETE_NODE_ITEMS_MODAL_VISIBILITY',
  UPDATE_SHARE_MODAL_VISIBILITY: 'UPDATE_SHARE_MODAL_VISIBILITY',
  UPDATE_NODE_AVATAR_DATA: 'UPDATE_NODE_AVATAR_DATA',
  GOTO_SPARK_INFO_MODAL_VISIBLE: 'GOTO_SPARK_INFO_MODAL_VISIBLE',
  UPDATE_FOLDER_PROPERTIES_WITH_ACTIVITY:
    'UPDATE_FOLDER_PROPERTIES_WITH_ACTIVITY',
  UPDATE_PHOTO: 'UPDATE_PHOTO',
  UPDATE_VIDEO: 'UPDATE_VIDEO',
  UPDATE_AUDIO: 'UPDATE_AUDIO',
  SHOW_CAPTION_MODAL: 'SHOW_CAPTION_MODAL',
  UPDATE_CAPTION: 'UPDATE_CAPTION',
  UPDATE_CAPTION_FAILURE: 'UPDATE_CAPTION_FAILURE',
  UPDATE_CAPTION_SUCCESS: 'UPDATE_CAPTION_SUCCESS',
  UPDATE_RENDER_VARIABLE: 'UPDATE_RENDER_VARIABLE',
  ROTATE_IMAGE: 'ROTATE_IMAGE',
  CHANGE_NODE_AVATAR_FAILURE: 'CHANGE_NODE_AVATAR_FAILURE',
  UPDATE_NODE_AVATAR_LOADER: 'UPDATE_NODE_AVATAR_LOADER',
  SHOW_DESCRIPTION_MODAL: 'SHOW_DESCRIPTION_MODAL',
  UPDATE_ITEM_PROPERTIES_FAILURE: 'UPDATE_ITEM_PROPERTIES_FAILURE',
  SET_SELECTED_NODE_ID: 'SET_SELECTED_NODE_ID',
  HIDE_TARGET_ZONE_ID: 'HIDE_TARGET_ZONE_ID',
  SET_COMPONENT_IS_DRAGGING: 'SET_COMPONENT_IS_DRAGGING',
  CREATE_QUICK_SPARK_FOLDER: 'CREATE_QUICK_SPARK_FOLDER',
  CREATE_QUICK_SPARK_FOLDER_SUCCESS: 'CREATE_QUICK_SPARK_FOLDER_SUCCESS',
  ROTATE_IMAGE_SUCCESS: 'ROTATE_IMAGE_SUCCESS',
  ROTATE_IMAGE_FAILURE: 'ROTATE_IMAGE_FAILURE',
  REMOVE_FOLDER_ON_TOP: 'REMOVE_FOLDER_ON_TOP',
  DISABLE_DRAG_IMAGE: 'DISABLE_DRAG_IMAGE',
  SET_SHOULD_REORDER: 'SET_SHOULD_REORDER',
  SET_DESTINATION_ID: 'SET_DESTINATION_ID',
  SET_LOCK_STATUS: 'SET_LOCK_STATUS',
  DISABLE_COMBINE_ENABLED: 'DISABLE_COMBINE_ENABLED',
};

export default actions;
