import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEng from 'locales/en/translation.json';
import translationSpa from './locales/es/translation.json';
import translationNum from './locales/number/translation.json';

i18n.use(LanguageDetector).init({
  // debug: true,
  lng: !!localStorage.getItem('i18nextLng')
    ? localStorage.getItem('i18nextLng')
    : 'en',
  fallbackLng: 'en', // use en if detected lng is not available

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },

  resources: {
    en: {
      translations: translationEng,
    },
    es: {
      translations: translationSpa,
    },
    num: {
      translations: translationNum,
    },
  },
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
});

export default i18n;
