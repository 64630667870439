import React from 'react';
import { notification, Button } from 'antd';
import actions from 'redux/auth/actions';
import { store } from 'redux/store';
import { timeConvert, Translate, updateBannerPosition } from 'utility/helpers';
import whiteWarningIcon from 'images/svgs/whiteWarning.svg';
import { screenName } from 'components/constants';

export const verifyNotification = (RemainingTime) => {
  notification.warning({
    message: (
      <>
        <Translate>email_verification_banner_text</Translate>{' '}
        {timeConvert(RemainingTime)}
      </>
    ),
    description: (
      <Button
        onClick={() => {
          store.dispatch({
            type: actions.VERIFY_MODAL_VISIBLE,
            payload: true,
          });
          window.analytics.page(screenName.verification_screen);
        }}
      >
        <Translate>verify_my_account</Translate>
      </Button>
    ),
    className: 'verify-notification',
    icon: <img src={whiteWarningIcon} alt={'white-warning'} />,
    duration: 0,
    placement: 'bottomRight',
    bottom: 0,
  });
  updateBannerPosition(store.getState().Settings.contentWidth);
};

export const disableCollapseOnFilter = [
  'free_space',
  'sparks_only',
];
