import actions from './actions';
import { grouping } from 'utility/helpers';

const initState = {
  sharedSparksList: {},
  shareModalVisible: false,
  loader: false,
  itemList: [],
  expandedSharedSparks: [],
  sortSharedSparksBy: 'newest_first',
  urlPreview: null,
  selectedId: '',
  deleteModalVisible: false,
  deleteLoader: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_SHARED_SPARKS: {
      return {
        ...state,
        loader: true,
      };
    }
    case actions.GET_SHARED_SPARKS_SUCCESS: {
      return {
        ...state,
        sharedSparksList: grouping(action.payload, true, true),
        loader: false,
      };
    }
    case actions.GET_SHARED_SPARKS_FAILURE: {
      return {
        ...state,
        loader: false,
      };
    }
    case actions.SHARE_MODAL_VISIBLE: {
      return {
        ...state,
        shareModalVisible: action.payload,
      };
    }
    case actions.GET_ITEM_LIST_SUCCESS: {
      return {
        ...state,
        itemList: {
          ...state.itemList,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case actions.SET_EXPAND_SHARED_SPARK_DETAILS: {
      return {
        ...state,
        expandedSharedSparks: action.payload,
      };
    }
    case actions.SET_SORT_BY_VALUE_SHARED_SPARKS: {
      return {
        ...state,
        sortSharedSparksBy: action.payload,
      };
    }
    case actions.GET_URL_PREVIEW_SUCCESS: {
      return {
        ...state,
        urlPreview: {
          ...state.urlPreview,
          [action.payload.url]: action.payload.data,
        },
      };
    }
    case actions.SET_SELECTED_ID: {
      return {
        ...state,
        selectedId: action.selectedId,
      };
    }
    case actions.DELETE_SHARED_SPARK_MODAL_VISIBLE: {
      return {
        ...state,
        deleteModalVisible: action.payload,
      };
    }
    case actions.DELETE_SHARED_SPARK: {
      return {
        ...state,
        deleteLoader: true,
      };
    }
    case actions.DELETE_SHARED_SPARK_SUCCESS: {
      return {
        ...state,
        deleteLoader: false,
      };
    }
    case actions.DELETE_SHARED_SPARK_FAILURE: {
      return {
        ...state,
        deleteLoader: false,
      };
    }
    default: {
      return state;
    }
  }
}
