import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from 'utility/asyncComponent';
import NotFound from 'containers/pages/notFound';
import { setActiveTab } from 'utility/helpers';

const routes = [
  {
    path: 'plans',
    component: asyncComponent(() => import('containers/pricingPlan/index')),
  },
  {
    path: 'shared',
    component: asyncComponent(() =>
      import('containers/sharedSparks/sharedSparks'),
    ),
  },
  {
    path: '',
    component: asyncComponent(() => import('containers/capture/Home')),
  },
  {
    path: 'settings',
    exact: false,
    component: asyncComponent(() =>
      import('containers/settingsContainer/index'),
    ),
  },
  {
    path: 'search',
    component: asyncComponent(() => import('containers/search/index')),
  },
];

class AppRouter extends Component {
  render() {
    const { url } = this.props;
    setActiveTab();
    return (
      <Switch>
        {routes.map((singleRoute) => {
          let { path, exact, component } = singleRoute;
          return (
            <Route
              exact={exact !== false}
              key={path}
              path={`${url}/${path}`}
              component={component}
            />
          );
        })}
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default AppRouter;
