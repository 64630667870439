const actions = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE',
  UPLOAD_ITEM_TO_PROJECT: 'UPLOAD_ITEM_TO_PROJECT',
  UPLOAD_ITEM_TO_PROJECT_SUCCESS: 'UPLOAD_ITEM_TO_PROJECT_SUCCESS',
  UPLOAD_ITEM_TO_PROJECT_FAILURE: 'UPLOAD_ITEM_TO_PROJECT_FAILURE',
  UPLOAD_ITEM_TO_CAPTURE: 'UPLOAD_ITEM_TO_CAPTURE',
  UPLOAD_ITEM_TO_CAPTURE_SUCCESS: 'UPLOAD_ITEM_TO_CAPTURE_SUCCESS',
  UPLOAD_ITEM_TO_CAPTURE_FAILURE: 'UPLOAD_ITEM_TO_CAPTURE_FAILURE',
};

export default actions;
